import React, { useEffect, useRef, useState } from 'react';
import { ArrowDown, ChevronDown, Upload } from 'react-feather';
import { Controller, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { Link } from 'react-router-dom';
import {
  Alert,
  AlertTitle,
  Autocomplete,
  Backdrop,
  Button,
  ButtonGroup,
  Card,
  Chip,
  ClickAwayListener,
  Fade,
  Grow,
  InputAdornment,
  MenuItem,
  MenuList,
  Modal,
  Paper,
  Popper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
} from '@mui/material';
import _ from 'lodash';
import hash from 'object-hash';
import PropTypes from 'prop-types';

import { MQ_TASK } from '@/utils/constants';

import {
  CSVUpload,
  Error,
  ExportExcelContainer,
  ImportWithStockLotModal,
  Loading,
  LoadingDialog,
  SocketIOLoading,
  ViewTitle,
} from '@iarcpsu/emanufac-components/src/components';
import { app as appConfig, useQuery } from '../../configs';
import * as actions from '../../redux/actions';
import { MaterialUtil } from '../../utils/modelutils';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '90vw',
  boxShadow: 24,
  p: 4,
};

function Material({ title, subtitle }) {
  const dispatch = useDispatch();
  const importButtonRef = useRef();
  const createButtonRef = useRef();
  const query = useQuery();
  const material = useSelector((state) => state.material);
  const materialType = useSelector((state) => state.materialType);
  const me = useSelector((state) => state.me);
  const history = useHistory();
  const { control } = useForm();
  const [searchTerm, setSearchTerm] = useState('');
  const [name, setName] = useState('');
  const [page, setPage] = useState(
    query.get('page') ? parseInt(query.get('page'), 10) : 1,
  );
  const [size, setSize] = useState(
    query.get('size') ? parseInt(query.get('size'), 10) : 10,
  );
  const [total, setTotal] = useState(undefined);
  const [csvUploadOpen, setCsvUploadOpen] = useState(false);
  const [csvData, setCsvData] = useState([]);
  const [selectMaterialType, setSelectMaterialType] = useState({
    _id: query.get('material-type') || '',
  });
  const [materialImportOpen, setMaterialImportOpen] = useState(false);
  const [createMaterialOpen, setCreateMaterialOpen] = useState(false);
  const [addStockFromFileOpen, setAddStockFromFileOpen] = useState(false);
  const [referenceNumber, setReferenceNumber] = useState('');
  const [dialogLoadingOn, setIsDialogLoadingOn] = useState(false);
  useEffect(() => {
    const refNo = hash({
      date: new Date(),
      user: me?.userData?._id,
    });

    setReferenceNumber(refNo);

    return () => {};
  }, []);

  const handleCSVClose = () => {
    setCsvUploadOpen(false);
    setCsvData([]);
  };

  const materialAll = () => {
    dispatch(
      actions.materialAll({
        name,
        page,
        size,
        selectMaterialType: selectMaterialType?._id || '',
      }),
    );
  };

  useEffect(() => {
    // dispatch(actions.matrialReset());
    materialAll();
    dispatch(actions.materialTypeAll(''));
    dispatch(actions.customerTypeAll({}));
    return () => {};
  }, [name, page, size, selectMaterialType]);

  useEffect(() => {
    setTotal(material?.total);
    return () => {};
  }, [material]);

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      setName(searchTerm);
      if (material?.rows) {
        setPage(1);
      }
    }, 700);
    return () => clearTimeout(delayDebounceFn);
  }, [searchTerm]);

  const renderTitle = () => <ViewTitle title={title} subtitle={subtitle} />;

  const handleDeleteMaterial = async (id) => {
    const confirm = window.confirm('ยืนยันการลบข้อมูล');
    if (confirm) {
      try {
        await dispatch(actions.materialDelete(id));
        materialAll();
      } catch (error) {
        console.error(error);
      }
    }
  };

  const handleChangeRowsPerPage = (event) => {
    setSize(event.target.value);
    setPage(1);
    history.push({
      search: `?${new URLSearchParams({ page, size }).toString()}`,
    });
  };

  const handleSelectMaterialType = (type) => {
    setSelectMaterialType(type);
    history.push({
      search: `?${new URLSearchParams({
        page,
        size,
        'material-type': type?._id || '',
      }).toString()}`,
    });
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage + 1);
    history.push({
      search: `?${new URLSearchParams({ page: newPage + 1, size }).toString()}`,
    });
  };

  const handleAddFromFile = async () => {
    if (!_.isEmpty(csvData)) {
      try {
        await dispatch(actions.materialCreate({ arr: csvData }));
        alert('สำเร็จ');
        handleCSVClose();
        await dispatch(actions.materialAll({ name, page, size }));
      } catch (error) {
        alert('เพิ่มวัตถุดิบไม่สำเร็จ');
        console.error(error);
      }
    } else {
      alert('ไม่สามารถอ่านไฟล์รายการวัตถุดิบได้');
    }
  };

  const startTimeOutSetting = () => {
    console.log('Pre setting');
    setTimeout(() => {
      console.log('GO Back');
      history.goBack();
    }, 10000);
  };

  const handleAddStockLotFromFile = async (data) => {
    try {
      startTimeOutSetting();
      await dispatch(
        actions.materialCreate({
          arr: _.map(data?.csvData, (each) => ({
            ...each,
            recipient_name: `${me?.userData?.firstname || ''} ${
              me?.userData?.lastname || ''
            }`,
          })),
          withStockLot: true,
          referenceNumber,
        }),
      );

      setAddStockFromFileOpen(false);

      // alert('สำเร็จ');
      // await dispatch(actions.materialAll({ name, page, size }));
    } catch (error) {
      alert('เพิ่มวัตถุดิบไม่สำเร็จ');
      console.error(error);
    }
  };

  const handleSocketIOFunction = {
    onFail: (args) => {
      setIsDialogLoadingOn(false);
      alert(args?.message);
    },
    onSuccess: (args) => {
      setIsDialogLoadingOn(false);
      history.goBack();
      //  history.push(`/mms/manufacturing-orders/assign/${args?.payload?._id}`);
    },
  };

  const renderAddButton = () => (
    <div className="flex flex-row justify-end pb-4 gap-2">
      <React.Fragment>
        <ButtonGroup
          variant="contained"
          ref={createButtonRef}
          aria-label="split button"
        >
          <Button
            variant="contained"
            onClick={() => {
              history.push('materials/create');
            }}
          >
            เพิ่ม
          </Button>
          <Button
            size="small"
            aria-controls={createMaterialOpen ? 'split-button-menu' : undefined}
            aria-expanded={createMaterialOpen ? 'true' : undefined}
            aria-label="select merge strategy"
            aria-haspopup="menu"
            onClick={() => setCreateMaterialOpen(!createMaterialOpen)}
          >
            <ChevronDown />
          </Button>
        </ButtonGroup>
        <Popper
          sx={{
            zIndex: 1,
          }}
          open={createMaterialOpen}
          anchorEl={createButtonRef.current}
          role={undefined}
          transition
          disablePortal
        >
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              style={{
                transformOrigin:
                  placement === 'bottom' ? 'center top' : 'center bottom',
              }}
            >
              <Paper>
                <ClickAwayListener
                  onClickAway={() => {
                    setCreateMaterialOpen(false);
                  }}
                >
                  <MenuList id="split-button-menu" autoFocusItem>
                    <MenuItem
                      onClick={() => {
                        history.push('materials/create');
                      }}
                    >
                      เพิ่มวัตถุดิบชนิดเดียว
                    </MenuItem>
                    <MenuItem
                      onClick={() => {
                        history.push('materials/create-multiple');
                      }}
                    >
                      เพิ่มวัตถุดิบหลายชนิด
                    </MenuItem>
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
      </React.Fragment>
      <React.Fragment>
        <ButtonGroup
          variant="contained"
          ref={importButtonRef}
          aria-label="split button"
          color="success"
        >
          <Button
            onClick={() => history.push('/ims/materials/import')}
            startIcon={<Upload />}
          >
            Import
          </Button>
          <Button
            size="small"
            aria-controls={materialImportOpen ? 'split-button-menu' : undefined}
            aria-expanded={materialImportOpen ? 'true' : undefined}
            aria-label="select merge strategy"
            aria-haspopup="menu"
            onClick={() => setMaterialImportOpen(!materialImportOpen)}
          >
            <ChevronDown />
          </Button>
        </ButtonGroup>
        <Popper
          sx={{
            zIndex: 1,
          }}
          open={materialImportOpen}
          anchorEl={importButtonRef.current}
          role={undefined}
          transition
          disablePortal
        >
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              style={{
                transformOrigin:
                  placement === 'bottom' ? 'center top' : 'center bottom',
              }}
            >
              <Paper>
                <ClickAwayListener
                  onClickAway={() => {
                    setMaterialImportOpen(false);
                  }}
                >
                  <MenuList id="split-button-menu" autoFocusItem>
                    <MenuItem
                      // onClick={handleCSVOpen}
                      onClick={() => history.push('/ims/materials/import')}
                    >
                      Import เฉพาะวัตถุดิบ
                    </MenuItem>
                    <MenuItem
                      onClick={() => {
                        setAddStockFromFileOpen(true);
                      }}
                    >
                      Import พร้อมจำนวน
                    </MenuItem>
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
      </React.Fragment>
    </div>
  );

  const renderSearch = () => (
    <Card>
      <div className="p-4 flex flex-row gap-2">
        <div className="w-full md:w-1/2">
          <TextField
            label="ค้นหา"
            fullWidth
            size={'small'}
            id="outlined-start-adornment"
            onChange={(e) => {
              setSearchTerm(e.target.value);
            }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <i className="fas fa-search"></i>
                </InputAdornment>
              ),
            }}
          />
        </div>
        <div className="w-full md:w-1/4">
          {!_.isEmpty(materialType?.rows) && (
            <Controller
              name={'status_code'}
              control={control}
              defaultValue={''}
              rules={{ required: true }}
              render={({ field }) => (
                <Autocomplete
                  {...field}
                  disablePortal
                  options={materialType?.rows}
                  size="small"
                  placeholder="เลือกประเภทวัตถุดิบ"
                  onChange={(e, newValue) => {
                    field.onChange(newValue);
                    handleSelectMaterialType(newValue);
                  }}
                  className="z-40"
                  // prettier-ignore
                  getOptionLabel={(option) => `${option?.name || ''}`
              }
                  renderInput={(params) => (
                    <TextField label="เลือกประเภทวัตถุดิบ" {...params} />
                  )}
                />
              )}
            />
          )}
        </div>
        <div className="w-full md:w-1/4 flex justify-end">
          <ExportExcelContainer
            columnList={MaterialUtil.columnWithoutStock}
            currentData={material?.rows}
            dataAPIEndPoint="material"
            dataQuery={{
              fetchStockLot: false,
              selectMaterialType,
              stockStatus: '',
              fetchProcess: false,
              page: 1,
              size: appConfig.maxFetchSize,
            }}
            sheetName="Material"
          />
        </div>
      </div>
    </Card>
  );

  const renderCSVUploadModal = () => (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={csvUploadOpen}
      onClose={handleCSVClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={csvUploadOpen}>
        <Card sx={style} className="max-w-4xl">
          <div className="pt-2">อัพโหลดข้อมูล</div>
          <div className="py-2 font-system">
            อัพโหลดไฟล์ .csv โดยมีโครงสร้างตามไฟล์เทมเพลต แถวที่ 1-2
            และแถวสุดท้าย จะไม่ถูกนำมาคิดในระบบ <br />
            <Button>
              <Link to="/filetemplate/Material.csv" target="_blank">
                ดาวน์โหลดเทมเพลต
              </Link>
            </Button>
          </div>
          <div className="my-2">
            <Alert severity="info">
              <AlertTitle>
                ระบบใหม่ สามารถใส่ชื่อหมวดหมู่วัตถุดิบ โดยการพิมพ์ชื่อได้เลย
                ไม่จำเป็นต้องใส่โค้ดใดๆ
              </AlertTitle>
            </Alert>
          </div>
          <div className="py-2">
            <div className="flex justify-center">
              <div>
                <CSVUpload setCsvData={setCsvData} />
                {!_.isEmpty(csvData) && (
                  <div>
                    <p className="font-system my-1">
                      พบข้อมูล {_.size(csvData)} รายการ
                    </p>
                    <Button
                      color="primary"
                      variant="contained"
                      type="button"
                      onClick={handleAddFromFile}
                    >
                      บันทึก
                    </Button>
                  </div>
                )}
              </div>
            </div>
          </div>
        </Card>
      </Fade>
    </Modal>
  );

  const renderTable = () => (
    <div className="my-2">
      <Paper>
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }}>
            <TableHead>
              <TableRow>
                <TableCell>
                  <div className="font-bold">ลำดับที่</div>
                </TableCell>
                <TableCell>
                  <div className="font-bold"> รหัสวัตถุดิบ</div>
                </TableCell>
                <TableCell>
                  <div className="font-bold"> ชื่อวัตถุดิบ</div>
                </TableCell>
                <TableCell>
                  <div className="font-bold"> หน่วย</div>
                </TableCell>
                <TableCell>
                  <div className="font-bold"> ประเภทวัตถุดิบ</div>
                </TableCell>
                <TableCell>
                  <div className="font-bold"> ดำเนินการ</div>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {!_.isEmpty(material.rows) ? (
                material.rows.map((row, index) => (
                  <TableRow
                    key={row.id}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">
                      {(page - 1) * size + index + 1}
                    </TableCell>
                    <TableCell>{`${row.type_code || ''}`}</TableCell>
                    <TableCell>
                      {row.name}{' '}
                      {row?.is_workin_process && (
                        <Chip color="warning" label="WIP" size="small"></Chip>
                      )}
                    </TableCell>
                    <TableCell>
                      {row?.use_unit_conversion
                        ? `${row?.unit_input?.short_sign || ''} - ${
                            row?.unit_output?.short_sign || ''
                          }`
                        : row?.unit}
                    </TableCell>
                    <TableCell>{`${row.material_type?.name}`}</TableCell>
                    <TableCell>
                      <div className="flex flex-row flex-wrap gap-1">
                        <Link to={`/ims/material/${row?.id}`}>
                          <Button
                            variant="contained"
                            color={'info'}
                            size={'small'}
                            // onClick={() => handlePushToDetailMaterial(row.id)}
                          >
                            รายละเอียด
                          </Button>{' '}
                        </Link>
                        <Link to={`/ims/material/edit/${row?.id}`}>
                          <Button
                            variant="contained"
                            color={'warning'}
                            size={'small'}
                            // onClick={() => handlePushToEditMaterial(row.id)}
                          >
                            แก้ไข
                          </Button>
                        </Link>
                        <Button
                          variant="contained"
                          color={'error'}
                          size={'small'}
                          onClick={() => {
                            handleDeleteMaterial(row?.id);
                          }}
                        >
                          ลบ
                        </Button>
                      </div>
                    </TableCell>
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell colSpan={6}>
                    <div className="text-center">ไม่มีข้อมูล</div>
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          component="div"
          //  rowsPerPageOptions={[]}
          onRowsPerPageChange={handleChangeRowsPerPage}
          page={page - 1}
          count={total || 1}
          rowsPerPage={size}
          onPageChange={handleChangePage}
        />
      </Paper>
    </div>
  );

  if (material.isLoading) {
    return <Loading />;
  }
  if (!material.isLoading && material.isCompleted) {
    return (
      <div>
        {renderCSVUploadModal()}
        <LoadingDialog
          isLoading={dialogLoadingOn}
          label="กำลังสร้างการอัพโหลดใหม่"
        />

        <SocketIOLoading
          referenceNumber={referenceNumber}
          taskCode={MQ_TASK.UPLOAD_MATERIAL_WITH_STOCKLOT.status_code}
          handleFail={handleSocketIOFunction.onFail}
          handleSuccess={handleSocketIOFunction.onSuccess}
        />

        <ImportWithStockLotModal
          materialType={materialType}
          handleAddFromFile={handleAddStockLotFromFile}
          onClose={() => {
            setAddStockFromFileOpen(false);
          }}
          open={addStockFromFileOpen}
        />
        <div className="flex justify-between">
          <div>{renderTitle()}</div>
          <div className="mt-6">{renderAddButton()}</div>
        </div>
        {renderSearch()}
        {renderTable()}
      </div>
    );
  }
  return <Error message={material?.message} />;
}

Material.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
};

Material.defaultProps = {
  title: '',
  subtitle: '',
};

export default Material;
