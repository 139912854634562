import React from 'react';
import { Link } from 'react-router-dom';
import { Button, Card, CardContent, Skeleton } from '@mui/material';
import dayjs from 'dayjs';
import _ from 'lodash';
import PropTypes from 'prop-types';

import { currencyFormatter } from '@/utils/functions';

const CardManufacturingOrderAnalysis = ({
  manufacturingOrder,
  selectedMonthDate,
  showRevenue = true,
  showProductionResult = true,
  showMoreInfoBox = false,
  isReady = true,
}) => (
  <Card>
    <CardContent>
      <div className="flex justify-between items-center">
        {selectedMonthDate && (
          <div className="font-display text-lg font-semibold ">
            รายงานประจำเดือน {dayjs(selectedMonthDate).format('MMMM BBBB')}
          </div>
        )}
        {showMoreInfoBox && (
          <div>
            <Link
              to={`/mms/manufacturing-order-report/monthy/${dayjs(
                selectedMonthDate,
              ).format('YYYY')}/${dayjs(selectedMonthDate).format('M')}`}
            >
              <Button size="small">รายละเอียดเพิ่มเติม</Button>
            </Link>
          </div>
        )}
      </div>
      <div className="font-display pt-2 font-semibold">คำสั่งผลิต</div>
      <div className="flex flex-wrap my-2">
        <div className="w-1/4">
          <div className="text-sm">คำสั่งผลิตทั้งหมด</div>
          <div className="flex gap-2">
            {isReady ? (
              <div className="text-3xl font-semibold">
                {manufacturingOrder?.total || 0}
              </div>
            ) : (
              <Skeleton variant="text" width={100} height={30} />
            )}
            <div className="self-end">รายการ</div>
          </div>
        </div>
        <div className="w-1/4">
          <div className="text-sm">สำเร็จ</div>
          <div className="flex gap-2">
            {isReady ? (
              <div className="text-3xl font-semibold">
                {manufacturingOrder?.success || 0}
              </div>
            ) : (
              <Skeleton variant="text" width={100} height={30} />
            )}
            <div className="self-end">รายการ</div>
          </div>
        </div>
        <div className="w-1/4">
          <div className="text-sm">ยกเลิก</div>
          <div className="flex gap-2">
            {isReady ? (
              <div className="text-3xl font-semibold">
                {manufacturingOrder?.fail || 0}
              </div>
            ) : (
              <Skeleton variant="text" width={100} height={30} />
            )}
            <div className="self-end">รายการ</div>
          </div>
        </div>
        <div className="w-1/4">
          <div className="text-sm">อยู่ระหว่างการดำเนินการ</div>
          <div className="flex gap-2">
            {isReady ? (
              <div className="text-3xl font-semibold">
                {manufacturingOrder?.incumbent || 0}
              </div>
            ) : (
              <Skeleton variant="text" width={100} height={30} />
            )}
            <div className="self-end">รายการ</div>
          </div>
        </div>
      </div>
      {showProductionResult && (
        <div>
          <div className="font-display pt-2 font-semibold">ผลผลิต</div>
          <div className="flex flex-wrap my-2 ">
            <div className="w-1/4">
              <div className="text-sm">ผลิตสินค้า/วัตถุดิบรวม</div>
              <div className="flex gap-2">
                <div className="text-3xl font-semibold">
                  {manufacturingOrder?.allProcess || 0}
                </div>
                <div className="self-end">รายการ</div>
              </div>
            </div>
            <div className="w-1/4">
              <div className="text-sm">สำเร็จ</div>
              <div className="flex gap-2">
                <div className="text-3xl font-semibold">
                  {manufacturingOrder?.successProcess || 0}
                </div>
                <div className="self-end">รายการ</div>
              </div>
            </div>
            <div className="w-1/4">
              <div className="text-sm">ยกเลิก</div>
              <div className="flex gap-2">
                <div className="text-3xl font-semibold">
                  {manufacturingOrder?.failProcess || 0}
                </div>
                <div className="self-end">รายการ</div>
              </div>
            </div>
            <div className="w-1/4">
              <div className="text-sm">อยู่ระหว่างการดำเนินการ</div>
              <div className="flex gap-2">
                <div className="text-3xl font-semibold">
                  {manufacturingOrder?.incumbentProcess || 0}
                </div>
                <div className="self-end">รายการ</div>
              </div>
            </div>
          </div>{' '}
          <div className="flex flex-wrap my-2 py-2">
            <div className="w-2/4">
              <div className="text-sm">ผลิตเป็นสินค้า</div>
              <div className="flex gap-2">
                <div className="text-3xl font-semibold">
                  {manufacturingOrder?.produceProduct || 0}
                </div>
                <div className="self-end">รายการ</div>
              </div>
            </div>
            <div className="w-2/4">
              <div className="text-sm">ผลิตเป็นวัตถุดิบ</div>
              <div className="flex gap-2">
                <div className="text-3xl font-semibold">
                  {manufacturingOrder?.produceMaterial || 0}
                </div>
                <div className="self-end">รายการ</div>
              </div>
            </div>
          </div>
        </div>
      )}

      {showRevenue && (
        <div className="font-display pt-2 font-semibold">รายได้</div>
      )}

      {showRevenue && (
        <div className="flex flex-wrap my-2 py-2">
          <div className="w-1/4">
            <div className="text-sm">รายได้รวมจากงานที่สำเร็จ</div>
            <div className="flex gap-2">
              <div className="text-3xl font-semibold">
                {currencyFormatter.format(
                  manufacturingOrder?.totalRevenue || 0,
                )}
              </div>
              <div className="self-end">บาท</div>
            </div>
          </div>
          <div className="w-1/4">
            <div className="text-sm">รายได้รวมในงานที่ยกเลิก</div>
            <div className="flex gap-2">
              <div className="text-3xl font-semibold">
                {currencyFormatter.format(
                  manufacturingOrder?.cancledRevenue || 0,
                )}
              </div>
              <div className="self-end">บาท</div>
            </div>
          </div>
          <div className="w-1/4">
            <div className="text-sm">รายได้รวมในงานที่ระหว่างดำเนินการ</div>
            <div className="flex gap-2">
              <div className="text-3xl font-semibold">
                {currencyFormatter.format(
                  manufacturingOrder?.unSuccessRevenue || 0,
                )}
              </div>
              <div className="self-end">บาท</div>
            </div>
          </div>
          <div className="w-1/4">
            <div className="text-sm">
              รายได้จากงานที่สำเร็จแต่คำสั่งผลิตไม่สำเร็จ
            </div>
            <div className="flex gap-2">
              <div className="text-3xl font-semibold">
                {currencyFormatter.format(
                  manufacturingOrder?.successButCancleMORevenue || 0,
                )}
              </div>
              <div className="self-end">บาท</div>
            </div>
          </div>
        </div>
      )}
    </CardContent>
  </Card>
);

export default CardManufacturingOrderAnalysis;

CardManufacturingOrderAnalysis.propTypes = {
  manufacturingOrder: PropTypes.object,
  selectedMonthDate: PropTypes.object,
  showRevenue: PropTypes.bool,
  showProductionResult: PropTypes.bool,
  showMoreInfoBox: PropTypes.bool,
};
