import React from 'react';
import { Card, CardContent } from '@mui/material';
import _ from 'lodash';
import PropTypes from 'prop-types';

const CardMaterialInOutTotalOnMove = ({ materialOut, materialIn }) => (
  <Card>
    <CardContent>
      <div className="flex flex-wrap">
        <div className="w-1/2">
          <div>รวมวัตถุดิบแปลงออก</div>
          <div className="text-2xl">
            {_.sumBy(
              _.filter(
                _.map(materialOut, (each) => ({
                  ...each,
                  amount: parseFloat(each?.amount || 0),
                })),
                (each) => each?.selected === true,
              ),
              'amount',
            )}
          </div>
        </div>{' '}
        <div className="w-1/2">
          <div>รวมวัตถุดิบเข้า</div>
          <div className="text-2xl">
            {_.sumBy(
              _.map(materialIn, (each) => ({
                ...each,
                quantity: parseFloat(each?.quantity),
              })),
              'quantity',
            )}
          </div>
        </div>
      </div>
    </CardContent>
  </Card>
);

export default CardMaterialInOutTotalOnMove;

CardMaterialInOutTotalOnMove.propTypes = {
  materialOut: PropTypes.arrayOf(PropTypes.object),
  materialIn: PropTypes.arrayOf(PropTypes.object),
};
