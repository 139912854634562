import React, { useEffect, useMemo, useState } from 'react';
import { ArrowRight } from 'react-feather';
import { Controller, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { PRODUCT_QUANTITY_DIVIDE_BY } from '@iarcpsu/emanufac-constant';
import {
  Button,
  Card,
  CardContent,
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  Switch,
  TextField,
} from '@mui/material';
import dayjs from 'dayjs';
import _, { size } from 'lodash';
import hash from 'object-hash';
import PropTypes from 'prop-types';
import Swal from 'sweetalert2';

import {
  BackButton,
  Error,
  Loading,
  LoadingDialogWithTimeout,
  LotInformationForm,
  SelectMaterialToMoveForm,
  SocketIOLoading,
  ViewTitle,
} from '@iarcpsu/emanufac-components/src/components';
import { api, app as appConfig } from '@/configs';
import * as actions from '@/redux/actions';
import { MATERIAL_STOCK_STATUS, MQ_TASK } from '@/utils/constants';

/**
 * @function EditLotTransferToProduct
 * @description Display a collections or a list of WithdrawMaterial from database
 */

const useQuery = () => {
  const { search } = useLocation();
  return useMemo(() => new URLSearchParams(search), [search]);
};

export default function EditLotTransferToProduct({ title, subtitle }) {
  const dispatch = useDispatch();
  const me = useSelector((state) => state.me);
  const materialStockLot = useSelector((state) => state.materialStockLot);
  const information = useSelector((state) => state.information);
  const materialTransactionType = useSelector(
    (state) => state.materialTransactionType,
  );
  const productTransactionType = useSelector(
    (state) => state.productTransactionType,
  );
  const employee = useSelector((state) => state.employee);
  const department = useSelector((state) => state.department);
  const product = useSelector((state) => state.product);
  const browserQuery = useQuery();
  const history = useHistory();
  const [dialogLoading, setOpenDialogLoading] = useState(false);
  const [referenceNumber, setReferenceNumber] = useState('');
  const [statusMessage, setStatusMessage] = useState('');
  const [currentPlace, setCurrentPlace] = useState();
  const [destinationWarehouse, setDestinationWarehouse] = useState();
  const [selectedCustomer, setSelectedCustomer] = useState();
  const [isMultipleCustomer, setIsMultipleCustomer] = useState(false);
  const wmsSetting = information?.setting?.wms;

  useEffect(() => {
    const refNo = hash({
      date: new Date(),
      user: me?.userData?._id,
    });

    setReferenceNumber(refNo);

    return () => {};
  }, []);

  /** Initial the form */

  const {
    control,
    handleSubmit,
    setValue,
    watch,
    formState: { errors },
  } = useForm({
    defaultValues: {
      materialIn: [],
    },
  });

  /** เตรียมข้อมูล */

  const getAllProducts = () => {
    dispatch(
      actions.productGet(browserQuery.get('product'), {
        fetchStockLot: '',
      }),
    );
  };

  const getMaterialTransactionType = async () => {
    try {
      await dispatch(actions.materialTransactionTypeAll({}));
      await dispatch(actions.productTransactionTypeAll({}));
    } catch (error) {
      console.error('Process Get Error', error);
    }
  };

  const getCurrentPlace = async () => {
    try {
      const res = await api.get(
        `${process.env.REACT_APP_API_URL}/place/${browserQuery.get('source')}`,
      );
      setCurrentPlace(res.data);
    } catch (error) {
      console.error('Cannot Gete Current Place', error?.message);
    }
  };

  const getDestination = async () => {
    try {
      if (browserQuery?.get('destination')) {
        const res = await api.get(
          `${process.env.REACT_APP_API_URL}/warehouse/${browserQuery.get(
            'destination',
          )}`,
        );
        setDestinationWarehouse(res.data);
      }
    } catch (error) {
      console.error('Cannot Gete Current Place', error?.message);
    }
  };

  const getMaterialStockLot = () => {
    try {
      dispatch(
        actions.materialStockLotAll({
          size: appConfig.maxFetchSize,
          page: 1,
          ids: browserQuery.get('lotid'),
          stockStatus: '',
        }),
      );
    } catch (error) {
      console.error('Material StockLot Cannot Get', error);
    }
  };

  const getEmployeeAndDepartment = () => {
    try {
      dispatch(
        actions.employeeAll({
          page: 1,
          size: appConfig.maxFetchSize,
        }),
      );
      dispatch(
        actions.departmentAll({
          page: 1,
          size: appConfig.maxFetchSize,
        }),
      );
    } catch (error) {
      console.error(`Cannot Get Employee and Department ${error}`);
    }
  };

  const inFilterTxnType = _.filter(
    productTransactionType?.rows,
    (each) => each?.direction === 'add',
  );

  const outFilterTxnType = _.filter(
    materialTransactionType?.rows,
    (each) => each?.direction === 'desc',
  );

  const totalMaterialQuantity = () =>
    _.sumBy(materialStockLot?.rows, 'quantity');

  const totalMaterialAmount = (stockLotRows) => {
    const filterStockLot = _.filter(
      stockLotRows,
      (each) => each?.prefer_product?._id === product?._id,
    );
    const totalAmount = _.sumBy(filterStockLot, 'amount');
    return totalAmount;
  };

  const productMaterialNetFind = () => {
    switch (currentPlace?.place_action?.product_quantity_divide_by) {
      case PRODUCT_QUANTITY_DIVIDE_BY.NONE.status_code:
        return 1;
      case PRODUCT_QUANTITY_DIVIDE_BY.NET.status_code:
        return product?.net;
      case PRODUCT_QUANTITY_DIVIDE_BY.PACKAGE_SIZE.status_code:
        return product?.packing_size;
      default:
        return 1;
    }
  };

  const calculateProductQuantity = (stockLotRows) => {
    const filterStockLot = _.filter(
      stockLotRows,
      (each) => each?.prefer_product?._id === product?._id,
    );
    const totalAmount = _.sumBy(filterStockLot, 'amount');
    const productAmount = totalAmount / productMaterialNetFind();
    return productAmount;
  };

  useEffect(() => {
    getAllProducts();
    getMaterialTransactionType();
    getCurrentPlace();
    getDestination();
    getMaterialStockLot();
    getEmployeeAndDepartment();

    return () => {};
  }, []);

  const handleSocketIOFunction = {
    onSuccess: () => {
      setOpenDialogLoading(false);
      Swal.fire({
        icon: 'success',
        title: 'แปลงเป็นสินค้าและเพิ่มลงคลังสินค้าสำเร็จ',
        showConfirmButton: false,
        timer: 1500,
      }).then(() => {
        history.goBack();
      });
    },
    onFail: (args) => {
      setOpenDialogLoading(false);
      Swal.fire({
        icon: 'error',
        title: 'เกิดข้อผิดพลาด',
        text: args?.message,
      });
    },
  };

  useEffect(() => {
    const inStockMaterial = _.filter(
      materialStockLot?.rows,
      (each) => each?.status === MATERIAL_STOCK_STATUS.IN_STOCK.status_code,
    );
    const instockWithSelect = _.map(inStockMaterial, (each) => ({
      ...each,
      selected: true,
    }));
    setValue('materialOut', instockWithSelect);

    const customerList = _.map(inStockMaterial, (each) => each?.customer);
    const uniqueCustomer = _.uniqBy(customerList, '_id');
    console.log('Unique Customer', uniqueCustomer);
    if (_.size(uniqueCustomer) > 1) {
      setIsMultipleCustomer(true);
      setSelectedCustomer(null);
    } else {
      setIsMultipleCustomer(false);
      setSelectedCustomer(uniqueCustomer?.[0]);
    }

    return () => {};
  }, [materialStockLot]);

  /** Main Logic */

  const handleConfirmLot = async (data) => {
    try {
      console.log('Errors', errors);

      const productIn = [
        {
          product: product?._id,
          quantity: data.amount,
          amount: data.amount,
          customer: isMultipleCustomer ? null : selectedCustomer?._id,
          cost_price: data.use_full_price
            ? data.cost_price
            : parseFloat(data.cost_per_unit) * parseFloat(data.amount),
        },
      ];

      const materialOut = _.map(data?.materialOut, (each) => ({
        material: each?.material?._id,
        lotId: each?._id,
        quantity: parseFloat(each?.amount),
        amount: each?.quantity - parseFloat(each?.amount),
      }));

      const payload = {
        materialOut,
        productIn,
        in_txn:
          _.size(inFilterTxnType) === 1
            ? inFilterTxnType?.[0]?._id
            : data?.in_transaction_type,
        out_txn: data?.material_transaction_type,
        place: browserQuery.get('source'),
        destination: browserQuery.get('destination'),
        referenceNumber,
        employee: data?.employee?._id,
        department: data?.department,
        remark: data?.remark,
        inventoryDate: dayjs(data?.receipt_date).toDate(),
      };

      console.log('Payload', payload);
      await dispatch(actions.productTransferFromMaterial(payload));
      setOpenDialogLoading(true);
    } catch (error) {
      alert(`เกิดความผิดพลาด ${error?.message}`);
    }
  };

  /** Display a component  */
  const renderTitle = () => <ViewTitle title={title} subtitle={subtitle} />;

  if (materialTransactionType?.isLoading || !materialTransactionType?.rows) {
    return <Loading />;
  }

  if (
    !materialTransactionType.isLoading &&
    materialTransactionType.isCompleted &&
    materialTransactionType?.rows
  ) {
    return (
      <div>
        {renderTitle()}
        <div className="my-2">
          <BackButton />
        </div>
        <LoadingDialogWithTimeout
          isLoading={dialogLoading}
          label={`กำลังดำเนินการเปลี่ยนวัตถุดิบ ${statusMessage || ''}`}
        />
        <SocketIOLoading
          taskCode={MQ_TASK.TRANSFORM_MATERIAL_TO_PRODUCT.status_code}
          handleSuccess={handleSocketIOFunction.onSuccess}
          handleFail={handleSocketIOFunction.onFail}
          referenceNumber={referenceNumber}
          setStatusMessage={setStatusMessage}
        />

        <div>
          <div>
            <Card>
              <CardContent>
                <div className="flex flex-wrap justify-center items-center">
                  <div className="w-1/3 lg:w-1/6 p-2">
                    <div>คลังต้นทาง (คลังวัตถุดิบ)</div>
                    <div className="text-lg font-semibold">
                      {currentPlace?.name}
                    </div>
                  </div>
                  <div className="w-1/3 lg:w-1/6">
                    <ArrowRight />
                  </div>
                  <div className="w-1/3 lg:w-1/6">
                    {' '}
                    <div>คลังปลายทาง (คลังสินค้า)</div>
                    <div className="text-lg font-semibold">
                      {destinationWarehouse?.name}
                    </div>
                  </div>
                </div>
              </CardContent>
            </Card>
          </div>
          <form onSubmit={handleSubmit(handleConfirmLot)}>
            <div className="w-full my-2">
              <Card>
                <CardContent>
                  <LotInformationForm
                    control={control}
                    departments={department?.rows}
                    employee={employee}
                    me={me?.userData}
                  />
                </CardContent>
              </Card>
            </div>
            <div className="w-full">
              <div className="w-full font-semibold  my-4 ">วัตถุดิบต้นทาง</div>

              <div
                className={`my-2 ${
                  _.size(outFilterTxnType) === 1 ? 'hidden' : ''
                } `}
              >
                <Card>
                  <CardContent>
                    <Controller
                      control={control}
                      name="material_transaction_type"
                      rules={{ require: true }}
                      defaultValue={outFilterTxnType?.[0]?._id}
                      render={({ field }) => (
                        <FormControl fullWidth size="small" required>
                          <InputLabel>ประเภทการขอเบิก</InputLabel>
                          <Select
                            size="small"
                            required
                            {...field}
                            label="ประเภทการขอเบิก"
                            disabled={_.size(outFilterTxnType) === 1}
                          >
                            {_.map(outFilterTxnType, (_txntype, index) => (
                              <MenuItem key={index} value={_txntype?._id}>
                                {_txntype?.name}
                              </MenuItem>
                            ))}
                          </Select>
                          {errors?.material_transaction_type && (
                            <FormHelperText>
                              กรุณาเลือกประเภทการขอเบิก
                            </FormHelperText>
                          )}
                        </FormControl>
                      )}
                    />
                  </CardContent>
                </Card>{' '}
              </div>

              <div className="my-2">
                <SelectMaterialToMoveForm
                  control={control}
                  controlKey={'materialOut'}
                  setValue={setValue}
                  watch={watch}
                  showProduct
                />
              </div>

              <div className="w-full font-semibold  my-4">สินค้าปลายทาง</div>
              <div
                className={`${_.size(outFilterTxnType) === 1 ? 'hidden' : ''}`}
              >
                <Card>
                  <CardContent>
                    <Controller
                      control={control}
                      name="in_transaction_type"
                      rules={{ require: true }}
                      defaultValue={inFilterTxnType?.[0]?._id}
                      render={({ field }) => (
                        <FormControl fullWidth size="small" required>
                          <InputLabel>ประเภทการนำเข้า</InputLabel>
                          <Select
                            size="small"
                            required
                            {...field}
                            label="ประเภทการนำเข้า"
                            disabled={_.size(inFilterTxnType) === 1}
                          >
                            {_.map(inFilterTxnType, (_txntype, index) => (
                              <MenuItem key={index} value={_txntype?._id}>
                                {_txntype?.name}
                              </MenuItem>
                            ))}
                          </Select>
                          {errors?.material_transaction_type && (
                            <FormHelperText>
                              กรุณาเลือกประเภทการนำเข้า
                            </FormHelperText>
                          )}
                        </FormControl>
                      )}
                    />
                  </CardContent>
                </Card>{' '}
              </div>
            </div>
            <div className="w-full my-2 flex flex-wrap">
              <div className="w-full lg:w-1/2 px-2">
                <Card>
                  <CardContent>
                    <div className="text-lg font-semibold">
                      {product?.type_code} {product?.name}
                    </div>
                    <ul>
                      <li className="flex">
                        <div className="w-1/2 ">ปริมาณวัตถุดิบรวม </div>
                        <div className="w-1/2 text-right">
                          {totalMaterialQuantity()?.toFixed(2)}{' '}
                          {materialStockLot?.rows?.[0]?.material?.unit}{' '}
                        </div>
                      </li>
                      <li className="flex">
                        <div className="w-1/2"> เบิกใช้ไปแล้ว </div>
                        <div className="w-1/2 text-right">
                          {parseFloat(
                            totalMaterialQuantity() -
                              totalMaterialAmount(materialStockLot?.rows),
                          )?.toFixed(2)}{' '}
                          {materialStockLot?.rows?.[0]?.material?.unit}{' '}
                        </div>
                      </li>
                      <li className="flex">
                        <div className="w-1/2">
                          ปริมาณวัตถุดิบที่ยังเบิกใช้ได้{' '}
                        </div>
                        <div className="w-1/2  font-semibold text-right">
                          {totalMaterialAmount(materialStockLot?.rows)?.toFixed(
                            2,
                          )}{' '}
                          {materialStockLot?.rows?.[0]?.material?.unit}{' '}
                        </div>
                      </li>
                      <hr className="my-2" />
                      <li className="flex">
                        <div className="w-1/2">
                          สินค้าปลายทาง 1 ชิ้นใช้ปริมาณวัตถุดิบ{' '}
                        </div>
                        <div className="w-1/2  text-right">
                          {productMaterialNetFind()?.toFixed(2)}{' '}
                          {materialStockLot?.rows?.[0]?.material?.unit}{' '}
                        </div>
                      </li>
                      <li className="flex">
                        <div className="w-1/2">
                          ได้สินค้าประมาณ{' '}
                          {totalMaterialAmount(materialStockLot?.rows)?.toFixed(
                            2,
                          )}{' '}
                          / {productMaterialNetFind()?.toFixed(2)}
                        </div>
                        <div className="w-1/2  text-right">
                          {calculateProductQuantity(
                            materialStockLot?.rows,
                          )?.toFixed(2)}{' '}
                          {product?.unit}
                        </div>
                      </li>{' '}
                      <li className="flex">
                        <div className="w-1/2">ลุกค้าที่ได้</div>
                        <div className="w-1/2  text-right">
                          {isMultipleCustomer
                            ? 'หลายลูกค้า'
                            : selectedCustomer?.name || '-'}
                        </div>
                      </li>
                    </ul>
                  </CardContent>
                </Card>
              </div>{' '}
              <div className="w-full lg:w-1/2 px-2">
                <Card>
                  <CardContent>
                    <div>
                      จำนวน ประมาณเป็น{' '}
                      <span className="text-lg font-semibold">
                        {parseInt(
                          calculateProductQuantity(materialStockLot?.rows),
                          10,
                        )}{' '}
                        {product?.unit}
                      </span>
                    </div>
                    <div className="my-2">ได้จริง</div>{' '}
                    <div className="my-2">
                      <Controller
                        control={control}
                        name="amount"
                        render={({ field }) => (
                          <TextField
                            {...field}
                            label="จำนวนที่ได้จริง"
                            size="small"
                            fullWidth
                          />
                        )}
                      />
                    </div>
                    {wmsSetting?.display?.cost_price && (
                      <div>
                        <hr className="my-4" />
                        <div className="my-1">ราคาต้นทุน</div>
                        <div className="my-1">
                          <Controller
                            control={control}
                            name="use_full_price"
                            render={({ field }) => (
                              <div className="flex gap-2 items-center">
                                <div>ใช้ราคาต่อหน่วย</div>
                                <Switch {...field} />
                                <div>ใช้ราคารวม</div>
                              </div>
                            )}
                          />
                        </div>
                        {watch('use_full_price') ? (
                          <div>
                            <Controller
                              control={control}
                              name="cost_price"
                              render={({ field }) => (
                                <TextField
                                  {...field}
                                  label="ราคาต้นทุน"
                                  size="small"
                                  fullWidth
                                  type="number"
                                  onChange={(e) => {
                                    field.onChange(e);
                                    setValue(
                                      'cost_per_unit',
                                      parseFloat(e.target.value) /
                                        parseFloat(watch('amount')),
                                    );
                                  }}
                                />
                              )}
                            />
                          </div>
                        ) : (
                          <div>
                            <Controller
                              control={control}
                              name="cost_per_unit"
                              render={({ field }) => (
                                <TextField
                                  {...field}
                                  label="ราคาต้นทุนต่อหน่วย"
                                  size="small"
                                  fullWidth
                                  type="number"
                                  onChange={(e) => {
                                    field.onChange(e);
                                    setValue(
                                      'cost_price',
                                      parseFloat(e.target.value) *
                                        parseFloat(watch('amount')),
                                    );
                                  }}
                                />
                              )}
                            />
                          </div>
                        )}
                      </div>
                    )}
                  </CardContent>
                </Card>
              </div>
            </div>
          </form>{' '}
          <div className="flex justify-end gap-2 my-4">
            <Button
              type="button"
              onClick={handleSubmit(handleConfirmLot)}
              color="primary"
              variant="contained"
            >
              ยืนยันยอด
            </Button>
          </div>
        </div>
      </div>
    );
  }
  return <Error />;
}

EditLotTransferToProduct.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
};

EditLotTransferToProduct.defaultProps = {
  title: '',
  subtitle: '',
};
