import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useHistory, useParams } from 'react-router-dom';
import {
  BackButton,
  CardInfoMaterialStockLot,
  CardMaterialStockLotListInPlace,
  Error,
  Loading,
  LoadingLinear,
  MaterialPlaceActionCard,
  MaterialStockLotTimeline,
  ViewTitle,
} from '@iarcpsu/emanufac-components/src/components';
import { Button, Card, CardContent } from '@mui/material';
import _ from 'lodash';
import PropTypes from 'prop-types';

import { api } from '@/configs';
import * as actions from '@/redux/actions';

/**
 * @function DetailMaterialStockLot
 * @description Display a collections or a list of EditProcessTemplateTableView from database
 */

export default function DetailMaterialStockLot({ title, subtitle }) {
  const dispatch = useDispatch();
  const history = useHistory();
  const params = useParams();
  const materialStockLot = useSelector((state) => state.materialStockLot);
  const information = useSelector((state) => state.information);
  const me = useSelector((state) => state.me);

  const [previousLotList, setPreviousLotList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const queryDataFromServer = async () => {
    try {
      dispatch(actions.materialStockLotGet(params.id));
    } catch (error) {
      dispatch(actions.materialStockLotError());
    }
  };

  const getPreviousLots = async () => {
    try {
      setIsLoading(true);
      const { data } = await api.get(
        `${process.env.REACT_APP_API_URL}/material-stock-lot/with-previous-lot/${params.id}`,
      );
      const tempPreviousLot = data?.rows;
      const uniqueLots = _.uniqBy(tempPreviousLot, '_id');
      const orderedLot = _.orderBy(uniqueLots, 'createdAt');
      console.log('Unique Lots', orderedLot);
      setPreviousLotList(orderedLot);
    } catch (error) {
      console.error('Cannot Get Previous Lot', error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    queryDataFromServer();
    getPreviousLots();
    return () => {};
  }, [params]);

  const renderTitle = () => <ViewTitle title={title} subtitle={subtitle} />;

  if (materialStockLot.isLoading) {
    return <Loading />;
  }

  if (!materialStockLot.isLoading && materialStockLot.isCompleted) {
    return (
      <div>
        <div className="flex justify-between items-center">
          {renderTitle()}
          <div className="flex items-center gap-1">
            <Link
              to={
                me?.userData?._id === materialStockLot?.employee ||
                me?.userData?.role?.level >= 1
                  ? `/ims/material-stock/edit/${materialStockLot?.id}`
                  : '#'
              }
            >
              <Button
                size="small"
                variant="contained"
                color="warning"
                fullWidth
                disabled={
                  !(
                    me?.userData?._id === materialStockLot?.employee ||
                    me?.userData?.role?.level >= 1
                  )
                }
              >
                แก้ไข
              </Button>
            </Link>{' '}
            <Link to={`/ims/cost-driver/${params?.id}`}>
              <Button size="small" variant="contained" color="info" fullWidth>
                ต้นทุน
              </Button>
            </Link>
            <MaterialPlaceActionCard
              flex="row"
              lot={materialStockLot}
              place={materialStockLot?.place}
            />{' '}
          </div>
        </div>
        <BackButton />
        <div className="my-2">
          <CardInfoMaterialStockLot
            materialStockLot={materialStockLot}
            information={information}
          />
        </div>
        {isLoading && <LoadingLinear />}
        {!_.isEmpty(previousLotList) && (
          <div className="my-2">
            <div className="font-semibold my-2 text-lg">ประวัติล็อต</div>
            <div className="flex flex-wrap">
              <div className="w-full lg:w-3/5 pr-2">
                <Card>
                  <CardContent>
                    <MaterialStockLotTimeline previousLots={previousLotList} />
                  </CardContent>
                </Card>
              </div>
              <div className="w-full lg:w-2/5">
                <CardMaterialStockLotListInPlace
                  previousLots={previousLotList}
                  currentLot={materialStockLot}
                />
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }
  return <Error />;
}

DetailMaterialStockLot.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
};

DetailMaterialStockLot.defaultProps = {
  title: '',
  subtitle: '',
};
