import React, { useContext } from 'react';
import { Card, CardContent } from '@mui/material';
import dayjs from 'dayjs';
import PropTypes from 'prop-types';

import { WordLocalizeContext } from '@/contexts/WordLocalizeContext';

import NameBox from '../Box/Common/NameBox';
import MultiUnitProdOrMatBox from '../Box/Rendering/MultiUnitProdOrMatBox';

const CardInfoMaterialStockLot = ({ materialStockLot, information }) => {
  const imsSetting = information?.setting?.ims;
  const { findWord } = useContext(WordLocalizeContext);
  return (
    <div>
      <Card>
        <CardContent>
          <div className="text-lg font-semibold my-1">
            ข้อมูลล็อตวัตถุดิบปัจจุบัน
          </div>
          <div className="flex flex-wrap">
            <div className="w-full lg:w-1/2">
              <div className="flex w-full my-2">
                <div className="w-1/3 font-semibold">หมายเลขอ้างอิง</div>
                <div className="w-2/3">{materialStockLot?.running_number}</div>
              </div>
              {imsSetting?.display?.lot_number && (
                <div className="flex w-full my-2">
                  <div className="w-1/3 font-semibold">หมายเลขล็อต</div>
                  <div className="w-2/3">{materialStockLot?.lot_number}</div>
                </div>
              )}
              <div className="flex w-full my-2">
                <div className="w-1/3 font-semibold">วันที่รับเข้าคลัง</div>
                <div className="w-2/3">
                  {imsSetting?.showTime
                    ? dayjs(materialStockLot?.receipt_date).format(
                        'D MMM BBBB HH.mm',
                      )
                    : dayjs(materialStockLot?.receipt_date).format(
                        'D MMM BBBB',
                      )}
                </div>
              </div>
              {imsSetting?.display?.purchase_date && (
                <div className="flex w-full my-2">
                  <div className="w-1/3 font-semibold">
                    {findWord('purchase_date') || 'วันที่ซื้อ'}
                  </div>
                  <div className="w-2/3">
                    {dayjs(materialStockLot?.purchase_date).format(
                      'D MMM BBBB',
                    )}
                  </div>
                </div>
              )}
              {imsSetting?.display?.production_date && (
                <div className="flex w-full my-2">
                  <div className="w-1/3 font-semibold">วัดผลิต</div>
                  <div className="w-2/3">
                    {dayjs(materialStockLot?.production_date).format(
                      'D MMM BBBB',
                    )}
                  </div>
                </div>
              )}
              {imsSetting?.display?.expiration_date && (
                <div className="flex w-full my-2">
                  <div className="w-1/3 font-semibold">วันหมดอายุ</div>
                  <div className="w-2/3">
                    {dayjs(materialStockLot?.expiration_date).format(
                      'D MMM BBBB',
                    )}
                  </div>
                </div>
              )}{' '}
              <br className="my-2" />
              <div className="flex w-full my-2">
                <div className="w-1/3 font-semibold">ผู้สร้างล็อต</div>
                <div className="w-2/3">
                  {materialStockLot?.employee ? (
                    <NameBox user={materialStockLot?.employee} />
                  ) : (
                    materialStockLot?.recipient_name
                  )}
                </div>
              </div>{' '}
              <div className="flex w-full my-2">
                <div className="w-1/3 font-semibold">วันที่บันทึกข้อมูล</div>
                <div className="w-2/3">
                  {dayjs(materialStockLot?.createdAt).format(
                    'D MMM BBBB HH.mm',
                  )}
                </div>
              </div>{' '}
              <div className="flex w-full my-2">
                <div className="w-1/3 font-semibold">อัพเดทล่าสุด</div>
                <div className="w-2/3">
                  {dayjs(materialStockLot?.updatedAt).format(
                    'D MMM BBBB HH.mm',
                  )}
                </div>
              </div>{' '}
            </div>{' '}
            <div className="w-full lg:w-1/2">
              <div className="flex w-full my-2">
                <div className="w-1/3 font-semibold">วัตถุดิบ</div>
                <div className="w-2/3">
                  {materialStockLot?.material?.type_code || ''}{' '}
                  {materialStockLot?.material?.name}
                </div>
              </div>{' '}
              <div className="flex w-full my-2">
                <div className="w-1/3 font-semibold">คลังวัตถุดิบ</div>
                <div className="w-2/3">
                  {materialStockLot?.place?.name || '-'}
                </div>
              </div>{' '}
              {imsSetting?.display?.source && (
                <div className="flex w-full my-2">
                  <div className="w-1/3 font-semibold">
                    {findWord('material_source') || 'แหล่งวัตถุดิบ'}
                  </div>
                  <div className="w-2/3">
                    {materialStockLot?.source?.name || '-'}
                  </div>
                </div>
              )}
              {materialStockLot?.place?.place_action?.enable_customer && (
                <div className="flex w-full my-2">
                  <div className="w-1/3 font-semibold">ลูกค้า</div>
                  <div className="w-2/3">
                    {materialStockLot?.customer?.name || '-'}
                  </div>
                </div>
              )}
              {materialStockLot?.place?.place_action
                ?.enable_add_prefer_product && (
                <div className="flex w-full my-2">
                  <div className="w-1/3 font-semibold">สินค้า</div>
                  <div className="w-2/3">
                    {materialStockLot?.prefer_product?.name || '-'}
                  </div>
                </div>
              )}
              <hr className="my-4 w-3/4" />
              <div className="flex w-full my-2">
                <div className="w-1/3 font-semibold">ปริมาณเริ่มต้น</div>
                <div className="w-2/3">
                  <MultiUnitProdOrMatBox
                    foundMaterial={materialStockLot?.material}
                    noWrap
                    quantity={materialStockLot?.quantity}
                  />{' '}
                </div>
              </div>{' '}
              <div className="flex w-full my-2">
                <div className="w-1/3 font-semibold">คงเหลือ</div>
                <div className="w-2/3">
                  <MultiUnitProdOrMatBox
                    foundMaterial={materialStockLot?.material}
                    noWrap
                    quantity={materialStockLot?.amount}
                  />{' '}
                </div>
              </div>{' '}
              <div className="flex w-full my-2">
                <div className="w-1/3 font-semibold">ราคาทั้งล็อต</div>
                <div className="w-2/3">
                  {materialStockLot?.price || '-'} บาท
                  <div className="text-sm">
                    ราคาต่อหน่วย:{' '}
                    {parseFloat(
                      materialStockLot?.price / materialStockLot?.quantity,
                    ).toFixed(2)}{' '}
                    บาท
                  </div>
                </div>
              </div>
            </div>
          </div>
        </CardContent>
      </Card>
    </div>
  );
};

export default CardInfoMaterialStockLot;

CardInfoMaterialStockLot.propTypes = {
  materialStockLot: PropTypes.object,
  information: PropTypes.object,
};
