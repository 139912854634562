/* eslint-disable no-param-reassign */
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import dayjs from 'dayjs';
import PropTypes from 'prop-types';

import {
  IMSDashboard,
  PMSDashboard,
} from '@iarcpsu/emanufac-components/src/components/Dashboards';
import { Error } from '@iarcpsu/emanufac-components/src/components/Error';
import Loading from '@iarcpsu/emanufac-components/src/components/Loading';
import { ViewTitle } from '@iarcpsu/emanufac-components/src/components/ViewTitle';
import * as actions from '../../redux/actions';
import { MATERIAL_REQUEST_STATUS } from '../../utils/constants';
import { getStorage, setStorage } from '../../utils/functions/localstorage';

function ManufacturingDashboard({ title, subtitle }) {
  const dispatch = useDispatch();
  const materialRequest = useSelector((state) => state.materialRequest);
  const material = useSelector((state) => state.material);
  const me = useSelector((state) => state.me);

  const isFullCalendar = getStorage('full_calendar');
  const [startDate, setStartDate] = useState(
    dayjs().startOf('month').toISOString(),
  );
  const [periodText, setPeriodText] = useState('ในเดือนล่าสุด');
  const [endDate, setEndDate] = useState(dayjs().endOf('month').toISOString());

  // console.log('isFullCalendar', isFullCalendar);

  useEffect(() => {
    const fetchMaterterialRequest = async () => {
      try {
        await dispatch(
          actions.materialRequestAll({
            statusCode: MATERIAL_REQUEST_STATUS.PENDING_APPROVAL.status_code,
            startDate,
            endDate,
          }),
        );
      } catch (error) {
        console.error(error);
      }
    };

    const fetchMaterial = async () => {
      try {
        await dispatch(actions.materialAll({}));
      } catch (error) {
        console.error(error);
      }
    };

    const fetchMe = async () => {
      try {
        await dispatch(actions.meGet(''));
      } catch (error) {
        console.error(error);
      }
    };

    const checkFullCalendar = async () => {
      if (!isFullCalendar) {
        await setStorage('full_calendar', true);
      }
    };

    checkFullCalendar();
    fetchMaterterialRequest();
    fetchMaterial();
    fetchMe();
    return () => {};
  }, [startDate, endDate]);

  const renderTitle = () => <ViewTitle title={title} subtitle={subtitle} />;

  const handleChangePeriod = (value) => {
    if (value === 'week') {
      setStartDate(dayjs().startOf('week').toISOString());
      setEndDate(dayjs().endOf('week').toISOString());
      setPeriodText('ในสัปดาห์ล่าสุด');
    } else if (value === 'month') {
      setStartDate(dayjs().startOf('month').toISOString());
      setEndDate(dayjs().endOf('month').toISOString());
      setPeriodText('ในเดือนล่าสุด');
    } else if (value === 'year') {
      setStartDate(dayjs().startOf('year').toISOString());
      setEndDate(dayjs().endOf('year').toISOString());
      setPeriodText('ในปีล่าสุด');
    }
  };
  const renderPeriod = () => (
    <div>
      <FormControl sx={{ minWidth: 120 }}>
        <InputLabel id="type" size={'small'}>
          ช่วงเวลา
        </InputLabel>
        <Select
          label="ช่วงเวลา"
          size={'small'}
          fullWidth
          defaultValue="month"
          onChange={(e) => {
            handleChangePeriod(e.target.value);
          }}
        >
          <MenuItem value="week">สัปดาห์ล่าสุด</MenuItem>
          <MenuItem value="month">เดือนล่าสุด</MenuItem>
          <MenuItem value="year">ปีล่าสุด</MenuItem>
        </Select>
      </FormControl>
    </div>
  );

  if (me.isLoading && material.isLoading) {
    return <Loading />;
  }
  if (me.isCompleted && me.isCompleted && material.isCompleted) {
    return (
      <div>
        {renderTitle()}
        <div className="flex justify-end my-2">{renderPeriod()}</div>
        <PMSDashboard
          materialRequest={materialRequest}
          periodText={periodText}
        />

        <IMSDashboard material={material} />
      </div>
    );
  }
  return <Error />;
}
ManufacturingDashboard.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
};

ManufacturingDashboard.defaultProps = {
  title: '',
  subtitle: '',
};

export default ManufacturingDashboard;
