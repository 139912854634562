import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router';
import {
  BackButton,
  Error,
  Loading,
  PlaceForm,
  ViewTitle,
} from '@iarcpsu/emanufac-components/src/components';
import { Button, Card } from '@mui/material';
import { gql } from 'graphql-request';
import PropTypes from 'prop-types';

import { config } from '@/configs';
import graphqlClient from '@/configs/graphqlClient';

import * as actions from '../../redux/actions';

export default function EditPlace({ title, subtitle }) {
  const dispatch = useDispatch();
  const { id } = useParams();
  const place = useSelector((state) => state.place);
  const department = useSelector((state) => state.department);
  const information = useSelector((state) => state.information);
  const materialType = useSelector((state) => state.materialType);
  const warehouse = useSelector((state) => state.warehouse);
  const [allPlaces, setAllPlaces] = useState();
  const history = useHistory();

  const {
    formState: { errors },
    handleSubmit,
    control,
    setValue,
    watch,
  } = useForm({
    defaultValues: {
      name: place.name,
      address: place.address,
      place_action: place?.place_action,
    },
  });

  const query = gql`
    query FindPlaces($placeInput: PlaceInput) {
      findPlaces(input: $placeInput) {
        total
        rows {
          _id
          name
        }
      }
    }
  `;

  const queryPlaces = async () => {
    try {
      const queryResult = await graphqlClient.request(query, {
        placeInput: {
          page: 1,
          size: config.maxFetchSize,
        },
      });
      const result = queryResult?.findPlaces;
      setAllPlaces(result);
    } catch (error) {
      console.error('Get All Places Fail', error);
    }
  };

  useEffect(() => {
    dispatch(actions.placeGet(id));
    queryPlaces();
    dispatch(
      actions.departmentAll({ name: '', page: 1, size: config.maxFetchSize }),
    );
    dispatch(actions.materialTypeAll({}));
    dispatch(
      actions.warehouseAll({
        page: 1,
        size: config.maxFetchSize,
      }),
    );
    return () => {};
  }, []);

  useEffect(() => {
    if (place?.place_action?.enable) {
      setValue('place_action.enable', place?.place_action?.enable);
    }
    if (place?.place_action?.for_stranger?.group_transfrom_result?.enable) {
      setValue(
        'place_action.for_stranger.group_transfrom_result.enable',
        place?.place_action?.for_stranger?.group_transfrom_result?.enable,
      );
    }
    if (place?.place_action?.for_stranger?.group_transfrom_result?.enable) {
      setValue(
        'place_action.for_stranger.transform',
        place?.place_action?.for_stranger?.transform,
      );
    }

    if (place?.place_action?.add_to_product_warehouse) {
      setValue(
        'place_action.add_to_product_warehouse',
        place?.place_action?.add_to_product_warehouse,
      );
    }

    return () => {};
  }, [place]);

  const renderTitle = () => <ViewTitle title={title} subtitle={subtitle} />;

  const onSubmit = async (data) => {
    try {
      console.log(data);
      await dispatch(actions.placePut(id, data));
      await dispatch(actions.placeGet(id));
      alert('สำเร็จ');
      history.goBack();
    } catch (error) {
      console.log(error);
    }
  };
  if (place.isLoading || place.rows) {
    return <Loading />;
  }
  if (!place.isLoading && place.isCompleted) {
    return (
      <div>
        {renderTitle()}
        <div className="flex flex-row justify-start pb-4">
          <div>
            <BackButton />
          </div>
        </div>
        <div>
          <Card className="p-4">
            <form onSubmit={handleSubmit(onSubmit)}>
              <PlaceForm
                errors={errors}
                place={place}
                control={control}
                department={department}
                information={information}
                materialType={materialType}
                watch={watch}
                allPlaces={allPlaces}
                warehouse={warehouse}
              />
              <div className="flex flex-row justify-end gap-1 py-4">
                <Button variant="contained" type="submit">
                  บันทึก
                </Button>
              </div>
            </form>
          </Card>
        </div>
      </div>
    );
  }
  return <Error />;
}

EditPlace.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
};

EditPlace.defaultProps = {
  title: '',
  subtitle: '',
};
