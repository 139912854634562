import React, { useEffect, useMemo, useState } from 'react';
import { ArrowRight } from 'react-feather';
import { Controller, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import {
  Button,
  Card,
  CardContent,
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from '@mui/material';
import { gql } from 'graphql-request';
import _ from 'lodash';
import hash from 'object-hash';
import PropTypes from 'prop-types';

import {
  BackButton,
  Error,
  Loading,
  LoadingDialogWithTimeout,
  OnlyAddProductForm,
  SelectMaterialToMoveForm,
  SocketIOLoading,
  ViewTitle,
} from '@iarcpsu/emanufac-components/src/components';
import { api, app as appConfig } from '@/configs';
import * as actions from '@/redux/actions';
import { MATERIAL_STOCK_STATUS, MQ_TASK } from '@/utils/constants';

/**
 * @function EditLotTransferToProduct
 * @description Display a collections or a list of WithdrawMaterial from database
 */

const useQuery = () => {
  const { search } = useLocation();
  return useMemo(() => new URLSearchParams(search), [search]);
};

export default function EditLotTransferToProduct({ title, subtitle }) {
  const dispatch = useDispatch();
  const information = useSelector((state) => state.information);
  const me = useSelector((state) => state.me);
  const materialStockLot = useSelector((state) => state.materialStockLot);
  const materialTransactionType = useSelector(
    (state) => state.materialTransactionType,
  );
  const productTransactionType = useSelector(
    (state) => state.productTransactionType,
  );
  const product = useSelector((state) => state.product);
  const browserQuery = useQuery();
  const history = useHistory();
  const [dialogLoading, setOpenDialogLoading] = useState(false);
  const [referenceNumber, setReferenceNumber] = useState('');
  const [statusMessage, setStatusMessage] = useState('');
  const [currentPlace, setCurrentPlace] = useState();
  const [destinationWarehouse, setDestinationWarehouse] = useState();

  useEffect(() => {
    const refNo = hash({
      date: new Date(),
      user: me?.userData?._id,
    });

    setReferenceNumber(refNo);

    return () => {};
  }, []);

  /** Initial the form */

  const {
    control,
    handleSubmit,
    setValue,
    watch,
    formState: { errors },
  } = useForm({
    defaultValues: {
      materialIn: [],
    },
  });

  /** เตรียมข้อมูล */

  const getAllProducts = () => {
    dispatch(
      actions.productGet(browserQuery.get('product'), {
        fetchStockLot: '',
      }),
    );
  };

  const getMaterialTransactionType = async () => {
    try {
      await dispatch(actions.materialTransactionTypeAll({}));
      await dispatch(actions.productTransactionTypeAll({}));
    } catch (error) {
      console.error('Process Get Error', error);
    }
  };

  const getCurrentPlace = async () => {
    try {
      const res = await api.get(
        `${process.env.REACT_APP_API_URL}/place/${browserQuery.get('source')}`,
      );
      setCurrentPlace(res.data);
    } catch (error) {
      console.error('Cannot Gete Current Place', error?.message);
    }
  };

  const getDestination = async () => {
    try {
      if (browserQuery?.get('destination')) {
        const res = await api.get(
          `${process.env.REACT_APP_API_URL}/warehouse/${browserQuery.get(
            'destination',
          )}`,
        );
        setDestinationWarehouse(res.data);
      }
    } catch (error) {
      console.error('Cannot Gete Current Place', error?.message);
    }
  };

  const getMaterialStockLot = () => {
    try {
      dispatch(
        actions.materialStockLotAll({
          size: appConfig.maxFetchSize,
          page: 1,
          ids: browserQuery.get('lotid'),
          stockStatus: '',
        }),
      );
    } catch (error) {
      console.error('Material StockLot Cannot Get', error);
    }
  };

  const inFilterTxnType = _.filter(
    productTransactionType?.rows,
    (each) => each?.direction === 'add',
  );

  const outFilterTxnType = _.filter(
    materialTransactionType?.rows,
    (each) => each?.direction === 'desc',
  );

  const calculateProductQuantity = (stockLotRows) => {
    const filterStockLot = _.filter(
      stockLotRows,
      (each) => each?.prefer_product?._id === product?._id,
    );
    const totalAmount = _.sumBy(filterStockLot, 'amount');
    // FIXME: Make it General
    const productAmount = totalAmount / product?.net;
    return productAmount;
  };

  useEffect(() => {
    getAllProducts();
    getMaterialTransactionType();
    getCurrentPlace();
    getDestination();
    getMaterialStockLot();

    return () => {};
  }, []);

  const handleSocketIOFunction = {
    onSuccess: () => {
      setOpenDialogLoading(false);
      if (browserQuery.get('inplace')) {
        history.push(
          `/wms/product-stock/warehouse/${browserQuery.get('source')}`,
        );
      } else {
        history.push(
          `/wms/product-stock/warehouse/${browserQuery.get('destination')}`,
        );
      }
    },
    onFail: (args) => {
      setOpenDialogLoading(false);
      alert(args?.message);
    },
  };

  useEffect(() => {
    const inStockMaterial = _.filter(
      materialStockLot?.rows,
      (each) => each?.status === MATERIAL_STOCK_STATUS.IN_STOCK.status_code,
    );
    const instockWithSelect = _.map(inStockMaterial, (each) => ({
      ...each,
      selected: true,
    }));
    setValue('materialOut', instockWithSelect);

    return () => {};
  }, [materialStockLot]);

  /** Main Logic */

  const handleRequestMaterial = async (data) => {
    try {
      console.log('Errors', errors);
      // setOpenDialogLoading(true);
      // ถ้ามันเป็นการขอแบบส่ง Request
      //   console.log('Data', data);
      const productIn = [
        {
          product: product?._id,
          quantity: data.amount,
          amount: data.amount,
        },
      ];
      //   if (browserQuery.get('fixedoutput') === 'true') {
      //     productIn = _.filter(data?.materialIn, (each) => each?.quantity !== 0);
      //   } else {
      //     productIn = _.map(data?.productIn, (each) => ({
      //       ...each,
      //       quantity: each?.quantity,
      //       amount: each?.quantity,
      //     }));
      //   }

      const materialOut = _.map(data?.materialOut, (each) => ({
        material: each?.material?._id,
        lotId: each?._id,
        quantity: parseFloat(each?.amount),
        amount: each?.quantity - parseFloat(each?.amount),
      }));

      const payload = {
        materialOut,
        productIn,
        in_txn:
          _.size(inFilterTxnType) === 1
            ? inFilterTxnType?.[0]?._id
            : data?.in_transaction_type,
        out_txn: data?.material_transaction_type,
        place: browserQuery.get('source'),
        destination: browserQuery.get('destination'),
        referenceNumber,
        employee: me?.userData?._id,
      };

      console.log('Payload', payload);
      await dispatch(actions.productTransferFromMaterial(payload));
      setOpenDialogLoading(true);
    } catch (error) {
      alert(`เกิดความผิดพลาด ${error?.message}`);
    }
  };

  /** Display a component  */
  const renderTitle = () => <ViewTitle title={title} subtitle={subtitle} />;

  if (materialTransactionType?.isLoading || !materialTransactionType?.rows) {
    return <Loading />;
  }

  if (
    !materialTransactionType.isLoading &&
    materialTransactionType.isCompleted &&
    materialTransactionType?.rows
  ) {
    return (
      <div>
        {renderTitle()}
        <div className="my-2">
          <BackButton />
        </div>
        <LoadingDialogWithTimeout
          isLoading={dialogLoading}
          label={`กำลังดำเนินการเปลี่ยนวัตถุดิบ ${statusMessage || ''}`}
        />
        <SocketIOLoading
          taskCode={MQ_TASK.TRANSFORM_MATERIAL_TO_PRODUCT.status_code}
          handleSuccess={handleSocketIOFunction.onSuccess}
          handleFail={handleSocketIOFunction.onFail}
          referenceNumber={referenceNumber}
          setStatusMessage={setStatusMessage}
        />

        <div>ระบบยังไม่พร้อมให้บริการ</div>
      </div>
    );
  }
  return <Error />;
}

EditLotTransferToProduct.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
};

EditLotTransferToProduct.defaultProps = {
  title: '',
  subtitle: '',
};
