const MANUFAC_ORDER_STATUS = {
  MANUFAC_ORDER_CREATE: {
    status_code: "MANUFAC_ORDER_CREATE",
    description: "คำสั่งผลิตถูกสร้าง ",
  },
  MANUFAC_ORDER_ON_PRODUCTION_LINE: {
    status_code: "MANUFAC_ORDER_ON_PRODUCTION_LINE",
    description: "อยู่ในระหว่างการเตรียมการผลิต",
  },
  MANUFAC_ORDER_PRODUCING: {
    status_code: "MANUFAC_ORDER_PRODUCING",
    description: "อยู่ระหว่างการผลิต",
  },
  MANUFAC_ORDER_SUCCESS: {
    status_code: "MANUFAC_ORDER_SUCCESS",
    description: "สำเร็จ",
  },
  MANUFAC_ORDER_CANCEL: {
    status_code: "MANUFAC_ORDER_CANCEL",
    description: "ยกเลิก",
  },
};

module.exports = MANUFAC_ORDER_STATUS;
