import React from 'react';
import Chart from 'react-apexcharts';
import { name } from '@iarcpsu/emanufac-constant/information.constant';
import { Card, CardContent, Skeleton } from '@mui/material';
import dayjs from 'dayjs';
import _ from 'lodash';

import PieChart from '../Chart/PieChart';

// eslint-disable-next-line arrow-body-style
const StepAnalysisDashboardElements = ({
  step,
  stepLog,
  showResultProduct = true,
  isReady = true,
}) => {
  const allSteps = [...(step?.rows || []), ...(stepLog?.rows || [])];
  console.log('All Steps', allSteps);
  // Working Status
  const amountOfSteps = _.size(allSteps);
  const successSteps = _.filter(allSteps, { completed: true });
  const failSteps = _.filter(allSteps, { deleted: true });
  const lateSteps = _.filter(
    allSteps,
    (each) =>
      each?.completed === false &&
      each?.deleted === false &&
      dayjs().isAfter(each?.manufacturing_order?.expected_date),
  );
  const runningSteps =
    amountOfSteps -
    _.size(successSteps) -
    _.size(failSteps) -
    _.size(lateSteps);

  // Working Title
  const stepGroupByName = _.groupBy(allSteps, 'name');
  const groupByNameWorkInfo = _.map(stepGroupByName, (value, key) => {
    const success = _.filter(value, { completed: true });
    const fail = _.filter(value, { deleted: true });
    return {
      name: key,
      total: _.size(value),
      success: _.size(success),
      fail: _.size(fail),
    };
  });

  // Working Product
  const stepGroupByProduct = _.groupBy(allSteps, 'product.type_code');
  const groupByProductWorkInfo = _.map(stepGroupByProduct, (value, key) => {
    const success = _.filter(value, { completed: true });
    const fail = _.filter(value, { deleted: true });
    return {
      type_code: key,
      name: value?.[0]?.product?.name,
      total: _.size(value),
      success: _.size(success),
      fail: _.size(fail),
    };
  });

  const productChartOptions = {
    xaxis: {
      categories: _.map(
        groupByProductWorkInfo,
        (each) => `${each.type_code || ''} - ${each.name || ''}`,
      ),
      labels: {
        show: true,
      },
    },
    yaxis: [
      {
        title: {
          text: 'ปริมาณ',
        },
        labels: {
          formatter: (value) => parseFloat(value).toFixed(2),
        },
      },
    ],
    dataLabels: {
      enabled: true,
      enabledOnSeries: [5],
      formatter: (value) => parseFloat(value).toFixed(2),
    },
  };

  return (
    <div className="grid grid-cols-2 gap-2 md:grid-cols-4 lg:grid-cols-6">
      <Card className="col-span-2 p-2">
        <CardContent>
          <div>
            <PieChart
              donut
              labels={['สำเร็จ', 'ไม่สำเร็จ', 'ช้ากว่ากำหนด', 'กำลังดำเนินการ']}
              series={[
                _.size(successSteps),
                _.size(failSteps),
                _.size(lateSteps),
                runningSteps,
              ]}
              height={300}
              anotherOptions={{
                legend: {
                  position: 'bottom',
                },
                colors: ['#00e29b', '#ff465d', '#ffae2a', '#2541B2'],
              }}
            />
          </div>
        </CardContent>
      </Card>
      <Card className="col-span-2 lg:col-span-4 p-2">
        <CardContent>
          <div className="grid grid-cols-3 gap-2 justify-between">
            <div>
              <div>จำนวนงานทั้งหมด</div>
              {isReady ? (
                <div>
                  <span className="text-2xl">{amountOfSteps}</span> งาน
                </div>
              ) : (
                <Skeleton variant="text" width={100} height={30} />
              )}
            </div>
            <div>
              <div>งานที่สำเร็จ</div>
              {isReady ? (
                <div>
                  <span className="text-2xl">{_.size(successSteps)}</span> งาน
                </div>
              ) : (
                <Skeleton variant="text" width={100} height={30} />
              )}
            </div>
            <div>
              <div>งานที่ไม่สำเร็จ / ยกเลิก</div>
              {isReady ? (
                <div>
                  <span className="text-2xl">{_.size(failSteps)}</span> งาน
                </div>
              ) : (
                <Skeleton variant="text" width={100} height={30} />
              )}
            </div>
            <div>
              <div>งานที่ช้ากว่ากำหนด</div>
              {isReady ? (
                <div>
                  <span className="text-2xl">{_.size(lateSteps)}</span> งาน
                </div>
              ) : (
                <Skeleton variant="text" width={100} height={30} />
              )}
            </div>
            <div>
              <div>กำลังดำเนินการ</div>
              {isReady ? (
                <div>
                  <span className="text-2xl">{runningSteps}</span> งาน
                </div>
              ) : (
                <Skeleton variant="text" width={100} height={30} />
              )}
            </div>
          </div>
        </CardContent>
      </Card>
      {showResultProduct && (
        <Card className="col-span-3 p-2">
          <CardContent>
            <div>
              <div>ชื่องานที่ทำ</div>
              {_.size(groupByNameWorkInfo) > 0 ? (
                <PieChart
                  labels={_.map(groupByNameWorkInfo, 'name')}
                  series={_.map(groupByNameWorkInfo, 'total')}
                  donut={true}
                  height={300}
                  anotherOptions={{
                    legend: {
                      position: 'bottom',
                    },
                  }}
                />
              ) : (
                <div className="text-center my-2">ไม่มีข้อมูล</div>
              )}
            </div>
          </CardContent>
        </Card>
      )}
      {showResultProduct && (
        <Card className="col-span-3 p-2">
          <CardContent>
            <div>
              <div>สินค้าที่ผลิต</div>
              {_.size(groupByProductWorkInfo) > 0 ? (
                <Chart
                  type="bar"
                  series={[
                    {
                      name: 'จำนวน',
                      data: _.map(groupByProductWorkInfo, 'total'),
                    },
                  ]}
                  options={productChartOptions}
                  height={300}
                />
              ) : (
                <div className="text-center my-2">ไม่มีข้อมูล</div>
              )}
            </div>
          </CardContent>
        </Card>
      )}
    </div>
  );
};

export default StepAnalysisDashboardElements;
