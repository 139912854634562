/* eslint-disable import/prefer-default-export */
import pdfMake from 'addthaifont-pdfmake/build/pdfmake';
import dayjs from 'dayjs';
import buddhistEra from 'dayjs/plugin/buddhistEra';
import LocalizedFormat from 'dayjs/plugin/localizedFormat';
import _ from 'lodash';
import THBText from 'thai-baht-text';

import currencyFormatterTH from '@/utils/functions/currencyFormatterTH';

import 'addthaifont-pdfmake/build/vfs_fonts';

import { ReportHeader } from './common';

dayjs.locale('th');
dayjs.extend(buddhistEra);
dayjs.extend(LocalizedFormat);

pdfMake.fonts = {
  Sarabun: {
    normal: 'Sarabun-Light.ttf',
    bold: 'Sarabun-Regular.ttf',
    italics: 'Sarabun-LightItalic.ttf',
    bolditalics: 'Sarabun-Italic.ttf',
  },
};

function splitter(inputString, l) {
  let str = inputString;
  const strs = [];
  while (str.length > l) {
    let pos = str.substring(0, l).lastIndexOf(' ');
    pos = pos <= 0 ? l : pos;
    strs.push(str.substring(0, pos));
    let i = str.indexOf(' ', pos) + 1;
    if (i < pos || i > pos + l) {
      i = pos;
    }
    str = str.substring(i);
  }
  strs.push(str);
  return strs;
}

const generateProductRemark = (remark) => {
  const splitWithLodash = _.split(remark, ' ');
  const newWordResult = _.map(splitWithLodash, (word) => {
    if (_.size(word) > 40) {
      const splitedRemark = splitter(word, 40);
      return _.join(splitedRemark, '\n');
    }

    return word;
  });

  return _.join(newWordResult, ' ');
};

const genOrder = (data, information) =>
  _.map(_.filter(data, { deleted: false }), (_data, index) => [
    {
      text: index + 1,
      border: [true, false, true, true],
      fontSize: 10,
      colSpan: 1,
      alignment: 'center',
    },
    {
      text: `${
        _data?.produce_material_instead
          ? _data?.product_as_material?.type_code || ''
          : _data?.product?.type_code || ''
      } ${
        _data?.produce_material_instead
          ? _data?.product_as_material?.name || ''
          : _data?.product?.name || ''
      } ${
        (_data?.produce_base_project_instead &&
          _data?.base_project?.type_code) ||
        ''
      } ${
        (_data?.produce_base_project_instead && _data?.base_project?.name) || ''
      }  \n  ${generateProductRemark(_data?.remark) || ''} `,
      border: [true, false, true, true],
      fontSize: 10,
      colSpan: 1,
    },
    {
      text: `${
        _data?.quantity !== 0
          ? currencyFormatterTH.format(_data?.quantity)
          : 'ไม่ระบุจำนวน'
      } ${
        _data?.quantity !== 0
          ? _data?.additional_unit ||
            (_data?.produce_material_instead
              ? _data?.product_as_material?.unit || ''
              : _data?.product?.unit || '') ||
            ''
          : ''
      }${
        _data?.quantity !== 0
          ? (!_data?.additional_unit &&
              _data?.produce_base_project_instead &&
              _data?.base_project?.unit) ||
            ''
          : ''
      }`,
      border: [true, false, true, true],
      fontSize: 10,
      alignment: 'center',
      colSpan: information?.setting?.mms?.manufacturingOrder?.showPrice ? 1 : 3,
    },
    information?.setting?.mms?.manufacturingOrder?.showPrice
      ? {
          text: `${
            currencyFormatterTH.format(_data?.price_per_unit || 0) || ''
          }`,
          border: [true, false, true, true],
          alignment: 'right',
          fontSize: 10,
          colSpan: 1,
        }
      : {},
    information?.setting?.mms?.manufacturingOrder?.showPrice
      ? {
          text: `${
            currencyFormatterTH.format(
              _data?.price_per_unit * _data?.quantity || 0,
            ) || ''
          }`,
          border: [true, false, true, true],
          alignment: 'right',
          fontSize: 10,
          colSpan: 1,
        }
      : {},
  ]);

export const ManufacturingOrderReport = (
  manufacturingOrder,
  information,
  pictureError = false,
) => {
  const orderData = genOrder(manufacturingOrder?.process, information);

  const genMOStatus = () => {
    const allProcessStartStatus = _.map(
      manufacturingOrder?.process,
      (_process) => _process?.current?.index === -1,
    );

    if (manufacturingOrder?.completed) {
      return 'การผลิตเสร็จสิ้น';
    }
    if (manufacturingOrder?.deleted) {
      return 'การผลิตถูกยกเลิก';
    }
    if (!_.includes(allProcessStartStatus, false)) {
      return 'การผลิตยังไม่เริ่มขึ้น';
    }
    return 'การผลิตอยู่ระหว่างกำลังดำเนินการ';
  };

  const totalPrice =
    _.sumBy(
      _.filter(manufacturingOrder?.process, { deleted: false }),
      (_process) => _process?.price_per_unit * _process?.quantity,
    ) || 0;

  const finalPrice = totalPrice - (manufacturingOrder?.discount || 0);

  const docDefinition = {
    pageSize: 'A4',
    pageOrientation: 'portrait',
    pageMargins: [20, 30, 40, 20],
    defaultStyle: {
      font: 'Sarabun',
      fontSize: '16',
    },
    info: {
      title: 'ใบสั่งงาน',
    },
    content: [
      pictureError
        ? ReportHeader(information, false)
        : ReportHeader(information),
      {
        // หัวข้อ
        alignment: 'center',
        margin: [0, 5, 0, 10],
        text: 'ใบสั่งงาน',
        fontSize: '16',
        bold: true,
      },
      {
        style: 'tableExample',
        table: {
          widths: ['10%', '40%', '18%', '*'],
          heights: [15],
          body: [
            [
              {
                text: 'รหัสลูกค้า',
                colSpan: 1,
                bold: true,
                fontSize: 10,
                border: [true, true, false, false],
              },
              {
                text: `${manufacturingOrder?.customer?.type_code || '-'}`,
                colSpan: 1,
                fontSize: 10,
                border: [false, true, true, false],
              },
              {
                text: 'เลขที่เอกสาร',
                colSpan: 1,
                bold: true,
                fontSize: 10,
                border: [true, true, false, false],
              },
              {
                text: `${manufacturingOrder?.running_number || ''}`,
                colSpan: 1,
                fontSize: 10,
                border: [false, true, true, false],
              },
            ],
            [
              {
                text: 'ลูกค้า',
                colSpan: 1,
                bold: true,
                fontSize: 10,
                border: [true, false, false, false],
              },
              {
                text: `${manufacturingOrder?.customer?.name || '-'}`,
                colSpan: 1,
                fontSize: 10,
                border: [false, false, true, false],
              },
              {
                text: 'วันที่สั่งผลิต',
                colSpan: 1,
                bold: true,
                fontSize: 10,
                border: [true, false, false, false],
              },
              {
                text: `${dayjs(manufacturingOrder?.start_date)
                  .locale('th')
                  .format(
                    information?.setting?.mms?.timing?.showTime
                      ? 'DD MMM BBBB เวลา HH.mm น.'
                      : 'DD MMM BBBB',
                  )}`,
                colSpan: 1,
                fontSize: 10,
                border: [false, false, true, false],
              },
            ],
            [
              {
                text: 'ที่อยู่',
                colSpan: 1,
                bold: true,
                fontSize: 10,
                border: [true, false, false, false],
              },
              {
                text: `${manufacturingOrder?.customer?.address || '-'}`,
                colSpan: 1,
                fontSize: 10,
                border: [false, false, true, false],
              },
              {
                text: information?.setting?.mms?.manufacturingOrder
                  ?.showPaymentMethod
                  ? 'ช่องทางการชำระเงิน'
                  : '',
                colSpan: 1,
                bold: true,
                fontSize: 10,
                border: [true, false, false, false],
              },
              {
                text: information?.setting?.mms?.manufacturingOrder
                  ?.showPaymentMethod
                  ? `${manufacturingOrder?.payment_method?.name || '-'}`
                  : '',
                colSpan: 1,
                fontSize: 10,
                border: [false, false, true, false],
              },
            ],
            [
              {
                text: '',
                colSpan: 1,
                bold: true,
                fontSize: 10,
                border: [true, false, false, false],
              },
              {
                text: `โทร ${
                  manufacturingOrder?.customer?.phone_number || '-'
                }`,
                colSpan: 1,
                fontSize: 10,
                border: [false, false, false, false],
              },
              {
                text: 'ผู้สั่งผลิต',
                colSpan: 1,
                bold: true,
                fontSize: 10,
                border: [true, false, false, false],
              },
              {
                text: ` ${manufacturingOrder?.assigner?.firstname || '-'} ${
                  manufacturingOrder?.assigner?.lastname || ''
                }`,
                colSpan: 1,
                fontSize: 10,
                border: [false, false, true, false],
              },
            ],
            [
              {
                text: '',
                colSpan: 1,
                bold: true,
                fontSize: 10,
                border: [true, false, false, false],
              },
              {
                text: '',
                colSpan: 1,
                fontSize: 10,
                border: [false, false, false, false],
              },
              {
                text: information?.setting?.mms?.manufacturingOrder
                  ?.showQuotation
                  ? 'เลขที่ใบเสนอราคา'
                  : '',
                colSpan: 1,
                bold: true,
                fontSize: 10,
                border: [true, false, false, false],
              },
              {
                text: `${
                  information?.setting?.mms?.manufacturingOrder?.showQuotation
                    ? manufacturingOrder?.quotation_number || '-'
                    : ''
                }`,
                colSpan: 1,
                fontSize: 10,
                border: [false, false, true, false],
              },
            ],
            [
              {
                text: '',
                colSpan: 1,
                bold: true,
                fontSize: 10,
                border: [true, false, false, true],
              },
              {
                text: '',
                colSpan: 1,
                fontSize: 10,
                border: [false, false, false, true],
              },
              {
                text: information?.setting?.mms?.usingAsDefault
                  ?.showExpectedDate
                  ? 'วันที่กำหนดจัดส่ง'
                  : '',
                colSpan: 1,
                bold: true,
                fontSize: 10,
                border: [true, false, false, true],
              },
              {
                text: `${
                  information?.setting?.mms?.usingAsDefault?.showExpectedDate
                    ? dayjs(manufacturingOrder?.expected_date)
                        .locale('th')
                        .format('DD MMM BBBB')
                    : ''
                }`,
                colSpan: 1,
                fontSize: 10,
                border: [false, false, true, true],
              },
            ],
          ],
        },
      },
      {
        margin: [0, 20, 0, 0],
        style: 'tableExample',
        table: {
          widths: ['10%', '*', '20%', '15%', '15%'],
          //  information?.setting?.mms?.manufacturingOrder?.showPrice
          //   ? ['10%', '*', '20%', '15%', '15%']
          //   : ['10%', '*', '20%'],
          heights: (row) => {
            if (row === 0) return 15;
            if (row > _.size(manufacturingOrder?.process)) {
              if (information?.setting?.mms?.manufacturingOrder?.showPrice) {
                return 15;
              }
              return 1;
            }
            return 40;
          },
          body: [
            [
              {
                text: 'ลำดับ',
                colSpan: 1,
                bold: true,
                fontSize: 10,
                alignment: 'center',
                border: [true, true, true, true],
              },
              {
                text: 'รหัสสินค้า / รายละเอียด',
                colSpan: 1,
                bold: true,
                fontSize: 10,
                border: [true, true, true, true],
              },
              {
                text: 'จำนวน',
                colSpan: information?.setting?.mms?.manufacturingOrder
                  ?.showPrice
                  ? 1
                  : 3,
                bold: true,
                fontSize: 10,
                alignment: 'center',
                border: [true, true, true, true],
              },
              information?.setting?.mms?.manufacturingOrder?.showPrice
                ? {
                    text: 'หน่วยละ',
                    colSpan: 1,
                    bold: true,
                    fontSize: 10,
                    alignment: 'center',
                    border: [true, true, true, true],
                  }
                : {},
              information?.setting?.mms?.manufacturingOrder?.showPrice
                ? {
                    text: 'ราคารวมภาษี',
                    colSpan: 1,
                    bold: true,
                    fontSize: 10,
                    alignment: 'center',
                    border: [true, true, true, true],
                  }
                : {},
            ],
            ...orderData,
            information?.setting?.mms?.manufacturingOrder?.showPrice
              ? [
                  {
                    text: '',
                    border: [true, false, false, false],
                    fontSize: 10,
                    colSpan: 2,
                    alignment: 'center',
                  },
                  {},
                  {
                    text: 'รวมเป็นเงิน',
                    border: [false, false, true, false],
                    fontSize: 10,
                    alignment: 'left',
                    colSpan: 2,
                  },
                  {},
                  {
                    text: `${currencyFormatterTH.format(totalPrice) || ''}`,
                    border: [true, false, true, true],
                    alignment: 'right',
                    fontSize: 10,
                    colSpan: 1,
                  },
                ]
              : [
                  {
                    text: '',
                    border: [false, false, false, false],
                    fontSize: 10,
                    colSpan: 5,
                    rowSpan: 5,
                    alignment: 'center',
                  },
                  {},
                  {},
                  {},
                  {},
                ],
            information?.setting?.mms?.manufacturingOrder?.showPrice
              ? [
                  {
                    text: '',
                    border: [true, false, false, false],
                    fontSize: 10,
                    colSpan: 2,
                    alignment: 'center',
                  },
                  {},
                  {
                    text: 'หักส่วนลด',
                    border: [false, false, true, false],
                    fontSize: 10,
                    alignment: 'left',
                    colSpan: 2,
                  },
                  {},
                  {
                    text: `${
                      currencyFormatterTH.format(
                        manufacturingOrder?.discount || 0,
                      ) || ''
                    }`,
                    border: [true, false, true, true],
                    alignment: 'right',
                    fontSize: 10,
                    colSpan: 1,
                  },
                ]
              : [],
            information?.setting?.mms?.manufacturingOrder?.showPrice
              ? [
                  {
                    text: '',
                    border: [true, false, false, false],
                    fontSize: 10,
                    colSpan: 2,
                    alignment: 'center',
                  },
                  {},
                  {
                    text: 'จำนวนเงินรวมทั้งสิ้น',
                    border: [false, false, true, false],
                    fontSize: 10,
                    alignment: 'left',
                    colSpan: 2,
                  },
                  {},
                  {
                    text: `${currencyFormatterTH.format(finalPrice) || ''}`,
                    border: [true, false, true, true],
                    alignment: 'right',
                    fontSize: 10,
                    colSpan: 1,
                  },
                ]
              : [],
            information?.setting?.mms?.manufacturingOrder?.showPrice
              ? [
                  {
                    text: '',
                    border: [true, false, false, true],
                    fontSize: 10,
                    colSpan: 2,
                    alignment: 'center',
                  },
                  {},
                  {
                    text: 'จำนวนภาษีมูลค่าเพิ่ม',
                    border: [false, false, false, true],
                    fontSize: 10,
                    alignment: 'left',
                    colSpan: 1,
                  },
                  {
                    text: '7.00 %',
                    border: [false, false, true, true],
                    alignment: 'right',
                    fontSize: 10,
                    colSpan: 1,
                  },
                  {
                    text: `${
                      currencyFormatterTH.format((finalPrice * 7) / 100) || ''
                    }`,
                    border: [true, false, true, true],
                    alignment: 'right',
                    fontSize: 10,
                    colSpan: 1,
                  },
                ]
              : [],
            information?.setting?.mms?.manufacturingOrder?.showPrice
              ? [
                  {
                    text: `( ${THBText(finalPrice)} )`,
                    border: [true, false, false, true],
                    fontSize: 10,
                    colSpan: 2,
                    alignment: 'left',
                  },
                  {},
                  {
                    text: 'ราคาสินค้า',
                    border: [false, false, true, true],
                    fontSize: 10,
                    alignment: 'left',
                    colSpan: 2,
                  },
                  {},
                  {
                    text: `${
                      currencyFormatterTH.format((finalPrice * 93) / 100) || ''
                    }`,
                    border: [true, false, true, true],
                    alignment: 'right',
                    fontSize: 10,
                    colSpan: 1,
                  },
                ]
              : [],
          ],
        },
      },

      {
        alignment: 'left',
        margin: [0, 10, 0, 5],
        text: 'หมายเหตุ',
        fontSize: '12',
        bold: true,
      },
      {
        alignment: 'left',
        margin: [0, 0, 0, 10],
        text: `${manufacturingOrder?.remark || ''}`,
        fontSize: '10',
      },
      {
        style: 'tableExample',
        table: {
          widths: ['25%', '25%', '25%', '25%'],
          margin: [0, 10, 0, 5],
          heights: [30, 15, 15],
          body: [
            [
              { border: [false, false, false, false], text: '', colSpan: 2 },
              {},
              {
                text: information?.setting?.mms?.manufacturingOrder
                  ?.showDesignerSignature
                  ? 'ออกแบบ'
                  : '',
                colSpan: 1,
                bold: true,
                fontSize: 10,
                alignment: 'center',
                border: [false, false, false, false],
              },
              {
                text: information?.setting?.mms?.manufacturingOrder
                  ?.showAssignerSignature
                  ? ` ${manufacturingOrder?.assigner?.department?.name || ''} `
                  : '',
                colSpan: 1,
                bold: true,
                fontSize: 10,
                alignment: 'center',
                border: [false, false, false, false],
              },
            ],
            [
              { border: [false, false, false, false], text: '', colSpan: 2 },
              {},
              {
                text: information?.setting?.mms?.manufacturingOrder
                  ?.showDesignerSignature
                  ? '........................................'
                  : '',
                colSpan: 1,
                bold: true,
                fontSize: 10,
                alignment: 'center',
                border: [false, false, false, false],
              },
              {
                text: information?.setting?.mms?.manufacturingOrder
                  ?.showAssignerSignature
                  ? '........................................'
                  : '',
                colSpan: 1,
                bold: true,
                fontSize: 10,
                alignment: 'center',
                border: [false, false, false, false],
              },
            ],
            [
              { border: [false, false, false, false], text: '', colSpan: 3 },
              {},
              {},
              {
                text: information?.setting?.mms?.manufacturingOrder
                  ?.showAssignerSignature
                  ? `( ${manufacturingOrder?.assigner?.firstname}  ${manufacturingOrder?.assigner?.lastname} )`
                  : '',
                colSpan: 1,
                bold: true,
                fontSize: 10,
                alignment: 'center',
                border: [false, false, false, false],
              },
            ],
          ],
        },
      },
    ],
    // images: {
    //   logo: information?.logo?.url,
    // },
  };
  console.log('doc definition', docDefinition);
  pdfMake.createPdf(docDefinition).open();
};
