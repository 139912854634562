import React, { useContext } from 'react';
import { Controller } from 'react-hook-form';
import { useSelector } from 'react-redux';
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from '@mui/material';
import _ from 'lodash';
import PropTypes from 'prop-types';

import { WordLocalizeContext } from '@/contexts/WordLocalizeContext';
import { DesktopDatepicker, DesktopDateTimepicker } from '../../DatePicker';
import EmployeeAutoComplete from '../../Inputs/EmployeeAutoComplete';

const LotInformationForm = ({
  control,
  defaultValue,
  employee,
  departments,
  me,
}) => {
  const { findWord } = useContext(WordLocalizeContext);
  const information = useSelector((state) => state.information);
  return (
    <div className="w-full flex flex-wrap">
      {!defaultValue && !_.isEmpty(employee?.rows) && (
        <div className="w-full  lg:w-1/2 px-1">
          <Controller
            name="employee"
            control={control}
            defaultValue={me}
            render={({ field }) => (
              <EmployeeAutoComplete
                field={field}
                employeeRows={employee?.rows}
                label="ผู้ดำเนินการ"
              />
            )}
          />
        </div>
      )}{' '}
      <div className="w-full lg:w-1/2  px-1 mb-2">
        <Controller
          name={'receipt_date'}
          control={control}
          defaultValue={defaultValue?.receipt_date || new Date()}
          rules={{ required: true }}
          // eslint-disable-next-line no-confusing-arrow
          render={({ field }) =>
            information?.setting?.ims?.showTime ? (
              <DesktopDateTimepicker
                {...field}
                label={findWord('receipt_date') || 'วันรับวัตถุดิบ'}
                fullWidth={true}
                size="small"
              />
            ) : (
              <DesktopDatepicker
                {...field}
                label={findWord('receipt_date') || 'วันรับวัตถุดิบ'}
                fullWidth
                size="small"
              />
            )
          }
        />
      </div>
      {!defaultValue && (
        <div className="w-full  lg:w-1/2 px-1 ">
          <Controller
            name={'order_department'}
            control={control}
            defaultValue={me ? me?.department?.id : ''}
            render={({ field }) => (
              <FormControl sx={{ minWidth: 120 }} fullWidth={true} required>
                <InputLabel id="type" size={'small'}>
                  แผนกผู้สั่งซื้อ
                </InputLabel>
                <Select {...field} label="คู่ค้า" size={'small'} fullWidth>
                  {_.size(departments) ? (
                    _.map(departments, (_department) => (
                      <MenuItem key={_department.id} value={_department.id}>
                        {_department.name}
                      </MenuItem>
                    ))
                  ) : (
                    <MenuItem>
                      <MenuItem disabled value="">
                        <em>ไม่มีข้อมูล</em>
                      </MenuItem>
                    </MenuItem>
                  )}
                </Select>
              </FormControl>
            )}
          />
        </div>
      )}
      <div className="w-full  lg:w-1/2  px-1">
        <Controller
          name={'remark'}
          control={control}
          defaultValue={defaultValue?.remark || ''}
          render={({ field }) => (
            <TextField
              {...field}
              label="หมายเหตุ"
              fullWidth
              size={'small'}
              multiline={true}
              rows={3}
            />
          )}
        />
      </div>
    </div>
  );
};

export default LotInformationForm;

LotInformationForm.propTypes = {
  control: PropTypes.object,
  defaultValue: PropTypes.object,
  employee: PropTypes.shape({
    rows: PropTypes.arrayOf(PropTypes.object),
  }),
  departments: PropTypes.arrayOf(PropTypes.object),
  me: PropTypes.object,
};
