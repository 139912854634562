import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router';
import { Link } from 'react-router-dom';
import { Button, Card } from '@mui/material';
import { gql } from 'graphql-request';
import _ from 'lodash';
import hash from 'object-hash';
import PropTypes from 'prop-types';

import {
  BackButton,
  Error,
  Loading,
  LoadingDialogWithTimeout,
  MaterialStockForm,
  NewAddMaterialForm,
  SocketIOLoading,
  ViewTitle,
} from '@iarcpsu/emanufac-components/src/components';
import { config } from '@/configs';
import graphqlClient from '@/configs/graphqlClient';
import * as actions from '@/redux/actions';
import {
  MATERIAL_STOCK_STATUS,
  MQ_TASK,
  TRANSACTION_TYPE,
} from '@/utils/constants';
import { useQuery } from '@/utils/functions';

const CreateMaterialStockLot = ({ title, subtitle }) => {
  const dispatch = useDispatch();
  const material = useSelector((state) => state.material);
  const place = useSelector((state) => state.place);
  const materialTransactionType = useSelector(
    (state) => state.materialTransactionType,
  );
  const query = useQuery();
  const customer = useSelector((state) => state.customer);
  const department = useSelector((state) => state.department);
  const withdrawDestination = useSelector((state) => state.withdrawDestination);
  const me = useSelector((state) => state.me);
  const materialSource = useSelector((state) => state.materialSource);
  const params = useParams();
  const information = useSelector((state) => state.information);
  const employee = useSelector((state) => state.employee);

  const history = useHistory();
  const {
    control,
    formState: { errors },
    handleSubmit,
    watch,
    setValue,
  } = useForm({
    defaultValues: {
      materials: [
        {
          material: null,
        },
      ],
    },
  });

  const tempSelectedType = _.find(
    materialTransactionType?.rows,
    (each) => each?._id === query.get('txnType'),
  );
  const [type, setType] = useState(tempSelectedType);

  const [filterPlace, setFilterPlace] = useState();
  const [referenceNumber, setReferenceNumber] = useState('');
  const [isDialogLoadingOn, setIsDialogLoadingOn] = useState(false);

  useEffect(() => {
    dispatch(
      actions.materialTransactionTypeAll({
        direction: 'add',
      }),
    );
    dispatch(actions.departmentAll(''));
    dispatch(actions.meGet(''));
    dispatch(
      actions.placeAll({ name: '', page: 1, size: config.maxFetchSize }),
    );
    dispatch(
      actions.withdrawDestinationAll({
        name: '',
        page: 1,
        size: config.maxFetchSize,
      }),
    );

    return () => {};
  }, []);

  useEffect(() => {
    const allowedPlace = _.filter(
      place?.rows,
      (eachPlace) =>
        eachPlace?.allow_all_department ||
        _.includes(
          _.map(eachPlace?.allow_departments, (each) => each?._id),
          me?.userData?.department?._id,
        ),
    );

    // If Exist Place ID in URL
    if (params.id) {
      const selectedPlace = _.find(
        place?.rows,
        (eachPlace) => eachPlace?.id === params.id,
      );
      console.log('Selected Place', selectedPlace);
      setFilterPlace(selectedPlace);
    } else {
      setFilterPlace(allowedPlace?.[0]);
      console.log('Set Filter Place to ', allowedPlace?.[0]);
    }

    return () => {};
  }, [place]);

  const gqlQuery = gql`
    query FindMaterial(
      $materialInput: MaterialInput
      $customerInput: CustomerInput
      $materialSourceInput: MaterialSourceInput
      $employeeInput: EmployeeInput
    ) {
      findMaterials(input: $materialInput) {
        currPage
        lastPage
        total
        rows {
          id
          _id
          type_code
          name
          material_type {
            name
          }
          stocklots {
            _id
            supplier {
              name
            }
            status
            place {
              _id
            }
            lot_number
            production_date
            expiration_date
            quantity
            recording_date
            amount
            price
          }
          use_unit_conversion
          unit
          conversion_from {
            unit_a
            unit_b
            conversion_factor
          }
          conversion_to {
            unit_b
            unit_a
            conversion_factor
          }
          unit_input {
            _id
            short_sign
            name
          }
          unit_output {
            _id
            short_sign
            name
          }
        }
      }
      findMaterialSources(input: $materialSourceInput) {
        rows {
          _id
          name
          overall_code
        }
      }
      findCustomers(input: $customerInput) {
        rows {
          _id
          name
          type_code
        }
      }
      findEmployees(input: $employeeInput) {
        rows {
          _id
          firstname
          lastname
          image {
            url
          }
        }
      }
    }
  `;

  // Data Query
  const queryDataFromServer = async () => {
    try {
      const queryResult = await graphqlClient.request(gqlQuery, {
        materialInput: {
          page: 1,
          size: config.maxFetchSize,
          fetchStockLot: false,
          placeRestriction: filterPlace?.inventory_restriction,
          place: filterPlace?._id,
        },
        customerInput: {
          page: 1,
          size: config.maxFetchSize,
        },
        materialSourceInput: {
          page: 1,
          size: config.maxFetchSize,
        },
        employeeInput: {
          page: 1,
          size: config.maxFetchSize,
        },
      });

      const elementData = queryResult?.findMaterials;
      const customerData = queryResult?.findCustomers;
      const materialSourceData = queryResult?.findMaterialSources;
      const employeeData = queryResult?.findEmployees;
      dispatch(actions.materialStateSet(elementData));
      dispatch(actions.customerStateSet(customerData));
      dispatch(actions.materialSourceStateSet(materialSourceData));
      dispatch(actions.employeeStateSet(employeeData));
      setValue('materials', [
        {
          material: null,
        },
      ]);
    } catch (error) {
      alert(error?.message);
      dispatch(actions.materialReset());
    }
  };

  useEffect(() => {
    queryDataFromServer();

    return () => {};
  }, [type?.direction, type, filterPlace]);

  useEffect(() => {
    const refNo = hash({
      date: new Date(),
      user: me?.userData?._id,
    });

    setReferenceNumber(refNo);

    return () => {};
  }, []);

  useEffect(() => {
    if (materialTransactionType?.rows) {
      if (query.get('txnType')) {
        const selectedTxnType = _.find(
          materialTransactionType?.rows,
          (each) => each?._id === query.get('txnType'),
        );
        setType(selectedTxnType);
      } else {
        setType(materialTransactionType?.rows[0]);
      }
    }

    return () => {};
  }, [materialTransactionType]);

  // Recieve Response From SocketIO
  const socketioHandleFunction = {
    onSuccess: () => {
      setIsDialogLoadingOn(false);
      history.goBack();
    },
    onFail: (args) => {
      setIsDialogLoadingOn(false);
      alert(args?.message);
    },
  };

  // Handle Main Logic
  const onSubmit = async (payload, event) => {
    try {
      const data = payload;
      if (errors) {
        console.error('Error', errors);
      }

      // Verification
      if (
        type?.direction === TRANSACTION_TYPE.add.status_code && // add
        _.isEmpty(data.materials)
      ) {
        alert('กรุณาเลือกวัตถุดิบ');
      }

      // Create Transaction
      else if (
        type?.direction === TRANSACTION_TYPE.add.status_code && // add
        !_.isEmpty(data.materials)
      ) {
        data.material = data?.materials;
        if (query.get('txnType')) {
          data.material_transaction_type = query.get('txnType');
        }
        data.status = MATERIAL_STOCK_STATUS.IN_STOCK.status_code;
        data.recording_date = new Date();
        // ถ้าไม่ใส่วันที่ซื้อ ให้วันที่ซื้อเป็นไปตามวันที่รับ
        // การย้ายวัตถุดิบ การเปลี่ยนชนิดวัตถุดิบ ของล็อตที่แปลงหรือยกจากล็อตนี้ไป
        // วันที่รับวัตถุดิบจะเปลี่ยนไป ตามวันที่เข้าไปในคลังนั้นๆ
        //  ในขณะที่วัน Purchase Date จะถูกใช้เป็นวันที่ล็อต
        // แล้วจะถูก copy ไปยังล็อตนั้นๆ ด้วย
        data.purchase_date = data.purchase_date
          ? data.purchase_date
          : data?.receipt_date;
        event.preventDefault();
        data.recipient_name = `${data?.employee?.firstname} ${
          data?.employee?.lastname || ''
        }`;

        await dispatch(
          actions.materialStockLotCreate({ ...data, referenceNumber }),
        );

        console.log('Material Stock Lot Payload', data);

        setIsDialogLoadingOn(true);
      } else {
        alert('เงื่อนไขต่างๆ ยังไม่ถูกต้อง');
        console.error(`Type Direction ${type?.direction}`);
      }
    } catch (error) {
      alert(error?.message);
      console.error(error);
    }
  };

  const renderTitle = () => <ViewTitle title={title} subtitle={subtitle} />;

  if (material.isLoading) {
    return <Loading />;
  }
  if (!material.isLoading && material.isCompleted) {
    return (
      <div>
        <SocketIOLoading
          referenceNumber={referenceNumber}
          taskCode={MQ_TASK.INSERT_MATERIAL.status_code}
          handleFail={socketioHandleFunction.onFail}
          handleSuccess={socketioHandleFunction.onSuccess}
        />
        {isDialogLoadingOn && <LoadingDialogWithTimeout />}
        <div className="flex justify-between items-center">
          <div>{renderTitle()}</div>
          <div>
            <Link to={`/ims/material-stock/create/legacy/${params.id}`}>
              <Button variant="contained">ใช้เวอร์ชั่นเดิม</Button>
            </Link>
          </div>
        </div>
        <div className="flex flex-row justify-start pb-4">
          <div>
            <BackButton />
          </div>
        </div>
        <form>
          <div className="flex flex-row flex-wrap">
            <div className="w-full px-1 py-2">
              <Card className="p-4">
                <div className="py-2">
                  <MaterialStockForm
                    control={control}
                    errors={errors}
                    place={place.rows}
                    materialTransactionType={materialTransactionType.rows}
                    customer={customer.rows}
                    department={department.rows}
                    me={me.userData}
                    type={type}
                    setType={setType}
                    setFilterPlace={setFilterPlace}
                    watch={watch}
                    withdrawDestinations={withdrawDestination?.rows}
                    employee={employee}
                  />
                </div>
              </Card>
            </div>
            <div className="w-full  px-1 py-2">
              <NewAddMaterialForm
                control={control}
                watch={watch}
                setValue={setValue}
                information={information}
                material={material}
                errors={errors}
                customer={customer}
                materialSource={materialSource}
              />
            </div>
          </div>
          <div className="flex flex-row justify-end gap-1 py-4">
            <Button
              variant="contained"
              type="button"
              onClick={handleSubmit(onSubmit)}
            >
              บันทึก
            </Button>
          </div>
        </form>
      </div>
    );
  }
  return <Error message={material?.message} />;
};

CreateMaterialStockLot.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
};

CreateMaterialStockLot.defaultProps = {
  title: '',
  subtitle: '',
};

export default CreateMaterialStockLot;
