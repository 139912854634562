import React from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { Helmet } from 'react-helmet';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Button } from '@mui/material';
import pdfMake from 'addthaifont-pdfmake/build/pdfmake';
import dayjs from 'dayjs';
import buddhistEra from 'dayjs/plugin/buddhistEra';
import LocalizedFormat from 'dayjs/plugin/localizedFormat';
import _ from 'lodash';
import PropTypes from 'prop-types';
import THBText from 'thai-baht-text';

import currencyFormatterTH from '@/utils/functions/currencyFormatterTH';

import 'addthaifont-pdfmake/build/vfs_fonts';

import { ReportHeader } from './common';

dayjs.locale('th');
dayjs.extend(buddhistEra);
dayjs.extend(LocalizedFormat);

pdfMake.fonts = {
  Sarabun: {
    normal: 'Sarabun-Light.ttf',
    bold: 'Sarabun-Regular.ttf',
    italics: 'Sarabun-LightItalic.ttf',
    bolditalics: 'Sarabun-Italic.ttf',
  },
};

function ReportRendering({
  report,
  color = 'primary',
  variant = 'contained',
  buttonLabel = 'พิมพ์',

  // When in Manufacturing Module
  manufacturingOrder,
  // When in Material or Warehouse Module
  materialStockLot = {},
  startDate,
  endDate,
  pointDate,
}) {
  const information = useSelector((state) => state.information);
  const strutureFile = report?.structure;
  const fileBolb = new Blob([strutureFile], { type: 'text/javascript' });
  const history = useHistory();

  const fileURL = URL.createObjectURL(fileBolb);

  const link = document.createElement('a');
  link.download = 'test.js';
  link.href = fileURL;

  const handlePrintReport = () => {
    try {
      if (docDefinition) {
        const createdDocDef = docDefinition({
          information,
          ReportHeader,
          dayjs,
          currencyFormatterTH,
          THBText,
          _,
          manufacturingOrder,
        });
        console.log('hi');
        console.log('documentDefinition', createdDocDef);

        pdfMake.createPdf(createdDocDef).open();
        history.goBack();
      }
    } catch (error) {
      alert(`Template ของคุณอาจจะมีความผิดพลาด ${error?.message}`);
    }
  };

  return (
    <div>
      <ErrorBoundary fallback={<div>Err</div>}>
        <Helmet>
          <script src={fileURL} type="text/javascript" />
        </Helmet>
      </ErrorBoundary>
      {fileURL && (
        <Button color={color} variant={variant} onClick={handlePrintReport}>
          {buttonLabel}
        </Button>
      )}
    </div>
  );
}

export default ReportRendering;

ReportRendering.propTypes = {
  report: PropTypes.object,
  color: PropTypes.string,
  variant: PropTypes.string,
  manufacturingOrder: PropTypes.object,
  buttonLabel: PropTypes.string,
  materialStockLot: PropTypes.object,
  startDate: PropTypes.object,
  endDate: PropTypes.object,
  pointDate: PropTypes.object,
};
