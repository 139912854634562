import React from 'react';
import {
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
} from '@mui/material';
import _ from 'lodash';
import PropTypes from 'prop-types';

export function CustomerForm({
  errors,
  customerType,
  customer,
  Controller,
  control,
  paymentType,
  fields,
  append,
  remove,
  watch,
}) {
  return (
    <div className="flex flex-row flex-wrap w-full">
      <div className="w-full font-semibold">ข้อมูลลูกค้า</div>
      <div className="w-full lg:w-1/2 px-1 py-2">
        <Controller
          name={'type'}
          control={control}
          defaultValue={customer?.type?._id ? customer?.type?._id : ''}
          rules={{ required: true }}
          render={({ field }) => (
            <FormControl sx={{ minWidth: 120 }} fullWidth={true} required>
              <InputLabel id="type" size={'small'}>
                หมวดหมู่
              </InputLabel>
              <Select {...field} label="หมวดหมู่" size={'small'} fullWidth>
                {_.size(customerType?.rows) ? (
                  _.map(customerType.rows, (row) => (
                    <MenuItem key={row.id} value={row.id}>
                      {row.name}
                    </MenuItem>
                  ))
                ) : (
                  <MenuItem>
                    <MenuItem disabled value="">
                      <em>ไม่มีข้อมูล</em>
                    </MenuItem>
                  </MenuItem>
                )}
              </Select>
            </FormControl>
          )}
        />
      </div>
      <div className="w-full lg:w-1/2 px-1 py-2">
        <Controller
          name={'organization_type'}
          control={control}
          defaultValue={customer ? customer.organization_type : 'บุคคลธรรมดา'}
          render={({ field }) => (
            <FormControl sx={{ minWidth: 120 }} fullWidth={true}>
              <InputLabel id="type" size={'small'}>
                ประเภทธุรกิจ
              </InputLabel>
              <Select {...field} label="ประเภทธุรกิจ" size={'small'} fullWidth>
                <MenuItem value="บุคคลธรรมดา">บุคคลธรรมดา</MenuItem>
                <MenuItem value="นิติบุคคล">นิติบุคคล</MenuItem>
              </Select>
            </FormControl>
          )}
        />
      </div>
      <div className="w-full lg:w-1/4  px-1 py-2">
        <Controller
          name={'type_code'}
          control={control}
          defaultValue={customer ? customer.type_code : ''}
          rules={{ required: false }}
          render={({ field }) => (
            <TextField
              {...field}
              label="รหัสลูกค้า / คู่ค้า"
              fullWidth
              size={'small'}
              helperText={errors.type_code && 'กรุณาใส่ข้อมูล'}
            />
          )}
        />
      </div>
      <div className="w-full lg:w-3/4  px-1 py-2">
        <Controller
          name={'name'}
          control={control}
          defaultValue={customer ? customer.name : ''}
          rules={{ required: true }}
          render={({ field }) => (
            <TextField
              {...field}
              label="ชื่อ"
              fullWidth
              size={'small'}
              required
              helperText={errors.name && 'กรุณาใส่ข้อมูล'}
            />
          )}
        />
      </div>
      <div className="w-full  px-1 py-2">
        <Controller
          name={'phone_number'}
          control={control}
          defaultValue={customer ? customer.phone_number : ''}
          render={({ field }) => (
            <TextField
              {...field}
              label="เบอร์โทรศัพท์"
              fullWidth
              size={'small'}
              helperText={errors.phone_number && 'กรุณาใส่ข้อมูล'}
            />
          )}
        />
      </div>
      <hr className="w-full my-4" />
      <div className="w-full px-1 py-1">
        <Controller
          name={'address'}
          control={control}
          defaultValue={customer ? customer.address : ''}
          rules={{ required: false }}
          render={({ field }) => (
            <TextField
              {...field}
              label="ที่อยู่"
              rows={3}
              multiline={true}
              fullWidth
              size={'small'}
              helperText={errors.address && 'กรุณาใส่ข้อมูล'}
            />
          )}
        />
      </div>
      <div className="w-full lg:w-1/2 px-1 py-2">
        <Controller
          name={'taxes'}
          control={control}
          defaultValue={customer ? customer.taxes : ''}
          rules={{ required: false }}
          render={({ field }) => (
            <TextField
              {...field}
              label="เลขที่ผู้เสียภาษี"
              fullWidth
              size={'small'}
              helperText={errors.taxes && 'กรุณาใส่ข้อมูล'}
            />
          )}
        />
      </div>

      <div className="w-full lg:w-1/2 px-1 py-2">
        <Controller
          name={'email'}
          control={control}
          defaultValue={customer ? customer.email : ''}
          rules={{ required: false }}
          render={({ field }) => (
            <TextField
              {...field}
              label="อีเมล"
              fullWidth
              size={'small'}
              helperText={errors.email && 'กรุณาใส่ข้อมูล'}
            />
          )}
        />
      </div>
      <div className="w-full lg:w-1/2 px-1 py-2">
        <Controller
          name={'fax'}
          control={control}
          defaultValue={customer ? customer.fax : ''}
          rules={{ required: false }}
          render={({ field }) => (
            <TextField
              {...field}
              label="แฟกซ์"
              fullWidth
              size={'small'}
              helperText={errors.fax && 'กรุณาใส่ข้อมูล'}
            />
          )}
        />
      </div>
      {!_.isEmpty(paymentType.rows) && (
        <div className="w-full lg:w-1/2 px-1 py-2">
          <Controller
            name={'payment'}
            control={control}
            defaultValue={customer?.payment?._id}
            render={({ field }) => (
              <FormControl sx={{ minWidth: 120 }} fullWidth={true}>
                <InputLabel id="type" size={'small'}>
                  รูปแบบการชำระเงิน
                </InputLabel>
                <Select
                  {...field}
                  label="รูปแบบการชำระเงิน"
                  size={'small'}
                  fullWidth
                >
                  {_.size(paymentType?.rows) ? (
                    _.map(paymentType.rows, (row) => (
                      <MenuItem key={row.id} value={row.id}>
                        {row.name}
                      </MenuItem>
                    ))
                  ) : (
                    <MenuItem>
                      <MenuItem disabled value="">
                        <em>ไม่มีข้อมูล</em>
                      </MenuItem>
                    </MenuItem>
                  )}
                </Select>
              </FormControl>
            )}
          />
        </div>
      )}
      <div className="w-full px-1 py-2">
        <Controller
          control={control}
          defaultValue={customer?.is_headquator}
          name="is_headquator"
          render={({ field }) => (
            <FormControl>
              <FormControlLabel
                label="สำนักงานใหญ่"
                control={<Checkbox {...field} checked={field.value} />}
              />
            </FormControl>
          )}
        />
      </div>

      {!watch('is_headquator') && (
        <div className="w-full lg:w-1/2 px-1 py-2">
          <Controller
            control={control}
            defaultValue={customer?.branch_code}
            name="branch_code"
            render={({ field }) => (
              <TextField size="small" {...field} label="รหัสสาขา" fullWidth />
            )}
          />
        </div>
      )}

      {!watch('is_headquator') && (
        <div className="w-full lg:w-1/2 px-1 py-2">
          <Controller
            control={control}
            defaultValue={customer?.branch_name}
            name="branch_name"
            render={({ field }) => (
              <TextField size="small" {...field} label="ชื่อสาขา" fullWidth />
            )}
          />
        </div>
      )}

      <div className="w-full font-semibold my-2">ข้อมูลผู้ติดต่อ</div>
      <TableContainer>
        <Table size="small" className="border-lg">
          <TableHead className="bg-blue-100">
            <TableRow>
              <TableCell>
                <div className="font-bold">ลำดับที่</div>
              </TableCell>
              <TableCell>
                <div className="font-bold"> ชื่อ</div>
              </TableCell>
              <TableCell>
                <div className="font-bold"> เบอรโทร</div>
              </TableCell>
              <TableCell>
                <div className="font-bold"> ดำเนินการ</div>
              </TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {_.size(fields) ? (
              fields.map((_field, index) => (
                <TableRow key={_field.id}>
                  <TableCell>{index + 1}</TableCell>
                  <TableCell>
                    <Controller
                      name={`contact.[${index}].name`}
                      control={control}
                      rules={{ required: true }}
                      render={({ field }) => (
                        <TextField
                          {...field}
                          label="ชื่อ"
                          fullWidth
                          required
                          size={'small'}
                          helperText={errors.name && 'กรุณาใส่ข้อมูล'}
                        />
                      )}
                    />
                  </TableCell>
                  <TableCell>
                    <Controller
                      name={`contact.[${index}].phone_number`}
                      control={control}
                      rules={{ required: true }}
                      render={({ field }) => (
                        <TextField
                          {...field}
                          label="เบอร์โทร"
                          fullWidth
                          required
                          size={'small'}
                          helperText={errors.phone_number && 'กรุณาใส่ข้อมูล'}
                        />
                      )}
                    />
                  </TableCell>
                  <TableCell>
                    <Button
                      color={'error'}
                      variant="contained"
                      size={'small'}
                      onClick={() => remove(index)}
                    >
                      ลบ
                    </Button>
                  </TableCell>
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={4}>
                  <div className="text-center py-2">ไม่มีรายการ</div>
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <div className="px-2 py-2">
        <Button
          color={'success'}
          variant="outlined"
          size={'small'}
          onClick={() =>
            append({
              name: '',
              phone_number: '',
            })
          }
        >
          เพิ่มรายการ
        </Button>
      </div>
    </div>
  );
}

CustomerForm.propTypes = {
  customerType: PropTypes.object,
  errors: PropTypes.shape({
    name: PropTypes.object,
    address: PropTypes.object,
    type_code: PropTypes.object,
    organization_type: PropTypes.object,
    taxes: PropTypes.object,
    phone_number: PropTypes.object,
    fax: PropTypes.object,
    email: PropTypes.object,
    payment: PropTypes.object,
  }),
  customer: PropTypes.object,
  paymentType: PropTypes.object,
  Controller: PropTypes.func,
  control: PropTypes.object,
  fields: PropTypes.object,
  append: PropTypes.func,
  remove: PropTypes.func,
};

CustomerForm.defaultProps = {
  customer: null,
  paymentType: null,
};

export default CustomerForm;
