import React, { useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import {
  BackButton,
  Error,
  Loading,
  MaterialTypeForm,
  ViewTitle,
} from '@iarcpsu/emanufac-components/src/components';
import { Button, Card } from '@mui/material';
import PropTypes from 'prop-types';

import * as actions from '../../redux/actions';

export default function EditMaterialType({ title, subtitle }) {
  const dispatch = useDispatch();
  const { id } = useParams();
  const materialType = useSelector((state) => state.materialType);
  const {
    formState: { errors },
    handleSubmit,
    control,
  } = useForm({
    defaultValues: {
      name: materialType.name,
      description: materialType.description,
    },
  });

  useEffect(() => {
    dispatch(actions.materialTypeGet(id));
    return () => {};
  }, []);

  const renderTitle = () => <ViewTitle title={title} subtitle={subtitle} />;

  const onSubmit = async (data) => {
    try {
      console.log(data);
      await dispatch(actions.materialTypePut(id, data));
      alert('สำเร็จ');
      await dispatch(actions.materialTypeGet(id));
    } catch (error) {
      console.log(error);
    }
  };
  if (materialType.isLoading || materialType.rows) {
    return <Loading />;
  }
  if (!materialType.isLoading && materialType.isCompleted) {
    return (
      <div>
        {renderTitle()}
        <div className="flex flex-row justify-start pb-4">
          <div>
            <BackButton />
          </div>
        </div>
        <div>
          <Card className="p-4">
            <form onSubmit={handleSubmit(onSubmit)}>
              <MaterialTypeForm
                errors={errors}
                materialType={materialType}
                control={control}
                Controller={Controller}
              />
              <div className="flex flex-row justify-end gap-1 py-4">
                <Button variant="contained" type="submit">
                  บันทึก
                </Button>
              </div>
            </form>
          </Card>
        </div>
      </div>
    );
  }
  return <Error />;
}

EditMaterialType.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
};

EditMaterialType.defaultProps = {
  title: '',
  subtitle: '',
};
