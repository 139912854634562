import React, { useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { Button, Card, CardContent, LinearProgress } from '@mui/material';
import { gql } from 'graphql-request';
import _ from 'lodash';
import hash from 'object-hash';
import PropTypes from 'prop-types';

import {
  BackButton,
  Error,
  Loading,
  LoadingDialogWithTimeout,
  MaterialMergingDisplay,
  MaterialYieldForm,
  MergingProcessForm,
  SocketIOLoading,
  SplittingProcessForm,
  ViewTitle,
} from '@iarcpsu/emanufac-components/src/components';
import { config } from '@/configs';
import graphqlClient from '@/configs/graphqlClient';
import * as actions from '@/redux/actions';
import { MANUFACTURING_TRANSACTION_TYPE, MQ_TASK } from '@/utils/constants';
import { MMS } from '@/utils/functions';

/**
 * @function HandleNextStep
 * @description Display a collections or a list of HandleNextStep from database
 */

const useQuery = () => {
  const { search } = useLocation();
  return useMemo(() => new URLSearchParams(search), [search]);
};

export default function HandleNextStep({ title, subtitle }) {
  const dispatch = useDispatch();
  const browserQuery = useQuery();
  const selectedProcess = useSelector((state) => state.process);
  const me = useSelector((state) => state.me);
  const information = useSelector((state) => state.information);
  const history = useHistory();
  const [loadingDialogOn, setIsLoadingDialogOn] = useState(false);
  const [referenceNumber, setReferenceNumber] = useState('');
  const [isReady, setIsReady] = useState(false);

  const params = useParams();
  const query = gql`
    query FindHandleNextStep(
      $processInput: ProcessInput
      $machineInput: MachineInput
    ) {
      findOneProcess(input: $processInput) {
        _id
        current {
          step {
            _id
            index
            name
            completed
            deleted
            responsible {
              employee {
                _id
                firstname
                lastname
              }
              department {
                _id
                name
              }
            }
            material_incoming_method
            material_associate
            is_process_merging_point
            is_process_splitting_point
            measurements {
              _id
              measurement_type {
                _id
                name
                description
                data_type
                type_code
                default_value
                label_left
                label_right
                default_boolean_value
                label_on_additional_text
                required
              }
              value
            }
            measurementTypes {
              _id
              name
            }
          }
          index
        }
        manufacturing_materials {
          _id
          material {
            _id
            name
            type_code
            use_unit_conversion
            unit
            conversion_from {
              unit_a
              unit_b
              conversion_factor
            }
            conversion_to {
              unit_b
              unit_a
              conversion_factor
            }
            unit_input {
              _id
              short_sign
              name
            }
            unit_output {
              _id
              short_sign
              name
            }
            common_price
          }
          transaction_type
          begin
          quantity
          amount
        }
        completed
        deleted
        steps {
          _id
          index
          name
          material_incoming_method
          use_set_of_material
          is_process_merging_point
          is_process_splitting_point
          main_material {
            _id
            name
            type_code
            use_unit_conversion
            unit
            conversion_from {
              unit_a
              unit_b
              conversion_factor
            }
            conversion_to {
              unit_b
              unit_a
              conversion_factor
            }
            unit_input {
              _id
              short_sign
              name
            }
            unit_output {
              _id
              short_sign
              name
            }
            is_workin_process
            input_materials {
              material {
                _id
              }
              fraction
            }
          }
        }
        product {
          steps {
            name
          }
        }
        main_process
        is_minor_process
      }
      findMachines(input: $machineInput) {
        rows {
          _id
          name
        }
      }
    }
  `;

  const currentProcessIndex = selectedProcess?.current?.index;
  const nextStep = _.find(
    selectedProcess?.steps,
    (each) => each?.index === currentProcessIndex + 1,
  );

  const {
    handleEditMaterialYield,
    ProcessStatusCheck,
    MaterialAnalyzer: { unNullManufacMaterialAnalyzedArrayAnalzer },
  } = MMS;

  const processStatus = ProcessStatusCheck(
    selectedProcess,
    selectedProcess?.current?.step,
    information,
  );

  const queryDataFromServer = async () => {
    const queryResult = await graphqlClient.request(query, {
      processInput: {
        id: params.id,
        fetchInsideProcess: true,
        fetchCurrentStep: true,
      },
      machineInput: {
        page: 1,
        size: config.maxFetchSize,
      },
    });
    const processData = queryResult?.findOneProcess;
    const machineData = queryResult?.findMachines;
    dispatch(actions.processStateOneSet(processData));
    dispatch(actions.machineStateSet(machineData));
  };

  useEffect(() => {
    queryDataFromServer();
    return () => {};
  }, []);

  const manufacMatAnalyze = unNullManufacMaterialAnalyzedArrayAnalzer(
    selectedProcess?.manufacturing_materials,
  );

  const notAdmin = me?.userData?.role?.level <= 0;
  const vacantWork = !selectedProcess?.current?.step?.responsible?.employee;
  const notYourWork =
    selectedProcess?.current?.step?.responsible?.employee?._id !==
    me?.userData?._id;
  const notYourDeptWork =
    me?.userData?.department?._id !==
    selectedProcess?.current?.step?.responsible?._id;
  const notYourOtherDeptWork = !_.includes(
    _.map(me?.userData?.other_departments, (_dept) => _dept?._id),
    selectedProcess?.current?.step?.responsible?.department,
  );

  // ดักให้เฉพาะผู้รับ
  const disableEditing =
    notAdmin &&
    vacantWork &&
    notYourWork &&
    notYourDeptWork &&
    notYourOtherDeptWork;

  const {
    control,
    handleSubmit,
    watch,
    setValue,
    formState: { errors },
  } = useForm();

  useEffect(() => {
    const newManufacMatAnalyze = unNullManufacMaterialAnalyzedArrayAnalzer(
      selectedProcess?.manufacturing_materials,
    );

    const newMaterials = _.map(newManufacMatAnalyze, (_material) => {
      const eachNewMaterialData = {
        begin: _material?.resultAmount,
        end: _material?.resultAmount,
        return: _material?.returnAmount,
        material: _material?.materialInfo,
      };
      return eachNewMaterialData;
    });

    const filterNewMaterial = _.filter(
      newMaterials,
      (each) => each?.begin !== 0,
    );
    setValue('materials', filterNewMaterial);
    // setValue('materials', newMaterials);

    if (selectedProcess?.current?.step?.measurements) {
      const measurementWithDefaultValue = _.map(
        selectedProcess?.current?.step?.measurements,
        (eachMeasurement) => {
          if (!eachMeasurement?.value || eachMeasurement?.value === '') {
            eachMeasurement.value =
              eachMeasurement?.measurement_type?.default_value;
          }
          return eachMeasurement;
        },
      );
      const measurementInOrderOfMeasurementType = _.map(
        selectedProcess?.current?.step?.measurementTypes,
        (eachType) => {
          const foundMeasurement = _.find(
            measurementWithDefaultValue,
            (eachMeasurement) =>
              eachMeasurement?.measurement_type?._id === eachType?._id,
          );
          return foundMeasurement;
        },
      );
      setValue('measurements', measurementInOrderOfMeasurementType);
      setIsReady(true);
    }

    return () => {};
  }, [selectedProcess?.manufacturing_materials]);

  const handleSocketIOFunction = {
    onSuccess: () => {
      setIsLoadingDialogOn(false);

      if (browserQuery?.get('fetch-txn')) {
        console.info('fetchManufacTxn');
        dispatch(
          actions.manufacTxnAll({
            processId: selectedProcess?._id,
            size: 200,
            page: 1,
          }),
        );
      }

      window.alert('บันทึกข้อมูลให้กระบวนการผลิตสำเร็จ');
      history.goBack();
      queryDataFromServer();
    },
    onFail: (args) => {
      setIsLoadingDialogOn(false);
      console.log('Error', args?.error);
      window.alert(`บันทึกข้อมูลไม่สำเร็จ ${args?.message}`);
      queryDataFromServer();
    },
  };

  const onSubmit = async (data) => {
    try {
      const tempRefNo = hash({
        date: new Date(),
        user: me?.userData?._id,
      });

      setReferenceNumber(tempRefNo);
      setIsLoadingDialogOn(true);

      await handleEditMaterialYield({
        dispatch,
        data,
        selectedProcess,
        step: selectedProcess?.current?.step,
        employeeId: me?.userData?._id,
        successHandle: () => {},
        information,
      });

      if (processStatus.step.isLaststep && !selectedProcess?.is_minor_process) {
        await dispatch(
          actions.stepPost({
            process: selectedProcess?._id,
            employeeId: me?.userData?._id,
            manufacturing_transaction_type:
              MANUFACTURING_TRANSACTION_TYPE.WORK_CLOSE.status_code,
            referenceNumber: tempRefNo,
          }),
        );
      } else {
        const payload = {
          process: selectedProcess?._id,
          materials: _.map(
            unNullManufacMaterialAnalyzedArrayAnalzer(
              selectedProcess?.manufacturing_materials,
            ),
            (_material) => ({
              material: _material?.materialInfo?._id,
              end: _material?.resultAmount,
              wastes: 0,
            }),
          ),
          employeeId: me?.userData?._id,
          manufacturing_transaction_type:
            MANUFACTURING_TRANSACTION_TYPE.WORK_CLOSE.status_code,
          referenceNumber: tempRefNo,
          amount_of_splitting: data?.amount_of_splitting,
          material_after_splitting: data?.material_after_splitting,
          splitting_line_list: data.splitting_line_list,
          main_process_materials: data.main_process_materials,
        };
        console.log('Next Step Payload', payload);
        await dispatch(actions.stepPost(payload));
        // await dispatch(actions.processGet(selectedProcess?._id));
      }
    } catch (err) {
      alert(`เกิดข้อผิดพลาดเกิดขึ้น ${err?.message}`);
      setIsLoadingDialogOn(false);
    }
  };

  const renderTitle = () => <ViewTitle title={title} subtitle={subtitle} />;

  if (selectedProcess.isLoading) {
    return <Loading />;
  }

  if (!selectedProcess.isLoading && selectedProcess.isCompleted) {
    return (
      <div>
        {renderTitle()}
        <div className="my-2">
          <BackButton />
        </div>
        <SocketIOLoading
          taskCode={MQ_TASK.MOVING_STEP_UP.status_code}
          referenceNumber={referenceNumber}
          handleSuccess={handleSocketIOFunction.onSuccess}
          handleFail={handleSocketIOFunction.onFail}
        />
        <div>
          <LoadingDialogWithTimeout
            isLoading={loadingDialogOn}
            label="กำลังบันทึกการใช้งานวัตถุดิบ"
          />
          <Card>
            <CardContent>
              {processStatus?.step?.isLaststep ? <div>ขั้นตอนสุดท้าย</div> : ''}

              <form onSubmit={handleSubmit(onSubmit)}>
                {isReady ? (
                  <MaterialYieldForm
                    step={selectedProcess?.current?.step}
                    manufacturingMaterials={
                      selectedProcess?.manufacturing_materials
                    }
                    control={control}
                    setValue={setValue}
                    errors={errors}
                    disableEditing={disableEditing}
                    watch={watch}
                  />
                ) : (
                  <LinearProgress />
                )}
                {!_.isEmpty(watch('materials')) &&
                _.size(watch('materials')) > 1 ? (
                  <MaterialMergingDisplay
                    currentProcess={selectedProcess}
                    watchedMaterial={watch('materials')}
                  />
                ) : (
                  <div></div>
                )}
                {nextStep?.is_process_splitting_point && (
                  <SplittingProcessForm
                    nextStep={nextStep}
                    allSteps={selectedProcess?.steps}
                    control={control}
                    watch={watch}
                    setValue={setValue}
                  />
                )}{' '}
                {selectedProcess?.current?.step?.is_process_merging_point && (
                  <MergingProcessForm
                    watchedMaterial={watch('materials')}
                    selectedProcess={selectedProcess}
                    setValue={setValue}
                  />
                )}
                <div className="flex flex-wrap justify-end mt-2 gap-2">
                  <Button
                    variant="contained"
                    type="submit"
                    disabled={disableEditing}
                  >
                    บันทึก
                  </Button>
                </div>
              </form>
            </CardContent>
          </Card>
        </div>
      </div>
    );
  }
  return <Error />;
}

HandleNextStep.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
};

HandleNextStep.defaultProps = {
  title: '',
  subtitle: '',
};
