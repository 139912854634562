import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Button, Card, CardContent } from '@mui/material';
import PropTypes from 'prop-types';

import {
  BackButton,
  PDFReportForm,
  ViewTitle,
} from '@iarcpsu/emanufac-components/src/components';
import { config } from '@/configs';
import * as actions from '@/redux/actions';

/**
 * @function CreatePDFReport
 * @description Display a collections or a list of CreatePDFReport from database
 */

export default function CreatePDFReport({ title, subtitle }) {
  const dispatch = useDispatch();
  const history = useHistory();
  const place = useSelector((state) => state.place);
  const warehouse = useSelector((state) => state.warehouse);
  const defaultPDFHead = `

 docDefinition = ({information,ReportHeader,dayjs,currencyFormatterTH,THBText,_,manufacturingOrder,materialStockLot,startDate,endDate,pointDate})=> {
  return {
    pageSize: 'A4',
    pageOrientation: 'portrait',
    pageMargins: [20, 30, 40, 20],
    defaultStyle: {
      font: 'Sarabun',
      fontSize: '9',
    },
  info: {
    title: '',
  },   
  content: [
    ReportHeader(information),
  ]
}}

`;

  useEffect(() => {
    dispatch(actions.warehouseAll({ page: 1, size: config.maxFetchSize }));
    dispatch(actions.placeAll({ page: 1, size: config.maxFetchSize }));

    return () => {};
  }, []);

  const { control, handleSubmit, watch, setValue } = useForm({
    defaultValues: {
      structure: defaultPDFHead,
    },
  });

  const handleCreatePDFReport = async (data) => {
    try {
      await dispatch(actions.pdfReportCreate(data));
      history.goBack();
    } catch (error) {
      alert(`ไม่สามารถสร้างรายงานได้ ${error.message}`);
    }
  };

  const renderTitle = () => <ViewTitle title={title} subtitle={subtitle} />;

  return (
    <div>
      {renderTitle()}
      <div className="flex justify-between items-center">
        <BackButton />
        <Button
          variant="contained"
          color="success"
          onClick={handleSubmit(handleCreatePDFReport)}
        >
          บันทึก
        </Button>
      </div>
      <div className="my-2">
        <Card>
          <CardContent>
            <form>
              <PDFReportForm
                control={control}
                place={place}
                setValue={setValue}
                warehouse={warehouse}
                watch={watch}
              />
            </form>
          </CardContent>
        </Card>
      </div>
    </div>
  );
}

CreatePDFReport.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
};

CreatePDFReport.defaultProps = {
  title: '',
  subtitle: '',
};
