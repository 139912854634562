// common type
export const ME_GET = 'ME_GET';
export const ME_RESET = 'ME_RESET';

export const USER_ALL = 'USER_ALL';
export const USER_GET = 'USER_GET';
export const USER_POST = 'USER_POST';
export const USER_PUT = 'USER_PUT';
export const USER_DEL = 'USER_DEL';
export const USER_CREATE = 'USER_CREATE';
export const USER_LOADING = 'USER_LOADING';
export const USER_ERROR = 'USER_ERROR';

export const INFORMATION_ALL = 'INFORMATION_ALL';
export const INFORMATION_PUT = 'INFORMATION_PUT';
export const INFORMATION_LOADING = 'INFORMATION_LOADING';
export const INFORMATION_ERROR = 'INFORMATION_ERROR';

export const AVAILABLE_MODULE_ALL = 'AVAILABLE_MODULE_ALL';
export const AVAILABLE_MODULE_PUT = 'AVAILABLE_MODULE_PUT';
export const AVAILABLE_MODULE_LOADING = 'AVAILABLE_MODULE_LOADING';
export const AVAILABLE_MODULE_ERROR = 'AVAILABLE_MODULE_ERROR';

// feature type

export const CUSTOMER_ALL = 'CUSTOMER_ALL';
export const CUSTOMER_GET = 'CUSTOMER_GET';
export const CUSTOMER_POST = 'CUSTOMER_POST';
export const CUSTOMER_PUT = 'CUSTOMER_PUT';
export const CUSTOMER_DEL = 'CUSTOMER_DEL';
export const CUSTOMER_RESET = 'CUSTOMER_RESET';
export const CUSTOMER_LOADING = 'CUSTOMER_LOADING';
export const CUSTOMER_ERROR = 'CUSTOMER_ERROR';

export const CUSTOMER_TYPE_ALL = 'CUSTOMER_TYPE_ALL';
export const CUSTOMER_TYPE_GET = 'CUSTOMER_TYPE_GET';
export const CUSTOMER_TYPE_POST = 'CUSTOMER_TYPE_POST';
export const CUSTOMER_TYPE_PUT = 'CUSTOMER_TYPE_PUT';
export const CUSTOMER_TYPE_DEL = 'CUSTOMER_TYPE_DEL';
export const CUSTOMER_TYPE_LOADING = 'CUSTOMER_TYPE_LOADING';
export const CUSTOMER_TYPE_ERROR = 'CUSTOMER_TYPE_ERROR';

export const DEPARTMENT_ALL = 'DEPARTMENT_ALL';
export const DEPARTMENT_GET = 'DEPARTMENT_GET';
export const DEPARTMENT_POST = 'DEPARTMENT_POST';
export const DEPARTMENT_PUT = 'DEPARTMENT_PUT';
export const DEPARTMENT_DEL = 'DEPARTMENT_DEL';
export const DEPARTMENT_LOADING = 'DEPARTMENT_LOADING';
export const DEPARTMENT_ERROR = 'DEPARTMENT_ERROR';

export const ROLETYPES_ALL = 'ROLETYPES_ALL';
export const ROLETYPES_GET = 'ROLETYPES_GET';
export const ROLETYPES_POST = 'ROLETYPES_POST';
export const ROLETYPES_PUT = 'ROLETYPES_PUT';
export const ROLETYPES_DEL = 'ROLETYPES_DEL';
export const ROLETYPES_LOADING = 'ROLETYPES_LOADING';
export const ROLETYPES_ERROR = 'ROLETYPES_ERROR';

export const EMPLOYEE_ALL = 'EMPLOYEE_ALL';
export const EMPLOYEE_GET = 'EMPLOYEE_GET';
export const EMPLOYEE_POST = 'EMPLOYEE_POST';
export const EMPLOYEE_PUT = 'EMPLOYEE_PUT';
export const EMPLOYEE_DEL = 'EMPLOYEE_DEL';
export const EMPLOYEE_RESET = 'EMPLOYEE_RESET';
export const EMPLOYEE_LOADING = 'EMPLOYEE_LOADING';
export const EMPLOYEE_ERROR = 'EMPLOYEE_ERROR';

export const MATERIALTYPES_ALL = 'MATERIALTYPES_ALL';
export const MATERIALTYPES_GET = 'MATERIALTYPES_GET';
export const MATERIALTYPES_POST = 'MATERIALTYPES_POST';
export const MATERIALTYPES_PUT = 'MATERIALTYPES_PUT';
export const MATERIALTYPES_DEL = 'MATERIALTYPES_DEL';
export const MATERIALTYPES_LOADING = 'MATERIALTYPES_LOADING';
export const MATERIALTYPES_ERROR = 'MATERIALTYPES_ERROR';

export const MATERIAL_ALL = 'MATERIAL_ALL';
export const MATERIAL_GET = 'MATERIAL_GET';
export const MATERIAL_POST = 'MATERIAL_POST';
export const MATERIAL_PUT = 'MATERIAL_PUT';
export const MATERIAL_DEL = 'MATERIAL_DEL';
export const MATERIAL_LOADING = 'MATERIAL_LOADING';
export const MATERIAL_ERROR = 'MATERIAL_ERROR';

export const MATERIAL_TRANSACTION_TYPES_ALL = 'MATERIAL_TRANSACTION_TYPES_ALL';
export const MATERIAL_TRANSACTION_TYPES_GET = 'MATERIAL_TRANSACTION_TYPES_GET';
export const MATERIAL_TRANSACTION_TYPES_POST =
  'MATERIAL_TRANSACTION_TYPES_POST';
export const MATERIAL_TRANSACTION_TYPES_PUT = 'MATERIAL_TRANSACTION_TYPES_PUT';
export const MATERIAL_TRANSACTION_TYPES_DEL = 'MATERIAL_TRANSACTION_TYPES_DEL';
export const MATERIAL_TRANSACTION_TYPES_LOADING =
  'MATERIAL_TRANSACTION_TYPES_LOADING';
export const MATERIAL_TRANSACTION_TYPES_ERROR =
  'MATERIAL_TRANSACTION_TYPES_ERROR';

export const MATERIAL_REQUEST_ALL = 'MATERIAL_REQUEST_ALL';
export const MATERIAL_REQUEST_GET = 'MATERIAL_REQUEST_GET';
export const MATERIAL_REQUEST_POST = 'MATERIAL_REQUEST_POST';
export const MATERIAL_REQUEST_PUT = 'MATERIAL_REQUEST_PUT';
export const MATERIAL_REQUEST_DEL = 'MATERIAL_REQUEST_DEL';
export const MATERIAL_REQUEST_LOADING = 'MATERIAL_REQUEST_LOADING';
export const MATERIAL_REQUEST_ERROR = 'MATERIAL_REQUEST_ERROR';

export const MATERIAL_WITHDRAW_ALL = 'MATERIAL_WITHDRAW_ALL';
export const MATERIAL_WITHDRAW_GET = 'MATERIAL_WITHDRAW_GET';
export const MATERIAL_WITHDRAW_POST = 'MATERIAL_WITHDRAW_POST';
export const MATERIAL_WITHDRAW_PUT = 'MATERIAL_WITHDRAW_PUT';
export const MATERIAL_WITHDRAW_DEL = 'MATERIAL_WITHDRAW_DEL';
export const MATERIAL_WITHDRAW_LOADING = 'MATERIAL_WITHDRAW_LOADING';
export const MATERIAL_WITHDRAW_ERROR = 'MATERIAL_WITHDRAW_ERROR';

export const PURCHASE_ORDER_ALL = 'PURCHASE_ORDER_ALL';
export const PURCHASE_ORDER_GET = 'PURCHASE_ORDER_GET';
export const PURCHASE_ORDER_POST = 'PURCHASE_ORDER_POST';
export const PURCHASE_ORDER_PUT = 'PURCHASE_ORDER_PUT';
export const PURCHASE_ORDER_DEL = 'PURCHASE_ORDER_DEL';
export const PURCHASE_ORDER_LOADING = 'PURCHASE_ORDER_LOADING';
export const PURCHASE_ORDER_ERROR = 'PURCHASE_ORDER_ERROR';

export const PLACE_ALL = 'PLACE_ALL';
export const PLACE_GET = 'PLACE_GET';
export const PLACE_POST = 'PLACE_POST';
export const PLACE_PUT = 'PLACE_PUT';
export const PLACE_DEL = 'PLACE_DEL';
export const PLACE_LOADING = 'PLACE_LOADING';
export const PLACE_ERROR = 'PLACE_ERROR';

export const MATERIAL_TRANSACTION_ALL = 'MATERIAL_TRANSACTION_ALL';
export const MATERIAL_TRANSACTION_GET = 'MATERIAL_TRANSACTION_GET';
export const MATERIAL_TRANSACTION_POST = 'MATERIAL_TRANSACTION_POST';
export const MATERIAL_TRANSACTION_PUT = 'MATERIAL_TRANSACTION_PUT';
export const MATERIAL_TRANSACTION_DEL = 'MATERIAL_TRANSACTION_DEL';
export const MATERIAL_TRANSACTION_SEARCH = 'MATERIAL_TRANSACTION_SEARCH';
export const MATERIAL_TRANSACTION_LOADING = 'MATERIAL_TRANSACTION_LOADING';
export const MATERIAL_TRANSACTION_ERROR = 'MATERIAL_TRANSACTION_ERROR';

export const MATERIAL_STOCK_LOT_ALL = 'MATERIAL_STOCK_LOT_ALL';
export const MATERIAL_STOCK_LOT_GET = 'MATERIAL_STOCK_LOT_GET';
export const MATERIAL_STOCK_LOT_POST = 'MATERIAL_STOCK_LOT_POST';
export const MATERIAL_STOCK_LOT_PUT = 'MATERIAL_STOCK_LOT_PUT';
export const MATERIAL_STOCK_LOT_DEL = 'MATERIAL_STOCK_LOT_DEL';
export const MATERIAL_STOCK_LOT_LOADING = 'MATERIAL_STOCK_LOT_LOADING';
export const MATERIAL_STOCK_LOT_ERROR = 'MATERIAL_STOCK_LOT_ERROR';
export const MATERIAL_STOCK_LOT_DECREASE = 'MATERIAL_STOCK_LOT_DECREASE';
export const MATERIAL_STOCK_LOT_INCREASE = 'MATERIAL_STOCK_LOT_INCREASE';

export const ORDER_ALL = 'ORDER_ALL';
export const ORDER_GET = 'ORDER_GET';
export const ORDER_POST = 'ORDER_POST';
export const ORDER_PUT = 'ORDER_PUT';
export const ORDER_DEL = 'ORDER_DEL';
export const ORDER_LOADING = 'ORDER_LOADING';
export const ORDER_ERROR = 'ORDER_ERROR';

export const MANUFACTURING_ORDER_ALL = 'MANUFACTURING_ORDER_ALL';
export const MANUFACTURING_ORDER_GET = 'MANUFACTURING_ORDER_GET';
export const MANUFACTURING_ORDER_POST = 'MANUFACTURING_ORDER_POST';
export const MANUFACTURING_ORDER_PUT = 'MANUFACTURING_ORDER_PUT';
export const MANUFACTURING_ORDER_DEL = 'MANUFACTURING_ORDER_DEL';
export const MANUFACTURING_ORDER_LOADING = 'MANUFACTURING_ORDER_LOADING';
export const MANUFACTURING_ORDER_ERROR = 'MANUFACTURING_ORDER_ERROR';

export const PROCESS_TEMPLATE_ALL = 'PROCESS_TEMPLATE_ALL';
export const PROCESS_TEMPLATE_GET = 'PROCESS_TEMPLATE_GET';
export const PROCESS_TEMPLATE_POST = 'PROCESS_TEMPLATE_POST';
export const PROCESS_TEMPLATE_PUT = 'PROCESS_TEMPLATE_PUT';
export const PROCESS_TEMPLATE_DEL = 'PROCESS_TEMPLATE_DEL';
export const PROCESS_TEMPLATE_LOADING = 'PROCESS_TEMPLATE_LOADING';
export const PROCESS_TEMPLATE_ERROR = 'PROCESS_TEMPLATE_ERROR';

export const PROCESS_OF_ORDER_ALL = 'PROCESS_OF_ORDER_ALL';
export const PROCESS_OF_ORDER_GET = 'PROCESS_OF_ORDER_GET';
export const PROCESS_OF_ORDER_POST = 'PROCESS_OF_ORDER_POST';
export const PROCESS_OF_ORDER_PUT = 'PROCESS_OF_ORDER_PUT';
export const PROCESS_OF_ORDER_DEL = 'PROCESS_OF_ORDER_DEL';
export const PROCESS_OF_ORDER_LOADING = 'PROCESS_OF_ORDER_LOADING';
export const PROCESS_OF_ORDER_ERROR = 'PROCESS_OF_ORDER_ERROR';

export const WAREHOUSE_ALL = 'WAREHOUSE_ALL';
export const WAREHOUSE_GET = 'WAREHOUSE_GET';
export const WAREHOUSE_POST = 'WAREHOUSE_POST';
export const WAREHOUSE_PUT = 'WAREHOUSE_PUT';
export const WAREHOUSE_DEL = 'WAREHOUSE_DEL';
export const WAREHOUSE_LOADING = 'WAREHOUSE_LOADING';
export const WAREHOUSE_ERROR = 'WAREHOUSE_ERROR';

export const PRODUCTTYPES_ALL = 'PRODUCTTYPES_ALL';
export const PRODUCTTYPES_GET = 'PRODUCTTYPES_GET';
export const PRODUCTTYPES_POST = 'PRODUCTTYPES_POST';
export const PRODUCTTYPES_PUT = 'PRODUCTTYPES_PUT';
export const PRODUCTTYPES_DEL = 'PRODUCTTYPES_DEL';
export const PRODUCTTYPES_LOADING = 'PRODUCTTYPES_LOADING';
export const PRODUCTTYPES_ERROR = 'MATERIALTYPES_ERROR';

export const PRODUCT_ALL = 'PRODUCT_ALL';
export const PRODUCT_GET = 'PRODUCT_GET';
export const PRODUCT_GET_BY_TYPE = 'PRODUCT_GET_BY_TYPE';
export const PRODUCT_POST = 'PRODUCT_POST';
export const PRODUCT_PUT = 'PRODUCT_PUT';
export const PRODUCT_DEL = 'PRODUCT_DEL';
export const PRODUCT_LOADING = 'PRODUCT_LOADING';
export const PRODUCT_ERROR = 'PRODUCT_ERROR';

export const PRODUCT_TRANSACTION_TYPES_ALL = 'PRODUCT_TRANSACTION_TYPES_ALL';
export const PRODUCT_TRANSACTION_TYPES_GET = 'PRODUCT_TRANSACTION_TYPES_GET';
export const PRODUCT_TRANSACTION_TYPES_POST = 'PRODUCT_TRANSACTION_TYPES_POST';
export const PRODUCT_TRANSACTION_TYPES_PUT = 'PRODUCT_TRANSACTION_TYPES_PUT';
export const PRODUCT_TRANSACTION_TYPES_DEL = 'PRODUCT_TRANSACTION_TYPES_DEL';
export const PRODUCT_TRANSACTION_TYPES_LOADING =
  'PRODUCT_TRANSACTION_TYPES_LOADING';
export const PRODUCT_TRANSACTION_TYPES_ERROR =
  'PRODUCT_TRANSACTION_TYPES_ERROR';

export const PRODUCT_TRANSACTION_ALL = 'PRODUCT_TRANSACTION_ALL';
export const PRODUCT_TRANSACTION_GET = 'PRODUCT_TRANSACTION_GET';
export const PRODUCT_TRANSACTION_POST = 'PRODUCT_TRANSACTION_POST';
export const PRODUCT_TRANSACTION_PUT = 'PRODUCT_TRANSACTION_PUT';
export const PRODUCT_TRANSACTION_DEL = 'PRODUCT_TRANSACTION_DEL';
export const PRODUCT_TRANSACTION_SEARCH = 'PRODUCT_TRANSACTION_SEARCH';
export const PRODUCT_TRANSACTION_LOADING = 'PRODUCT_TRANSACTION_LOADING';
export const PRODUCT_TRANSACTION_ERROR = 'PRODUCT_TRANSACTION_ERROR';

export const PRODUCT_STOCK_LOT_ALL = 'PRODUCT_STOCK_LOT_ALL';
export const PRODUCT_STOCK_LOT_GET = 'PRODUCT_STOCK_LOT_GET';
export const PRODUCT_STOCK_LOT_POST = 'PRODUCT_STOCK_LOT_POST';
export const PRODUCT_STOCK_LOT_PUT = 'PRODUCT_STOCK_LOT_PUT';
export const PRODUCT_STOCK_LOT_DEL = 'PRODUCT_STOCK_LOT_DEL';
export const PRODUCT_STOCK_LOT_LOADING = 'PRODUCT_STOCK_LOT_LOADING';
export const PRODUCT_STOCK_LOT_ERROR = 'PRODUCT_STOCK_LOT_ERROR';
export const PRODUCT_STOCK_LOT_DECREASE = 'PRODUCT_STOCK_LOT_DECREASE';

export const SHIPPING_TYPE_ALL = 'SHIPPING_TYPE_ALL';
export const SHIPPING_TYPE_GET = 'SHIPPING_TYPE_GET';
export const SHIPPING_TYPE_POST = 'SHIPPING_TYPE_POST';
export const SHIPPING_TYPE_PUT = 'SHIPPING_TYPE_PUT';
export const SHIPPING_TYPE_DEL = 'SHIPPING_TYPE_DEL';
export const SHIPPING_TYPE_LOADING = 'SHIPPING_TYPE_LOADING';
export const SHIPPING_TYPE_ERROR = 'SHIPPING_TYPE_ERROR';

export const PAYMENT_TYPE_ALL = 'PAYMENT_TYPE_ALL';
export const PAYMENT_TYPE_GET = 'PAYMENT_TYPE_GET';
export const PAYMENT_TYPE_POST = 'PAYMENT_TYPE_POST';
export const PAYMENT_TYPE_PUT = 'PAYMENT_TYPE_PUT';
export const PAYMENT_TYPE_DEL = 'PAYMENT_TYPE_DEL';
export const PAYMENT_TYPE_LOADING = 'PAYMENT_TYPE_LOADING';
export const PAYMENT_TYPE_ERROR = 'PAYMENT_TYPE_ERROR';

export const STEP_ALL = 'STEP_ALL';
export const STEP_GET = 'STEP_GET';
export const STEP_POST = 'STEP_POST';
export const STEP_PUT = 'STEP_PUT';
export const STEP_DELETE = 'STEP_DELETE';
export const STEP_LOADING = 'STEP_LOADING';
export const STEP_ERROR = 'STEP_ERROR';

export const COMMENT_ALL = 'COMMENT_ALL';
export const COMMENT_GET = 'COMMENT_GET';
export const COMMENT_POST = 'COMMENT_POST';
export const COMMENT_PUT = 'COMMENT_PUT';
export const COMMENT_DELETE = 'COMMENT_DELETE';
export const COMMENT_LOADING = 'COMMENT_LOADING';
export const COMMENT_ERROR = 'COMMENT_ERROR';
export const COMMENT_RESET = 'COMMENT_RESET';

export const NOTIFY_REQ_TOKEN = 'NOTIFY_REQ_TOKEN';
export const NOTIFY_DIRECT = 'NOTIFY_DIRECT';
export const NOTIFY_DEPARTMENT = 'NOTIFY_DEPARTMENT';
export const NOTIFY_LOADING = 'NOTIFY_LOADING';
export const NOTIFY_ERROR = 'NOTIFY_ERROR';

export const MANUFACTURING_TRANSACTION_ALL = 'MANUFACTURING_TRANSACTION_ALL';
export const MANUFACTURING_TRANSACTION_GET = 'MANUFACTURING_TRANSACTION_GET';
export const MANUFACTURING_TRANSACTION_POST = 'MANUFACTURING_TRANSACTION_POST';
export const MANUFACTURING_TRANSACTION_PUT = 'MANUFACTURING_TRANSACTION_PUT';
export const MANUFACTURING_TRANSACTION_DELETE =
  'MANUFACTURING_TRANSACTION_DELETE';
export const MANUFACTURING_TRANSACTION_LOADING =
  'MANUFACTURING_TRANSACTION_LOADING';
export const MANUFACTURING_TRANSACTION_ERROR =
  'MANUFACTURING_TRANSACTION_ERROR';

export const MANUFAC_MATERIAL_ALL = 'MANUFAC_MATERIAL_ALL';
export const MANUFAC_MATERIAL_GET = 'MANUFAC_MATERIAL_GET';
export const MANUFAC_MATERIAL_POST = 'MANUFAC_MATERIAL_POST';
export const MANUFAC_MATERIAL_PUT = 'MANUFAC_MATERIAL_PUT';
export const MANUFAC_MATERIAL_DELETE = 'MANUFAC_MATERIAL_DELETE';
export const MANUFAC_MATERIAL_LOADING = 'MANUFAC_MATERIAL_LOADING';
export const MANUFAC_MATERIAL_ERROR = 'MANUFAC_MATERIAL_ERROR';

export const MEASUREMENT_ALL = 'MEASUREMENT_ALL';
export const MEASUREMENT_GET = 'MEASUREMENT_GET';
export const MEASUREMENT_POST = 'MEASUREMENT_POST';
export const MEASUREMENT_PUT = 'MEASUREMENT_PUT';
export const MEASUREMENT_DELETE = 'MEASUREMENT_DELETE';
export const MEASUREMENT_LOADING = 'MEASUREMENT_LOADING';
export const MEASUREMENT_ERROR = 'MEASUREMENT_ERROR';

export const MEASUREMENT_TYPE_ALL = 'MEASUREMENT_TYPE_ALL';
export const MEASUREMENT_TYPE_GET = 'MEASUREMENT_TYPE_GET';
export const MEASUREMENT_TYPE_POST = 'MEASUREMENT_TYPE_POST';
export const MEASUREMENT_TYPE_PUT = 'MEASUREMENT_TYPE_PUT';
export const MEASUREMENT_TYPE_DELETE = 'MEASUREMENT_TYPE_DELETE';
export const MEASUREMENT_TYPE_LOADING = 'MEASUREMENT_TYPE_LOADING';
export const MEASUREMENT_TYPE_ERROR = 'MEASUREMENT_TYPE_ERROR';

export const UNIT_ALL = 'UNIT_ALL';
export const UNIT_GET = 'UNIT_GET';
export const UNIT_POST = 'UNIT_POST';
export const UNIT_PUT = 'UNIT_PUT';
export const UNIT_DELETE = 'UNIT_DELETE';
export const UNIT_LOADING = 'UNIT_LOADING';
export const UNIT_ERROR = 'UNIT_ERROR';

export const CONVERSION_ALL = 'CONVERSION_ALL';
export const CONVERSION_GET = 'CONVERSION_GET';
export const CONVERSION_POST = 'CONVERSION_POST';
export const CONVERSION_PUT = 'CONVERSION_PUT';
export const CONVERSION_DELETE = 'CONVERSION_DELETE';
export const CONVERSION_LOADING = 'CONVERSION_LOADING';
export const CONVERSION_ERROR = 'CONVERSION_ERROR';

export const ORG_CONSTANT_ALL = 'ORG_CONSTANT_ALL';
export const ORG_CONSTANT_GET = 'ORG_CONSTANT_GET';
export const ORG_CONSTANT_POST = 'ORG_CONSTANT_POST';
export const ORG_CONSTANT_PUT = 'ORG_CONSTANT_PUT';
export const ORG_CONSTANT_DELETE = 'ORG_CONSTANT_DELETE';
export const ORG_CONSTANT_LOADING = 'ORG_CONSTANT_LOADING';
export const ORG_CONSTANT_ERROR = 'ORG_CONSTANT_ERROR';

export const RESULTING_FN_TYPE_ALL = 'RESULTING_FN_TYPE_ALL';
export const RESULTING_FN_TYPE_GET = 'RESULTING_FN_TYPE_GET';
export const RESULTING_FN_TYPE_POST = 'RESULTING_FN_TYPE_POST';
export const RESULTING_FN_TYPE_PUT = 'RESULTING_FN_TYPE_PUT';
export const RESULTING_FN_TYPE_DELETE = 'RESULTING_FN_TYPE_DELETE';
export const RESULTING_FN_TYPE_LOADING = 'RESULTING_FN_TYPE_LOADING';
export const RESULTING_FN_TYPE_ERROR = 'RESULTING_FN_TYPE_ERROR';

export const PAYROLL_ALL = 'PAYROLL_ALL';
export const PAYROLL_GET = 'PAYROLL_GET';
export const PAYROLL_POST = 'PAYROLL_POST';
export const PAYROLL_PUT = 'PAYROLL_PUT';
export const PAYROLL_DELETE = 'PAYROLL_DELETE';
export const PAYROLL_LOADING = 'PAYROLL_LOADING';
export const PAYROLL_ERROR = 'PAYROLL_ERROR';

export const MACHINE_ALL = 'MACHINE_ALL';
export const MACHINE_GET = 'MACHINE_GET';
export const MACHINE_POST = 'MACHINE_POST';
export const MACHINE_PUT = 'MACHINE_PUT';
export const MACHINE_DELETE = 'MACHINE_DELETE';
export const MACHINE_LOADING = 'MACHINE_LOADING';
export const MACHINE_ERROR = 'MACHINE_ERROR';

export const MO_TEMPLATE_ALL = 'MO_TEMPLATE_ALL';
export const MO_TEMPLATE_GET = 'MO_TEMPLATE_GET';
export const MO_TEMPLATE_POST = 'MO_TEMPLATE_POST';
export const MO_TEMPLATE_PUT = 'MO_TEMPLATE_PUT';
export const MO_TEMPLATE_DELETE = 'MO_TEMPLATE_DELETE';
export const MO_TEMPLATE_LOADING = 'MO_TEMPLATE_LOADING';
export const MO_TEMPLATE_ERROR = 'MO_TEMPLATE_ERROR';

export const BOM_TEMPLATE_ALL = 'BOM_TEMPLATE_ALL';
export const BOM_TEMPLATE_GET = 'BOM_TEMPLATE_GET';
export const BOM_TEMPLATE_POST = 'BOM_TEMPLATE_POST';
export const BOM_TEMPLATE_PUT = 'BOM_TEMPLATE_PUT';
export const BOM_TEMPLATE_DELETE = 'BOM_TEMPLATE_DELETE';
export const BOM_TEMPLATE_LOADING = 'BOM_TEMPLATE_LOADING';
export const BOM_TEMPLATE_ERROR = 'BOM_TEMPLATE_ERROR';

export const BOM_CHECKING_ALL = 'BOM_CHECKING_ALL';
export const BOM_CHECKING_GET = 'BOM_CHECKING_GET';
export const BOM_CHECKING_POST = 'BOM_CHECKING_POST';
export const BOM_CHECKING_PUT = 'BOM_CHECKING_PUT';
export const BOM_CHECKING_DELETE = 'BOM_CHECKING_DELETE';
export const BOM_CHECKING_LOADING = 'BOM_CHECKING_LOADING';
export const BOM_CHECKING_ERROR = 'BOM_CHECKING_ERROR';

export const WAREHOUSE_STOCK_REQUEST_ALL = 'WAREHOUSE_STOCK_REQUEST_ALL';
export const WAREHOUSE_STOCK_REQUEST_GET = 'WAREHOUSE_STOCK_REQUEST_GET';
export const WAREHOUSE_STOCK_REQUEST_POST = 'WAREHOUSE_STOCK_REQUEST_POST';
export const WAREHOUSE_STOCK_REQUEST_PUT = 'WAREHOUSE_STOCK_REQUEST_PUT';
export const WAREHOUSE_STOCK_REQUEST_DELETE = 'WAREHOUSE_STOCK_REQUEST_DELETE';
export const WAREHOUSE_STOCK_REQUEST_LOADING =
  'WAREHOUSE_STOCK_REQUEST_LOADING';
export const WAREHOUSE_STOCK_REQUEST_ERROR = 'WAREHOUSE_STOCK_REQUEST_ERROR';

export const BASE_PROJECT_ALL = 'BASE_PROJECT_ALL';
export const BASE_PROJECT_GET = 'BASE_PROJECT_GET';
export const BASE_PROJECT_POST = 'BASE_PROJECT_POST';
export const BASE_PROJECT_PUT = 'BASE_PROJECT_PUT';
export const BASE_PROJECT_DELETE = 'BASE_PROJECT_DELETE';
export const BASE_PROJECT_LOADING = 'BASE_PROJECT_LOADING';
export const BASE_PROJECT_ERROR = 'BASE_PROJECT_ERROR';

export const DASHBOARD_ELEMENT_ALL = 'DASHBOARD_ELEMENT_ALL';
export const DASHBOARD_ELEMENT_GET = 'DASHBOARD_ELEMENT_GET';
export const DASHBOARD_ELEMENT_POST = 'DASHBOARD_ELEMENT_POST';
export const DASHBOARD_ELEMENT_PUT = 'DASHBOARD_ELEMENT_PUT';
export const DASHBOARD_ELEMENT_DELETE = 'DASHBOARD_ELEMENT_DELETE';
export const DASHBOARD_ELEMENT_LOADING = 'DASHBOARD_ELEMENT_LOADING';
export const DASHBOARD_ELEMENT_ERROR = 'DASHBOARD_ELEMENT_ERROR';

export const MMS_DASHBOARD_ALL = 'MMS_DASHBOARD_ALL';
export const MMS_DASHBOARD_GET = 'MMS_DASHBOARD_GET';
export const MMS_DASHBOARD_POST = 'MMS_DASHBOARD_POST';
export const MMS_DASHBOARD_PUT = 'MMS_DASHBOARD_PUT';
export const MMS_DASHBOARD_DELETE = 'MMS_DASHBOARD_DELETE';
export const MMS_DASHBOARD_LOADING = 'MMS_DASHBOARD_LOADING';
export const MMS_DASHBOARD_ERROR = 'MMS_DASHBOARD_ERROR';

export const WITHDRAW_DESTINATION_ALL = 'WITHDRAW_DESTINATION_ALL';
export const WITHDRAW_DESTINATION_GET = 'WITHDRAW_DESTINATION_GET';
export const WITHDRAW_DESTINATION_POST = 'WITHDRAW_DESTINATION_POST';
export const WITHDRAW_DESTINATION_PUT = 'WITHDRAW_DESTINATION_PUT';
export const WITHDRAW_DESTINATION_DELETE = 'WITHDRAW_DESTINATION_DELETE';
export const WITHDRAW_DESTINATION_LOADING = 'WITHDRAW_DESTINATION_LOADING';
export const WITHDRAW_DESTINATION_ERROR = 'WITHDRAW_DESTINATION_ERROR';

export const SUMMARY_DASHBOARD_ALL = 'SUMMARY_DASHBOARD_ALL';
export const SUMMARY_DASHBOARD_GET = 'SUMMARY_DASHBOARD_GET';
export const SUMMARY_DASHBOARD_POST = 'SUMMARY_DASHBOARD_POST';
export const SUMMARY_DASHBOARD_PUT = 'SUMMARY_DASHBOARD_PUT';
export const SUMMARY_DASHBOARD_DELETE = 'SUMMARY_DASHBOARD_DELETE';
export const SUMMARY_DASHBOARD_LOADING = 'SUMMARY_DASHBOARD_LOADING';
export const SUMMARY_DASHBOARD_ERROR = 'SUMMARY_DASHBOARD_ERROR';

export const MAT_SPLITTING_ALL = 'MAT_SPLITTING_ALL';
export const MAT_SPLITTING_GET = 'MAT_SPLITTING_GET';
export const MAT_SPLITTING_POST = 'MAT_SPLITTING_POST';
export const MAT_SPLITTING_PUT = 'MAT_SPLITTING_PUT';
export const MAT_SPLITTING_DELETE = 'MAT_SPLITTING_DELETE';
export const MAT_SPLITTING_LOADING = 'MAT_SPLITTING_LOADING';
export const MAT_SPLITTING_ERROR = 'MAT_SPLITTING_ERROR';

export const PDF_REPORT_ALL = 'PDF_REPORT_ALL';
export const PDF_REPORT_GET = 'PDF_REPORT_GET';
export const PDF_REPORT_POST = 'PDF_REPORT_POST';
export const PDF_REPORT_PUT = 'PDF_REPORT_PUT';
export const PDF_REPORT_DELETE = 'PDF_REPORT_DELETE';
export const PDF_REPORT_LOADING = 'PDF_REPORT_LOADING';
export const PDF_REPORT_ERROR = 'PDF_REPORT_ERROR';

export const MANUFACTURING_ORDER_LOG_ALL = 'MANUFACTURING_ORDER_LOG_ALL';
export const MANUFACTURING_ORDER_LOG_GET = 'MANUFACTURING_ORDER_LOG_GET';
export const MANUFACTURING_ORDER_LOG_DELETE = 'MANUFACTURING_ORDER_LOG_DELETE';
export const MANUFACTURING_ORDER_LOG_LOADING =
  'MANUFACTURING_ORDER_LOG_LOADING';
export const MANUFACTURING_ORDER_LOG_ERROR = 'MANUFACTURING_ORDER_LOG_ERROR';

export const PROCESS_LOG_ALL = 'PROCESS_LOG_ALL';
export const PROCESS_LOG_GET = 'PROCESS_LOG_GET';
export const PROCESS_LOG_DELETE = 'PROCESS_LOG_DELETE';
export const PROCESS_LOG_LOADING = 'PROCESS_LOG_LOADING';
export const PROCESS_LOG_ERROR = 'PROCESS_LOG_ERROR';

export const STEP_LOG_ALL = 'STEP_LOG_ALL';
export const STEP_LOG_GET = 'STEP_LOG_GET';
export const STEP_LOG_DELETE = 'STEP_LOG_DELETE';
export const STEP_LOG_LOADING = 'STEP_LOG_LOADING';
export const STEP_LOG_ERROR = 'STEP_LOG_ERROR';

export const FEEDBACK_ALL = 'FEEDBACK_ALL';
export const FEEDBACK_GET = 'FEEDBACK_GET';
export const FEEDBACK_DELETE = 'FEEDBACK_DELETE';
export const FEEDBACK_LOADING = 'FEEDBACK_LOADING';
export const FEEDBACK_ERROR = 'FEEDBACK_ERROR';
export const FEEDBACK_PUT = 'FEEDBACK_PUT';
export const FEEDBACK_POST = 'FEEDBACK_POST';

export const PAYROLL_ADJ_ALL = 'PAYROLL_ADJ_ALL';
export const PAYROLL_ADJ_GET = 'PAYROLL_ADJ_GET';
export const PAYROLL_ADJ_DELETE = 'PAYROLL_ADJ_DELETE';
export const PAYROLL_ADJ_LOADING = 'PAYROLL_ADJ_LOADING';
export const PAYROLL_ADJ_ERROR = 'PAYROLL_ADJ_ERROR';
export const PAYROLL_ADJ_PUT = 'PAYROLL_ADJ_PUT';
export const PAYROLL_ADJ_POST = 'PAYROLL_ADJ_POST';

export const MATERIAL_SOURCE_ALL = 'MATERIAL_SOURCE_ALL';
export const MATERIAL_SOURCE_GET = 'MATERIAL_SOURCE_GET';
export const MATERIAL_SOURCE_DELETE = 'MATERIAL_SOURCE_DELETE';
export const MATERIAL_SOURCE_LOADING = 'MATERIAL_SOURCE_LOADING';
export const MATERIAL_SOURCE_ERROR = 'MATERIAL_SOURCE_ERROR';
export const MATERIAL_SOURCE_PUT = 'MATERIAL_SOURCE_PUT';
export const MATERIAL_SOURCE_POST = 'MATERIAL_SOURCE_POST';
