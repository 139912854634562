import React from 'react';
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import _ from 'lodash';
import PropTypes from 'prop-types';

import { MATERIAL_GROUP_OPTION } from '@/utils/constants';

import MaterialAmountAddComponent from './MaterialAmountAddComponent';

function MaterialAmountAddList({
  control,
  watch,
  setValue,
  enableAddPreferProduct,
  enableCustomer,
  customer,
  product,
  place,
  rerender,
  setRerender,
  materialRows,
}) {
  let tableCellWidth = 3;
  if (enableAddPreferProduct) {
    tableCellWidth += 1;
  }
  if (enableCustomer) {
    tableCellWidth += 1;
  }
  const normalList = () => {
    const orderFieldList = _.sortBy(materialRows, 'name');
    return (
      <TableBody>
        {_.map(orderFieldList, (eachField, index) => (
          <MaterialAmountAddComponent
            control={control}
            customer={customer}
            material={eachField}
            enableAddPreferProduct={enableAddPreferProduct}
            enableCustomer={enableCustomer}
            index={index}
            product={product}
            rerender={rerender}
            setRerender={setRerender}
            setValue={setValue}
            watch={watch}
            key={eachField.id}
            place={place}
          />
        ))}
      </TableBody>
    );
  };

  const groupByTagList = () => {
    const materialByTag = _.groupBy(
      materialRows,
      `additional.${place?.place_action?.for_stranger?.group_transfrom_result?.tag_to_group}.value`,
    );
    const tagKeys = _.keys(materialByTag);
    const orderedKey = tagKeys.sort();

    let count = -1;

    return (
      <>
        {_.map(orderedKey, (each, index) => {
          const materialList = materialByTag?.[each];
          const sortedMaterial = _.sortBy(materialList, 'name');
          return (
            <TableBody key={index}>
              <TableRow>
                <TableCell
                  colSpan={tableCellWidth}
                  className="bg-gray-100 shadow-md"
                >
                  <div className=" font-semibold">{each}</div>
                </TableCell>
              </TableRow>
              {_.map(sortedMaterial, (eachField) => {
                count += 1;
                return (
                  <MaterialAmountAddComponent
                    control={control}
                    customer={customer}
                    material={eachField}
                    enableAddPreferProduct={enableAddPreferProduct}
                    enableCustomer={enableCustomer}
                    index={count}
                    product={product}
                    rerender={rerender}
                    setRerender={setRerender}
                    setValue={setValue}
                    watch={watch}
                    key={eachField.id}
                    place={place}
                  />
                );
              })}
            </TableBody>
          );
        })}
      </>
    );
  };

  const groupByMaterialType = () => {
    const materialByTag = _.groupBy(materialRows, 'material_type');
    const tagKeys = _.keys(materialByTag);
    const orderedKey = tagKeys.sort();
    let count = -1;

    return (
      <>
        {_.map(orderedKey, (each, index) => (
          <TableBody key={index}>
            <TableRow>
              <TableCell colSpan={tableCellWidth} className="bg-gray-200">
                <div className=" font-semibold">{each}</div>
              </TableCell>
            </TableRow>

            {_.map(materialByTag?.[each], (eachField) => {
              count += 1;
              return (
                <MaterialAmountAddComponent
                  control={control}
                  customer={customer}
                  material={eachField}
                  enableAddPreferProduct={enableAddPreferProduct}
                  enableCustomer={enableCustomer}
                  index={count}
                  product={product}
                  rerender={rerender}
                  setRerender={setRerender}
                  setValue={setValue}
                  watch={watch}
                  key={eachField.id}
                  place={place}
                />
              );
            })}
          </TableBody>
        ))}
      </>
    );
  };

  // Render Components
  const renderResult = () => {
    if (!place?.place_action?.for_stranger?.group_transfrom_result?.enable) {
      return normalList();
    }
    if (
      place?.place_action?.for_stranger?.group_transfrom_result?.group_by ===
      MATERIAL_GROUP_OPTION.MATERIAL_TYPE.status_code
    ) {
      return groupByMaterialType();
    }

    return groupByTagList();
  };

  return (
    <div>
      <TableContainer component={Paper}>
        <Table size="small">
          <TableHead>
            <TableCell>
              <div className="font-semibold">ลำดับที่</div>
            </TableCell>
            <TableCell>
              <div className="font-semibold">วัตถุดิบ</div>
            </TableCell>
            {enableAddPreferProduct && (
              <TableCell>
                <div className="font-semibold">สินค้าที่ผลิตได้</div>
              </TableCell>
            )}{' '}
            {enableCustomer && (
              <TableCell>
                <div className="font-semibold">ลูกค้า</div>
              </TableCell>
            )}
            <TableCell>
              <div className="font-semibold">ปริมาณที่ได้</div>
            </TableCell>
          </TableHead>
          {renderResult()}
        </Table>
      </TableContainer>
    </div>
  );
}

export default MaterialAmountAddList;

MaterialAmountAddList.propTypes = {
  control: PropTypes.object,
  watch: PropTypes.func,
  setValue: PropTypes.func,
  enableAddPreferProduct: PropTypes.bool,
  enableCustomer: PropTypes.bool,
  customer: PropTypes.shape({
    rows: PropTypes.arrayOf(PropTypes.object),
  }),
  product: PropTypes.shape({
    rows: PropTypes.object,
  }),
  place: PropTypes.object,
  rerender: PropTypes.bool,
  setRerender: PropTypes.func,
  materialRows: PropTypes.arrayOf(PropTypes.object),
};
