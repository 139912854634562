import React, { useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  BackButton,
  DaySpotAndRangePicker,
  Error,
  Loading,
  LoadingLinear,
  MaterialToProductPivotTable,
  ViewTitle,
} from '@iarcpsu/emanufac-components/src/components';
import { PRODUCT_QUANTITY_DIVIDE_BY } from '@iarcpsu/emanufac-constant';
import {
  Card,
  CardContent,
  Checkbox,
  Chip,
  Paper,
  Switch,
  TablePagination,
} from '@mui/material';
import dayjs from 'dayjs';
import PropTypes from 'prop-types';

import { config, useQuery } from '@/configs';
import { WordLocalizeContext } from '@/contexts/WordLocalizeContext';
import * as actions from '@/redux/actions';
import generateMaterialToProductPayload from '@/utils/functions/IMS/generateMaterialToProductPayload';

/**
 * @function MaterialStockToProduct
 * @description Display a collections or a list of EditProcessTemplateTableView from database
 */

export default function MaterialStockToProduct({ title, subtitle }) {
  const dispatch = useDispatch();
  const query = useQuery();
  const { findWordInPlace, findWord } = useContext(WordLocalizeContext);
  const materialStockLot = useSelector((state) => state.materialStockLot);
  const productStockLot = useSelector((state) => state.productStockLot);
  const place = useSelector((state) => state.place);
  const [page, setPage] = useState(1);
  const [size, setSize] = useState(100);
  const [total, setTotal] = useState();
  const [displayPivotTable, setIsDisplayPivotTable] = useState(true);
  const [usePurchaseDateSearch, setUsePurchaseDateSearch] = useState(false);
  const [startDate, setStartDate] = useState(dayjs().toDate());
  const [endDate, setEndDate] = useState(dayjs().toDate());
  const [startPurchaseDate, setStartPurchaseDate] = useState(dayjs().toDate());
  const [endPurchaseDate, setEndPurchaseDate] = useState(dayjs().toDate());
  const [showRemainAmount, setIsShowRemainAmount] = useState(false);
  const [showRunOutOff, setIsShowRunOutOff] = useState(true);
  const [isUseDivider, setIsUseDivider] = useState(false);
  const [isFilterByCustomer, setIsFilterByCustomer] = useState(true);

  const queryDataFromServer = async () => {
    try {
      if (usePurchaseDateSearch) {
        dispatch(
          actions.materialStockLotAll({
            purchaseStartDate: dayjs(startPurchaseDate).format('YYYY-MM-DD'),
            purchaseEndDate: dayjs(endPurchaseDate).format('YYYY-MM-DD'),
            place: query.get('place') || '',
            size,
            page,
            stockStatus: '',
            placeRestricNotify: true,
            fetchProcess: true,
          }),
        );
      } else {
        dispatch(
          actions.materialStockLotAll({
            startDate: dayjs(startDate).format('YYYY-MM-DD'),
            endDate: dayjs(endDate).format('YYYY-MM-DD'),
            place: query.get('place') || '',
            size,
            page,
            stockStatus: '',
            placeRestricNotify: true,
            fetchProcess: true,
          }),
        );
      }
      dispatch(
        actions.productStockLotAll({
          startDate: dayjs(startDate).format('YYYY-MM-DD'),
          endDate: dayjs(endDate).format('YYYY-MM-DD'),
          warehouse: place?.place_action?.destination_warehouse,
          size,
          page,
          stockStatus: '',
          placeRestricNotify: true,
          fetchProcess: true,
        }),
      );
    } catch (error) {
      dispatch(actions.materialError());
    }
  };

  useEffect(() => {
    queryDataFromServer();

    return () => {};
  }, [
    page,
    size,
    startDate,
    endDate,
    showRunOutOff,
    usePurchaseDateSearch,
    startPurchaseDate,
    endPurchaseDate,
    showRunOutOff,
  ]);

  useEffect(() => {
    setTotal(materialStockLot?.total);

    return () => {};
  }, [materialStockLot]);

  useEffect(() => {
    if (query.get('place') && (query.get('place') !== '') !== '') {
      dispatch(actions.placeGet(query.get('place')));
    }

    return () => {};
  }, [query.get('place')]);

  useEffect(() => {
    if (place && place?.isCompleted) {
      if (
        place?.place_action?.product_quantity_divide_by !==
        PRODUCT_QUANTITY_DIVIDE_BY.NONE.status_code
      ) {
        setIsUseDivider(true);
      }
    }

    return () => {};
  }, [place]);

  const generatedData = generateMaterialToProductPayload({
    materialStockLotRows: materialStockLot?.rows,
    productStockLotRows: productStockLot?.rows,
    place,
    showCustomer: isFilterByCustomer,
  });

  const renderTitle = () => <ViewTitle title={title} subtitle={subtitle} />;

  if (materialStockLot.isLoading) {
    return <Loading />;
  }

  if (!materialStockLot.isLoading && materialStockLot.isCompleted) {
    return (
      <div>
        {renderTitle()}
        <BackButton />

        <div className="my-2">
          <Paper>
            <div className="flex flex-wrap py-4 px-2">
              <div className="w-full lg:w-1/3">
                <div className="flex gap-2 p-2 border rounded-md items-center">
                  <div>กรองตามวันที่เข้าคลังปัจจุบัน </div>
                  <Switch
                    checked={usePurchaseDateSearch}
                    onChange={(e) => {
                      setUsePurchaseDateSearch(e.target.checked);
                    }}
                  />
                  <div>กรองตาม{findWord('purchase_date') || 'วันที่ซื้อ'}</div>
                </div>
              </div>
              <div className="w-full lg:w-2/3 px-2">
                {usePurchaseDateSearch ? (
                  <DaySpotAndRangePicker
                    startDate={startPurchaseDate}
                    endDate={endPurchaseDate}
                    setEndDate={setEndPurchaseDate}
                    setStartDate={setStartPurchaseDate}
                    flex
                    state="date"
                  />
                ) : (
                  <DaySpotAndRangePicker
                    startDate={startDate}
                    endDate={endDate}
                    setEndDate={setEndDate}
                    setStartDate={setStartDate}
                    flex
                    state="date"
                  />
                )}
              </div>
            </div>
          </Paper>
        </div>
        <div className="my-2">
          {query.get('place') && (
            <Card>
              <CardContent>
                <div className="flex justify-between flex-wrap">
                  <div className="w-full lg:w-1/2">
                    <div>คลังวัตถุดิบ</div>
                    <div className="text-lg font-semibold">{place?.name}</div>
                    {query.get('mistakeplace') === 'true' && (
                      <div>
                        <Chip color="warning" label="ไม่ตรงคลัง" />
                      </div>
                    )}
                  </div>

                  <div className="w-full lg:w-1/2 flex flex-wrap p-2 border">
                    <div className="lg:w-1/3  rounded-md p-2 flex items-center">
                      <div></div>
                      <div>
                        <Checkbox
                          checked={showRemainAmount}
                          onChange={(e) =>
                            setIsShowRemainAmount(e.target.checked)
                          }
                        />
                      </div>
                      <div>แสดงคงเหลือ</div>
                    </div>
                    {place?.place_action?.product_quantity_divide_by !==
                      PRODUCT_QUANTITY_DIVIDE_BY.NONE.status_code && (
                      <div className="lg:w-1/3  rounded-md p-2 flex items-center">
                        <div></div>
                        <div>
                          <Checkbox
                            checked={isUseDivider}
                            onChange={(e) => setIsUseDivider(e.target.checked)}
                          />
                        </div>
                        <div>หารออกมาเป็นหน่วยสินค้า</div>
                      </div>
                    )}{' '}
                    <div className="lg:w-1/3  rounded-md p-2 flex items-center">
                      <div></div>
                      <div>
                        <Checkbox
                          checked={isFilterByCustomer}
                          onChange={(e) =>
                            setIsFilterByCustomer(e.target.checked)
                          }
                        />
                      </div>
                      <div>แบ่งตามลูกค้า</div>
                    </div>
                    <div className="w-full p-2 flex gap-2">
                      <div className="flex items-center">
                        <div>
                          <Checkbox
                            checked={showRunOutOff}
                            onChange={(e) =>
                              setIsShowRunOutOff(e.target.checked)
                            }
                          />
                        </div>
                        <div>แสดงล็อตที่หมดแล้ว</div>
                      </div>
                    </div>
                  </div>
                </div>
              </CardContent>
            </Card>
          )}
        </div>

        <div className="my-2">
          {displayPivotTable ? (
            <MaterialToProductPivotTable
              generatedData={generatedData}
              showRemainAmount={showRemainAmount}
              page={page}
              size={size}
              total={total}
              showRunOutOff={showRunOutOff}
              useDivider={isUseDivider}
              dividedBy={place?.place_action?.product_quantity_divide_by}
              showCustomer={isFilterByCustomer}
            />
          ) : (
            <LoadingLinear />
          )}
          <TablePagination
            component="div"
            count={total}
            page={page - 1}
            onPageChange={(event, newValue) => {
              setPage(newValue);
            }}
            rowsPerPage={size}
            onRowsPerPageChange={(event) => {
              setSize(event.target.value);
              setPage(1);
            }}
          />
        </div>
      </div>
    );
  }
  return <Error />;
}

MaterialStockToProduct.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
};

MaterialStockToProduct.defaultProps = {
  title: '',
  subtitle: '',
};
