import React from 'react';
import { Autocomplete, Avatar, TextField } from '@mui/material';
import _ from 'lodash';
import PropTypes from 'prop-types';

const EmployeeAutoComplete = ({ employeeRows, field, disabled, label }) => {
  if (_.isEmpty(employeeRows)) {
    return <div></div>;
  }
  return (
    <Autocomplete
      {...field}
      disablePortal
      options={employeeRows}
      placeholder={label || 'เลือกพนักงาน'}
      onChange={(e, newValue) => {
        field.onChange(newValue);
      }}
      disabled={disabled}
      size="small"
      // prettier-ignore
      getOptionLabel={(option) => `${option?.firstname || ''} ${option?.lastname || ''}`
                }
      renderInput={(params) => (
        <TextField label={label || 'เลือกพนักงาน'} {...params} />
      )}
      renderOption={(props, option) => (
        <div className="flex" {...props}>
          <div className="w-1/6">
            <Avatar src={option?.image?.url}>
              {option?.firstname} {option?.lastname}
            </Avatar>
          </div>
          <div className="w-5/6">
            {option.firstname} {option.lastname}
          </div>
        </div>
      )}
    />
  );
};

export default EmployeeAutoComplete;

EmployeeAutoComplete.propTypes = {
  employeeRows: PropTypes.array.isRequired,
  field: PropTypes.object.isRequired,
  disabled: PropTypes.bool,
  label: PropTypes.string,
};
