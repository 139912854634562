import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Card, CardContent, Switch } from '@mui/material';
import _ from 'lodash';
import PropTypes from 'prop-types';

import {
  BackButton,
  Error,
  Loading,
  LoadingDialogWithTimeout,
  MaterialAmountPivotTable,
  MaterialLotTable,
  MaterialStockLotPieChart,
  ViewTitle,
} from '@iarcpsu/emanufac-components/src/components';
import { config } from '@/configs';
import * as actions from '@/redux/actions';
import { useQuery } from '@/utils/functions';

/**
 * @function ManyMaterialLots
 * @description Display a collections or a list of EditProcessTemplateTableView from database
 */

export default function ManyMaterialLots({ title, subtitle }) {
  const dispatch = useDispatch();
  const materialStockLot = useSelector((state) => state.materialStockLot);
  const information = useSelector((state) => state.information);
  const place = useSelector((state) => state.place);
  const material = useSelector((state) => state.material);
  const me = useSelector((state) => state.me);
  const params = useParams();
  const query = useQuery();
  const [showAmount, setIsShowAmount] = useState(false);

  const queryDataFromServer = async () => {
    try {
      dispatch(
        actions.materialStockLotAll({
          page: 1,
          ids: query.get('lots'),
          size: config.maxFetchSize,
          stockStatus: '',
        }),
      );
      if (query.get('place')) {
        dispatch(actions.placeGet(query.get('place')));
      }
    } catch (error) {
      dispatch(actions.materialStockLotError());
    }
  };

  const handleDeleteMaterialLot = async (id) => {
    const confirm = window.confirm('ยืนยันการลบล็อตนี้');
    if (confirm) {
      await dispatch(
        actions.materialStockLotDelete(id, {
          employee: me?.userData?._id,
        }),
      );
      queryDataFromServer();
    }
  };

  useEffect(() => {
    queryDataFromServer();
    return () => {};
  }, [params]);

  const renderTitle = () => <ViewTitle title={title} subtitle={subtitle} />;

  if (materialStockLot.isLoading || !materialStockLot?.rows) {
    return <LoadingDialogWithTimeout action="REFRESH" />;
  }

  if (!materialStockLot.isLoading && materialStockLot.isCompleted) {
    return (
      <div>
        {renderTitle()}
        <BackButton />
        <div className="my-2">
          <div className="flex flex-wrap my-2">
            <div className="w-full lg:w-1/2 px-2">
              <Card>
                <CardContent>
                  <MaterialStockLotPieChart
                    materialStockLotRows={materialStockLot?.rows}
                  />
                </CardContent>
              </Card>
            </div>
            <div className="w-full lg:w-1/2 px-2">
              <MaterialAmountPivotTable
                groupedBy={
                  place?.place_action?.for_stranger?.group_transfrom_result
                    ?.group_by
                }
                groupedResult={
                  place?.place_action?.for_stranger?.group_transfrom_result
                    ?.enable
                }
                materialStockLots={materialStockLot?.rows}
                materials={material}
                tag={
                  place?.place_action?.for_stranger?.group_transfrom_result
                    ?.tag_to_group
                }
                showAmount={showAmount}
                showCustomer={place?.place_action?.enable_customer}
                showPreferProduct={
                  place?.place_action?.enable_add_prefer_product
                }
              />
            </div>
          </div>
          <MaterialLotTable
            imsSetting={information?.setting?.ims}
            materialStockLot={materialStockLot}
            multiMaterial
            showPrice={false}
            showStatus
            size={_.size(materialStockLot?.rows)}
            total={_.size(materialStockLot?.rows)}
            page={1}
            showActionButton
            place={place}
            handleDelete={handleDeleteMaterialLot}
          />
        </div>
      </div>
    );
  }
  return <Error />;
}

ManyMaterialLots.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
};

ManyMaterialLots.defaultProps = {
  title: '',
  subtitle: '',
};
