import React from 'react';
import { Link } from 'react-router-dom';
import { Alert, AlertTitle, Button, Card } from '@mui/material';
import dayjs from 'dayjs';
import _ from 'lodash';
import PropTypes from 'prop-types';

import { currencyFormatter, durationTimeDisplay } from '@/utils/functions';

import 'dayjs/locale/th';

import NameBox from '../Common/NameBox';

const InfoManufacturingOrder = ({
  manufacturingOrder,
  information,
  handleMOToCancle,
  handleMOToSuccess,
}) => {
  const {
    setting: { mms: mmsSetting },
  } = information;
  const { timeDurationWord } = durationTimeDisplay;
  const isMOComplete = manufacturingOrder?.completed;
  const isMODeleted = manufacturingOrder?.deleted;

  // คำนวณหาว่าเหลือกี่วันจาก Function ที่เขียนไว้ใน Util
  const calculatedDate = manufacturingOrder?.completed
    ? dayjs(manufacturingOrder.updatedAt)
    : dayjs();

  // แสดงกรณี Manufac Order Success แล้ว
  const renderSuccessBadge = () => {
    if (isMOComplete) {
      return (
        <div className="my-2">
          <Alert severity="success">
            <AlertTitle>
              <strong>การผลิตสำเร็จแล้ว</strong>
            </AlertTitle>
            ทุกงานในคำสั่งผลิตนี้สำเร็จแล้ว
          </Alert>
        </div>
      );
    }
    return <div></div>;
  };

  // แสดงกรณี Manufac Order ยกเลิก
  const renderDeleteBadge = () => {
    if (isMODeleted) {
      return (
        <div className="my-2">
          <Alert severity="error">
            <AlertTitle>
              <strong>การผลิตถูกยกเลิก</strong>
            </AlertTitle>
            คำสั่งผลิตนี้ถูกยกเลิกไปแล้ว
            {manufacturingOrder?.delete_note
              ? `ด้วยเหตุผล ${manufacturingOrder?.delete_note}`
              : ''}
          </Alert>
        </div>
      );
    }
    return <div></div>;
  };

  const timeReminder = (expectedDate) => {
    const timeDuration = timeDurationWord(
      expectedDate,
      calculatedDate,
      manufacturingOrder?.completed,
    );
    return <div className={timeDuration.cssClass}> {timeDuration.word} </div>;
  };

  const renderSuccessOrRemoveSelect = () => {
    const completedProcesses = _.filter(
      manufacturingOrder?.process,
      (eachProcess) => eachProcess?.completed,
    );
    const deletedProcesses = _.filter(
      manufacturingOrder?.process,
      (eachProcess) => eachProcess?.deleted,
    );
    if (
      !_.isEmpty(completedProcesses) &&
      !_.isEmpty(deletedProcesses) &&
      !manufacturingOrder?.completed &&
      !manufacturingOrder?.deleted
    ) {
      return (
        <div className=" m-2">
          <div className="bg-gray-100 rounded-md p-4">
            <div className="text-center">
              คำสั่งผลิตนี้ประกอบไปด้วยทั้งงานที่สำเร็จ และ งานที่ยกเลิก
              ต้องการตั้งค่าสถานะคำสั่งผลิตเป็นสถานะใด
            </div>
            <div className="flex justify-center gap-2 mt-2">
              <Button
                variant="contained"
                color="error"
                onClick={() =>
                  handleMOToCancle({
                    manufacturingOrderId: manufacturingOrder?._id,
                  })
                }
              >
                ยกเลิก
              </Button>
              <Button
                variant="contained"
                color="success"
                onClick={() =>
                  handleMOToSuccess({
                    manufacturingOrderId: manufacturingOrder?._id,
                  })
                }
              >
                สำเร็จ
              </Button>
            </div>
          </div>
        </div>
      );
    }
    return <div></div>;
  };

  const renderDisplayMode = () => (
    <div className="flex flex-wrap w-full ">
      <div className="w-full text-lg py-2 px-2 font-semibold font-display ">
        {manufacturingOrder?.running_number}
      </div>
      {manufacturingOrder?.customer &&
        !_.isEmpty(manufacturingOrder?.customer) && (
          <div className="w-full md:w-1/6 py-2 px-2 font-semibold font-display ">
            ลูกค้า
          </div>
        )}
      {manufacturingOrder?.customer &&
        !_.isEmpty(manufacturingOrder?.customer) && (
          <div className="w-full md:w-5/6 py-2 px-2">
            {manufacturingOrder?.order?.customer?.type_code ||
              manufacturingOrder?.customer?.type_code}{' '}
            -{' '}
            {manufacturingOrder?.order?.customer?.name ||
              manufacturingOrder?.customer?.name}
            <br />
          </div>
        )}
      <div className="w-full md:w-1/6 py-1 md:py-2 px-2 font-semibold font-display ">
        ผู้สั่งผลิต
      </div>
      <div className="w-full md:w-5/6 py-1 md:py-2 px-2">
        <NameBox user={manufacturingOrder?.assigner} showDepartment={false} />
      </div>
      <div className="w-full md:w-1/6 py-1 pt-4 md:py-2 px-2 font-semibold font-display ">
        วันเริ่มการผลิต
      </div>

      <div className="w-full md:w-2/6 py-1 md:py-2 px-2">
        <div className="flex gap-2 md:block">
          {dayjs(manufacturingOrder?.start_date)
            .locale('th')
            .format(
              mmsSetting?.timing?.showTime
                ? 'D MMMM BBBB HH:mm'
                : 'D MMMM BBBB',
            )}
        </div>
      </div>
      {mmsSetting?.usingAsDefault?.showExpectedDate && (
        <div className="w-full md:w-1/6 py-1  md:py-2 pt-4 md:pt-2 px-2 font-semibold font-display ">
          วันกำหนดส่ง
        </div>
      )}

      {mmsSetting?.usingAsDefault?.showExpectedDate && (
        <div className="flex flex-wrap w-full md:w-2/6 py-1 md:py-2 px-2 ">
          {/* <div className=" gap-2 md:block"> */}
          <div className="mr-4">
            {dayjs(manufacturingOrder?.expected_date)
              .locale('th')
              .format(
                mmsSetting?.timing?.showTime
                  ? 'D MMMM BBBB HH:mm'
                  : 'D MMMM BBBB',
              )}
          </div>
          <div>
            {!isMOComplete &&
              !isMODeleted &&
              timeReminder(manufacturingOrder?.expected_date)}
          </div>
        </div>
      )}

      {information?.setting?.mms?.manufacturingOrder?.showQuotation &&
        manufacturingOrder?.quotation_number &&
        manufacturingOrder?.quotation_number !== '' && (
          <div className="w-full md:w-1/6 py-2 px-2 font-semibold font-display ">
            เลขที่ใบเสนอราคา
          </div>
        )}
      {information?.setting?.mms?.manufacturingOrder?.showQuotation &&
        manufacturingOrder?.quotation_number &&
        manufacturingOrder?.quotation_number !== '' && (
          <div className="w-full md:w-2/6 py-2 px-2">
            {manufacturingOrder?.quotation_number}
          </div>
        )}

      {information?.setting?.mms?.manufacturingOrder?.showPaymentMethod &&
        manufacturingOrder?.payment_method && (
          <div className="w-full md:w-1/6 py-2 px-2 font-semibold font-display ">
            วิธีการชำระเงิน
          </div>
        )}
      {information?.setting?.mms?.manufacturingOrder?.showPaymentMethod &&
        manufacturingOrder?.payment_method && (
          <div className="w-full md:w-2/6 py-2 px-2">
            {manufacturingOrder?.payment_method?.name}
          </div>
        )}
      {(information?.setting?.mms?.manufacturingOrder?.showPrice &&
        manufacturingOrder?.discount && (
          <div className="w-full md:w-1/6 py-2 px-2 font-semibold font-display ">
            ส่วนลด
          </div>
        )) ||
        ''}

      {(information?.setting?.mms?.manufacturingOrder?.showPrice &&
        manufacturingOrder?.discount &&
        manufacturingOrder?.discount !== 0 && (
          <div className="w-full md:w-2/6 py-2 px-2">
            {currencyFormatter.format(manufacturingOrder?.discount)} บาท
          </div>
        )) ||
        ''}
      <div className="w-full">
        <div className="w-full px-2 pt-2 font-semibold font-display ">
          รายละเอียด / หมายเหตุ
        </div>
        <div className="w-full py-1 px-2 lg:pr-6">
          {manufacturingOrder?.remark || '-'}
        </div>
      </div>
      <div className="w-full">
        {manufacturingOrder?.process &&
          renderSuccessBadge(manufacturingOrder?.expected_date)}
        {manufacturingOrder?.process && renderDeleteBadge()}
      </div>
      <div className="w-full">{renderSuccessOrRemoveSelect()}</div>
      {!_.isEmpty(information?.setting?.mms?.customReport) && (
        <div className="px-2 w-full font-semibold font-display py-1">
          รายงาน
        </div>
      )}
      <div className="px-2 flex flex-wrap gap-2">
        {_.map(information?.setting?.mms?.customReport, (eachCReport, idx) => (
          <Link
            to={{
              pathname: '/mms/manufacturing-orders/report',
              state: {
                manufacturingOrder,
                report: eachCReport,
              },
            }}
            key={idx}
          >
            <Button variant="outlined">{eachCReport?.name}</Button>
          </Link>
        ))}
      </div>
    </div>
  );

  return (
    <div>
      <Card className=" p-4 pb-6">{renderDisplayMode()} </Card>
    </div>
  );
};

InfoManufacturingOrder.propTypes = {
  manufacturingOrder: PropTypes.object,
  me: PropTypes.object,
  information: PropTypes.object,
  handleToEditorMode: PropTypes.func,
  handleMOToCancle: PropTypes.func,
  handleMOToSuccess: PropTypes.func,
  showStatus: PropTypes.bool,
};

export default InfoManufacturingOrder;
