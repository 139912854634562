import React, { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { Button } from '@mui/material';
import _ from 'lodash';
import PropTypes from 'prop-types';

import {
  BackButton,
  ViewTitle,
} from '@iarcpsu/emanufac-components/src/components';
import { Error } from '@iarcpsu/emanufac-components/src/components/Error';
import { InformationForm } from '@iarcpsu/emanufac-components/src/components/Forms';
import Loading from '@iarcpsu/emanufac-components/src/components/Loading';
import * as actions from '../../redux/actions';

function Information({ title, subtitle }) {
  const dispatch = useDispatch();
  const information = useSelector((state) => state.information);
  const [logoImage, setLogoImage] = useState([]);

  const {
    formState: { errors },
    handleSubmit,
    control,
  } = useForm();

  useEffect(() => {
    dispatch(actions.informationAll());
    return () => {};
  }, []);

  const onSubmit = async (data, event) => {
    try {
      event.preventDefault();
      if (!_.isEmpty(logoImage)) {
        await dispatch(
          actions.informationPut(information.id, {
            ...data,
            logo: {
              image: logoImage[0]?.data_url,
              imageType: 'profile',
              alt: '',
            },
          }),
        );
        setLogoImage([]);
      } else {
        await dispatch(actions.informationPut(information.id, data));
      }
      alert('สำเร็จ');
      await dispatch(actions.informationAll());
    } catch (error) {
      console.error(error);
    }
  };

  console.log('logoImage', logoImage);

  const renderTitle = () => <ViewTitle title={title} subtitle={subtitle} />;

  const renderForm = () => (
    <div className="my-2">
      <form onSubmit={handleSubmit(onSubmit)}>
        <InformationForm
          Controller={Controller}
          control={control}
          errors={errors}
          information={information}
          selectedImage={logoImage}
          setSelectedImage={setLogoImage}
        />
        <div className="flex flex-row justify-end gap-1 py-4">
          <Button variant="contained" type="submit">
            บันทึก
          </Button>
        </div>
      </form>
    </div>
  );

  if (information.isLoading) {
    return <Loading />;
  }
  if (!information.isLoading && information.isCompleted) {
    return (
      <div>
        {renderTitle()}
        <BackButton />
        {renderForm()}
      </div>
    );
  }
  return <Error />;
}
Information.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
};

Information.defaultProps = {
  title: '',
  subtitle: '',
};

export default Information;
