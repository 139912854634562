import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect, Route, Switch, useLocation } from 'react-router-dom';
import {
  NotAuthorized,
  NotFound,
} from '@iarcpsu/emanufac-components/src/components/Error';
import { MainFooter } from '@iarcpsu/emanufac-components/src/components/Footers';
import { Loading } from '@iarcpsu/emanufac-components/src/components/Loading';
import { HomeNavbar } from '@iarcpsu/emanufac-components/src/components/Navbars';
import { MainSidebar } from '@iarcpsu/emanufac-components/src/components/Sidebar';

import { config } from '@/configs';

import * as actions from '../redux/actions';
import accessRight from '../utils/functions/accessRight';
import {
  Dashboard,
  DetailMaterialOnProcess,
  DetailMeasuredData,
  DetailWork,
  EditProductProcess,
  EditStepMeasurement,
  HistoryWork,
  ManufacturingOrderViews,
  MeasuredData,
  MyWorks,
  PluginEditData,
  ProcessView,
  ReportView,
  SettingView,
  Works,
} from '../views/MMS';

export function MMS() {
  const module = 'MMS';
  const prefix = '/mms';
  const name = 'การผลิต';
  const dispatch = useDispatch();
  const { pathname } = useLocation();

  const me = useSelector((state) => state.me);
  const availableModule = useSelector((state) => state.availableModule);
  const information = useSelector((state) => state.information);

  const [isMobileNavOpen, setMobileNavOpen] = useState(false);
  const [sidebarOpen, setSidebarOpen] = useState(true);
  const handleOnMobileNavOpen = () => {
    setMobileNavOpen(true);
  };
  const handleOnMobileNavClose = () => {
    setMobileNavOpen(false);
  };

  useEffect(() => {
    console.log('Layout: MMS');
    dispatch(actions.meGet());
    dispatch(actions.informationAll());
    dispatch(actions.availableModuleAll());
    dispatch(
      actions.conversionAll({ name: '', page: 1, size: config.maxFetchSize }),
    );
    return () => {};
  }, []);

  if (!me?.userData) {
    return <Loading />;
  }

  if (accessRight(me, module, 0, availableModule)) {
    return (
      <div className="min-h-screen">
        <Helmet>
          <meta charSet="utf-8" />
          <title>การผลิต - {information?.name || 'E-Manufac'}</title>
        </Helmet>
        <MainSidebar
          onMobileClose={handleOnMobileNavClose}
          openMobile={isMobileNavOpen}
          me={me}
          isTaskbarOpen={sidebarOpen}
          setIsTaskbarOpen={setSidebarOpen}
        />
        <HomeNavbar onMobileNavOpen={handleOnMobileNavOpen} />
        <div
          className={`${
            sidebarOpen ? 'lg:ml-64' : ''
          }   min-h-screen  pt-16 px-8 `}
        >
          <div className="py-4">
            <Switch>
              <Redirect
                exact
                from={`${prefix}`}
                to={`${prefix}/manufacturing-orders`}
              />
              <Redirect from="/:url*(/+)" to={pathname.slice(0, -1)} />{' '}
              <Route exact path={`${prefix}/dashboard`}>
                <Dashboard title="แดชบอร์ด" subtitle={name} />
              </Route>
              <Route exact path={`${prefix}/myworks`}>
                <MyWorks title="งานของฉัน" subtitle={name} />
              </Route>
              <Route exact path={`${prefix}/works`}>
                <Works title="รายการงาน" subtitle={name} />
              </Route>
              <Route exact path={`${prefix}/works/view/:id`}>
                <DetailWork title="รายละเอียดงาน" subtitle={name} />
              </Route>
              <Route exact path={`${prefix}/works/plugin/edit/:pluginId/:id`}>
                <PluginEditData title="ใส่ข้อมูลตามปลั๊กอิน" subtitle={name} />
              </Route>
              <Route exact path={`${prefix}/works/measurement/edit/:id`}>
                <EditStepMeasurement
                  title="แก้ไขตัวแปรรับค่า"
                  subtitle={name}
                />
              </Route>
              <Route exact path={`${prefix}/manufacturing-orders`}>
                <ManufacturingOrderViews.ManufactoringOrders
                  title="จัดการคำสั่งผลิต"
                  subtitle={name}
                />
              </Route>
              <Route exact path={`${prefix}/manufacturing-orders/create`}>
                <ManufacturingOrderViews.CreateManufacturingOrder
                  title="เพิ่มคำสั่งผลิต"
                  subtitle={name}
                />
              </Route>
              <Route exact path={`${prefix}/manufacturing-orders/assign/:id`}>
                <ManufacturingOrderViews.AssignManufacturingOrder
                  title="การแจกจ่ายงาน"
                  subtitle={name}
                />
              </Route>
              <Route exact path={`${prefix}/manufacturing-orders/edit/:id`}>
                <ManufacturingOrderViews.EditManufacturingOrder
                  title="แก้ไขคำสั่งผลิต"
                  subtitle={name}
                />
              </Route>
              <Route
                exact
                path={`${prefix}/manufacturing-orders/bom-checking/:id`}
              >
                <ManufacturingOrderViews.ManufacturingOrderBom
                  title="วัตถุดิบส่วนผสม"
                  subtitle={name}
                />
              </Route>
              <Route
                exact
                path={`${prefix}/manufacturing-orders/create-new-process/:id`}
              >
                <ManufacturingOrderViews.CreateProcessInMO
                  title="เพิ่มการผลิต"
                  subtitle={name}
                />
              </Route>
              <Route
                exact
                path={`${prefix}/manufacturing-orders/material/:processid/:materialid`}
              >
                <DetailMaterialOnProcess
                  title="การใช้วัตถุดิบ"
                  subtitle={name}
                />
              </Route>
              <Route
                exact
                path={`${prefix}/manufacturing-orders/view/:id/:tab`}
              >
                <ManufacturingOrderViews.DetailManufacOrderA
                  title="รายละเอียดคำสั่งผลิต"
                  subtitle={name}
                />
              </Route>
              <Route exact path={`${prefix}/manufacturing-orders/view/:id/`}>
                <ManufacturingOrderViews.DetailManufacOrderA
                  title="รายละเอียดคำสั่งผลิต"
                  subtitle={name}
                />
              </Route>
              <Route exact path={`${prefix}/manufacturing-orders/plan/`}>
                <ManufacturingOrderViews.ManufacturingPlaning
                  title="วางแผนการผลิต"
                  subtitle={name}
                />
              </Route>
              <Route exact path={`${prefix}/manufacturing-orders/report/`}>
                <ManufacturingOrderViews.ReportDisplay
                  title="แสดงรายงาน"
                  subtitle={name}
                />
              </Route>
              <Route
                exact
                path={`${prefix}/manufacturing-orders-b/view/:id/:processId`}
              >
                <ManufacturingOrderViews.DetailManufacOrderB
                  title="รายละเอียดคำสั่งผลิต"
                  subtitle={name}
                />
              </Route>
              <Route exact path={`${prefix}/manufacturing-orders-b/view/:id`}>
                <ManufacturingOrderViews.DetailManufacOrderB
                  title="รายละเอียดคำสั่งผลิต"
                  subtitle={name}
                />
              </Route>
              <Route exact path={`${prefix}/manufacturing-orders-c/view/:id`}>
                <ManufacturingOrderViews.DetailManufacOrderC
                  title="รายละเอียดคำสั่งผลิต"
                  subtitle={name}
                />
              </Route>{' '}
              <Route
                exact
                path={`${prefix}/manufacturing-orders-c/view/:id/:tab`}
              >
                <ManufacturingOrderViews.DetailManufacOrderC
                  title="รายละเอียดคำสั่งผลิต"
                  subtitle={name}
                />
              </Route>
              <Route
                exact
                path={`${prefix}/manufacturing-orders/custom/:dashboardId/:id/`}
              >
                <ManufacturingOrderViews.CustomDetailManufacturingOrder
                  title="รายละเอียดคำสั่งผลิต"
                  subtitle={name}
                />
              </Route>
              <Route exact path={`${prefix}/process`}>
                <ProcessView.ProductionLines
                  title="ไลน์การผลิต"
                  subtitle={name}
                />
              </Route>
              <Route exact path={`${prefix}/process/add-inventory/:id/`}>
                <ProcessView.AddToMaterialInventory
                  title="เพิ่มไปยังคลังวัตถุดิบ"
                  subtitle={name}
                />
              </Route>
              <Route exact path={`${prefix}/process/edit/:id/`}>
                <ProcessView.EditProcess
                  title="แก้ไขไลน์การผลิต"
                  subtitle={name}
                />
              </Route>
              <Route exact path={`${prefix}/process/assign/:id/`}>
                <ProcessView.ProcessAssignment
                  title="แจกจ่ายงาน"
                  subtitle={name}
                />
              </Route>
              <Route exact path={`${prefix}/process/withdraw-material/:id/`}>
                <ProcessView.WithdrawMaterial
                  title="เบิกวัตถุดิบ"
                  subtitle={name}
                />
              </Route>
              <Route
                exact
                path={`${prefix}/process/request-withdraw-material/:id/`}
              >
                <ProcessView.CreateRequestOfMaterial
                  title="ขอเบิกวัตถุดิบ"
                  subtitle={name}
                />
              </Route>
              <Route exact path={`${prefix}/process/handle-next-step/:id/`}>
                <ProcessView.HandleNextStep
                  title="บันทึกข้อมูลและไปขั้นตอนต่อไป"
                  subtitle={name}
                />
              </Route>
              <Route exact path={`${prefix}/process/return-material/:id/`}>
                <ProcessView.ReturnMaterial
                  title="คืนวัตถุดิบ"
                  subtitle={name}
                />
              </Route>
              <Route exact path={`${prefix}/process/add-warehouse/:id/`}>
                <ProcessView.HandleAddToWarehouse
                  title="เพิ่มลงคลังสินค้า"
                  subtitle={name}
                />
              </Route>
              <Route exact path={`${prefix}/process/measurement/edit/:id/`}>
                <ProcessView.EditMeasurement
                  title="แก้ไขการวัดค่า"
                  subtitle={name}
                />
              </Route>
              <Route exact path={`${prefix}/process/measurement/pre-edit/:id/`}>
                <ProcessView.MeasurementPreEdit
                  title="แก้ไขการวัดค่า"
                  subtitle={name}
                />
              </Route>
              <Route exact path={`${prefix}/process/bom-checking/:id/`}>
                <ProcessView.ProcessBom
                  title="รายละเอียดวัตถุดิบส่วนผสม"
                  subtitle={name}
                />
              </Route>
              <Route
                exact
                path={`${prefix}/process/request-add-warehouse/:id/`}
              >
                <ProcessView.RequestAddWarehouse
                  title="ขอเพิ่มลงคลังสินค้า"
                  subtitle={name}
                />
              </Route>
              <Route exact path={`${prefix}/process/select-current-step/:id/`}>
                <ProcessView.SelectCurrentStep
                  title="ปรับขั้นตอนงานปัจจุบัน"
                  subtitle={name}
                />
              </Route>
              <Route exact path={`${prefix}/process/product-barcode/:id`}>
                <ProcessView.ProcessBarcodePrint
                  title="พิมพ์บาร์โค้ด"
                  subtitle={name}
                />
              </Route>
              <Route exact path={`${prefix}/material-usage/edit/:processid/`}>
                <ProcessView.SelectEditMaterialUsage
                  title="แก้ไขการใช้วัตถุดิบ"
                  subtitle={name}
                />
              </Route>
              <Route
                exact
                path={`${prefix}/material-usage/edit/:processid/:materialid`}
              >
                <ProcessView.EditMaterialUsageTransaction
                  title="แก้ไขการใช้วัตถุดิบ"
                  subtitle={name}
                />
              </Route>
              <Route exact path={`${prefix}/process/recieve-material/:id`}>
                <ProcessView.RecieveMaterial
                  title="รับวัตถุดิบเข้าสู่การผลิต"
                  subtitle={name}
                />
              </Route>{' '}
              <Route
                exact
                path={`${prefix}/process/recieve-material/:id/:stepId`}
              >
                <ProcessView.RecieveMaterial
                  title="รับวัตถุดิบเข้าสู่การผลิต"
                  subtitle={name}
                />
              </Route>{' '}
              <Route
                exact
                path={`${prefix}/process/splitting-recieve-material/:id/:splittingProcessId`}
              >
                <ProcessView.SplittingProcessRecieveMaterial
                  title="รับวัตถุดิบเข้าสู่การผลิต"
                  subtitle={name}
                />
              </Route>{' '}
              <Route
                exact
                path={`${prefix}/process/splitting-recieve-material/:id`}
              >
                <ProcessView.SplittingProcessRecieveMaterial
                  title="รับวัตถุดิบเข้าสู่การผลิต"
                  subtitle={name}
                />
              </Route>{' '}
              <Route exact path={`${prefix}/process/splitting-close/:id`}>
                <ProcessView.SplittingProcessClose
                  title="รับวัตถุดิบเข้าสู่การผลิต"
                  subtitle={name}
                />
              </Route>{' '}
              <Route
                exact
                path={`${prefix}/process/splitting-close/:id/:splittingProcessId`}
              >
                <ProcessView.SplittingProcessClose
                  title="ปิดงานและเพิ่มลงคลังวัตถุดิบ"
                  subtitle={name}
                />
              </Route>{' '}
              <Route exact path={`${prefix}/process/split-more-process/:id`}>
                <ProcessView.SplitMoreProcess
                  title="แบ่งไลน์ผลิตเพิ่ม"
                  subtitle={name}
                />
              </Route>{' '}
              <Route exact path={`${prefix}/process/splitting/expand`}>
                <ProcessView.ExpandMaterialSplitting
                  title="การแบ่งกระจายวัตถุดิบ"
                  subtitle={name}
                />
              </Route>{' '}
              <Route exact path={`${prefix}/process/cost-detail/:id`}>
                <ProcessView.ProcessCostDetail
                  title="ส่วนประกอบของต้นทุน"
                  subtitle={name}
                />
              </Route>
              <Route exact path={`${prefix}/measured-data`}>
                <MeasuredData
                  title="ค่าที่วัดได้จากกระบวนการ"
                  subtitle={name}
                />
              </Route>
              <Route exact path={`${prefix}/measured-data/detail/:id`}>
                <DetailMeasuredData title="รายละเอียดค่า" subtitle={name} />
              </Route>
              <Route exact path={`${prefix}/history/manufacturing-orders`}>
                <ManufacturingOrderViews.HistoryManufacturingOrder
                  title="ประวัติคำสั่งผลิต"
                  subtitle={name}
                />
              </Route>
              <Route exact path={`${prefix}/bom-report`}>
                <ReportView.ReportAllBom
                  title="รายงานการใช้วัตถุดิบส่วนผสม"
                  subtitle={name}
                />
              </Route>
              {accessRight(me, module, 1, availableModule) && (
                <Switch>
                  {' '}
                  <Route exact path={`${prefix}/product-process/edit/:id`}>
                    <EditProductProcess
                      title="แก้ไขรูปแบบไลน์การผลิต"
                      subtitle={name}
                    />
                  </Route>
                  <Route exact path={`${prefix}/history/works`}>
                    <HistoryWork title="ประวัติงาน" subtitle={name} />
                  </Route>
                  <Route exact path={`${prefix}/material-report`}>
                    <ReportView.ReportMaterialUsage
                      title="รายงานการใช้วัตถุดิบ"
                      subtitle={name}
                    />
                  </Route>
                  <Route exact path={`${prefix}/material-report/:id`}>
                    <ReportView.DetailReportMaterialUsage
                      title="รายงานการใช้วัตถุดิบ"
                      subtitle={name}
                    />
                  </Route>
                  <Route exact path={`${prefix}/manufacturing-order-report/`}>
                    <ReportView.ReportManufacturingOrder
                      title="รายงานคำสั่งผลิต"
                      subtitle={name}
                    />
                  </Route>
                  <Route
                    exact
                    path={`${prefix}/manufacturing-order-report/monthy/:year/:month`}
                  >
                    <ReportView.OnMonthReportManufacturingOrder
                      title="รายงานคำสั่งผลิตเดือน"
                      subtitle={name}
                    />
                  </Route>
                  {/** Duplicate From MMS */}
                  <Route exact path={`${prefix}/process-template/create`}>
                    <SettingView.CreateProcessTemplate
                      title="สร้างสูตรการผลิต"
                      subtitle={name}
                    />
                  </Route>
                  <Route exact path={`${prefix}/process-template`}>
                    <SettingView.ProcessTemplate
                      title="รูปแบบโฟลว์การผลิต / สูตรการผลิต"
                      subtitle={name}
                    />
                  </Route>
                  <Route
                    exact
                    path={`${prefix}/process-template/edit/table/:id`}
                  >
                    <SettingView.EditProcessTemplateTableView
                      title="แก้ไขสูตรการผลิต"
                      subtitle={name}
                    />
                  </Route>
                  <Route exact path={`${prefix}/process-template/edit/:id`}>
                    <SettingView.EditProcessTemplate
                      title="แก้ไขรูปแบบโฟลว์การผลิต / สูตรการผลิต"
                      subtitle={name}
                    />
                  </Route>
                  <Route exact path={`${prefix}/process-template/view/:id`}>
                    <SettingView.DetailProcessTemplate
                      title="รายละเอียดรูปแบบโฟลว์การผลิต / สูตรการผลิต"
                      subtitle={name}
                    />
                  </Route>{' '}
                  <Route
                    exact
                    path={`${prefix}/process-template/measurement/edit/:id/:index`}
                  >
                    <SettingView.EditProcessTemplateMeasurement
                      title="แก้ไขตัวแปรวัดค่าในสูตรการผลิต"
                      subtitle={name}
                    />
                  </Route>{' '}
                  <Route
                    exact
                    path={`${prefix}/process-template/measurement/arrange/:id/:index`}
                  >
                    <SettingView.ArrangeProcessTemplateMeasurement
                      title="จัดเรียงตัวแปรวัดค่าในสูตรการผลิต"
                      subtitle={name}
                    />
                  </Route>{' '}
                  <Route
                    exact
                    path={`${prefix}/process-template/resulting-function/edit/:id/:index`}
                  >
                    <SettingView.EditProcessTemplateResultingFunction
                      title="แก้ไขฟังก์ชันการแสดงผลในสูตรการผลิต"
                      subtitle={name}
                    />
                  </Route>
                  <Route exact path={`${prefix}/measurement-type`}>
                    <SettingView.MeasurementType
                      title="ตัวแปรรับค่า"
                      subtitle={name}
                    />
                  </Route>
                  <Route exact path={`${prefix}/measurement-type/edit/:id`}>
                    <SettingView.EditMeasurementType
                      title="แก้ไขตัวแปรรับค่า"
                      subtitle={name}
                    />
                  </Route>
                  <Route exact path={`${prefix}/bom-template`}>
                    <SettingView.BOMTemplates
                      title="รายการสูตรส่วนผสม"
                      subtitle={name}
                    />
                  </Route>
                  <Route exact path={`${prefix}/bom-template/create`}>
                    <SettingView.CreateBOMTemplate
                      title="สร้างสูตรส่วนผสม"
                      subtitle={name}
                    />
                  </Route>
                  <Route exact path={`${prefix}/bom-template/view/:id`}>
                    <SettingView.DetailBomTemplate
                      title="รายละเอียดสูตรส่วนผสม"
                      subtitle={name}
                    />
                  </Route>
                  <Route exact path={`${prefix}/bom-template/edit/:id`}>
                    <SettingView.EditBOMTemplate
                      title="แก้ไชสูตรส่วนผสม"
                      subtitle={name}
                    />
                  </Route>
                  <Route exact path={`${prefix}/base-project`}>
                    <SettingView.BaseProjects
                      title="โปรเจกต์/สินค้าพื้นฐาน"
                      subtitle={name}
                    />
                  </Route>
                  <Route exact path={`${prefix}/base-project/create`}>
                    <SettingView.CreateBaseProject
                      title="สร้างโปรเจกต์/สินค้าพื้นฐาน"
                      subtitle={name}
                    />
                  </Route>
                  <Route exact path={`${prefix}/base-project/edit/:id`}>
                    <SettingView.EditBaseProject
                      title="แก้ไขโปรเจกต์/สินค้าพื้นฐาน"
                      subtitle={name}
                    />
                  </Route>
                </Switch>
              )}
              <Route path="*">
                <NotFound />
              </Route>
            </Switch>
          </div>
        </div>
        <div className="lg:ml-64">
          <MainFooter />
        </div>
      </div>
    );
  }
  return (
    <div>
      <div className="min-h-screen">
        <MainSidebar
          onMobileClose={handleOnMobileNavClose}
          openMobile={isMobileNavOpen}
          me={me}
        />
        <HomeNavbar onMobileNavOpen={handleOnMobileNavOpen} />
        <NotAuthorized />
        <div className="lg:ml-64">
          <MainFooter />
        </div>
      </div>
    </div>
  );
}

export default MMS;
