import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import {
  Button,
  Card,
  TextField,
  InputAdornment,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  Paper,
  FormControl,
  Select,
  MenuItem,
  InputLabel,
} from '@mui/material';
import { useForm, Controller } from 'react-hook-form';
import _ from 'lodash';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';
import { PurchaseOrderReport } from '@iarcpsu/emanufac-components/src/components/Report/index';
import * as actions from '../../redux/actions';
import {
  Loading,
  Error,
  ViewTitle,
  ExportExcelContainer,
} from '@iarcpsu/emanufac-components/src/components';
import { MATERIAL_PURCHASE_STATUS } from '../../utils/constants';
import { PurchaseOrderUtil } from '../../utils/modelutils';
import currencyFormatterTH from '../../utils/functions/currencyFormatterTH';

export default function PurchaseOrder({ title, subtitle }) {
  const dispatch = useDispatch();
  const purchaseOrder = useSelector((state) => state.purchaseOrder);
  const information = useSelector((state) => state.information);
  const { control } = useForm();
  const [searchTerm, setSearchTerm] = useState('');
  const [name, setName] = useState('');
  const [page, setPage] = useState(1);
  const [size, setSize] = useState(10);
  const [statusCode, setStatusCode] = useState('');
  const [total, setTotal] = useState(undefined);
  const history = useHistory();

  useEffect(() => {
    const fetchMaterterialRequest = async () => {
      try {
        await dispatch(
          actions.purchaseOrderAll({
            name,
            page,
            size,
            statusCode,
          }),
        );
        await dispatch(actions.informationAll());
      } catch (error) {
        console.error(error);
      }
    };

    fetchMaterterialRequest();

    return () => {};
  }, [name, page, size, statusCode]);

  useEffect(() => {
    setTotal(purchaseOrder?.total);
    return () => {};
  }, [purchaseOrder]);

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      setName(searchTerm);
      setPage(1);
    }, 700);
    return () => clearTimeout(delayDebounceFn);
  }, [searchTerm]);

  const renderTitle = () => <ViewTitle title={title} subtitle={subtitle} />;

  const handlePushToDetailPurchaseOrder = async (id) => {
    history.push(`purchase-order/${id}`);
  };

  const handlePrintPdf = (row) => {
    try {
      PurchaseOrderReport(row, information);
    } catch (error) {
      PurchaseOrderReport(row, information, true);
    }
  };

  const handleChangeRowsPerPage = (event) => {
    setSize(event.target.value);
    setPage(1);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage + 1);
  };

  const handleChangeStatus = (status) => {
    setStatusCode(status);
  };

  const renderSearch = () => (
    <Card>
      <div className="p-4 flex flex-row gap-2">
        <div className="w-full md:w-1/2">
          <TextField
            label="ค้นหา"
            fullWidth
            size={'small'}
            id="outlined-start-adornment"
            onChange={(e) => {
              setSearchTerm(e.target.value);
            }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <i className="fas fa-search"></i>
                </InputAdornment>
              ),
            }}
          />
        </div>
        <div className="w-full md:w-1/4">
          <Controller
            name={'status_code'}
            control={control}
            defaultValue={''}
            rules={{ required: true }}
            render={({ field }) => (
              <FormControl sx={{ minWidth: 120 }} fullWidth={true}>
                <InputLabel id="type" size={'small'}>
                  ค้นหาด้วยสถานะ
                </InputLabel>
                <Select
                  {...field}
                  label="ค้นหาด้วยสถานะ"
                  size={'small'}
                  fullWidth
                  onChange={(e) => {
                    field.onChange(e.target.value);
                    handleChangeStatus(e.target.value);
                  }}
                >
                  <MenuItem value="">ทั้งหมด</MenuItem>
                  {_.map(
                    MATERIAL_PURCHASE_STATUS,
                    (_MATERIAL_PURCHASE_STATUS) => (
                      <MenuItem
                        value={_MATERIAL_PURCHASE_STATUS?.status_code}
                        key={_MATERIAL_PURCHASE_STATUS?.status_code}
                      >
                        {_MATERIAL_PURCHASE_STATUS?.description}
                      </MenuItem>
                    ),
                  )}
                </Select>
              </FormControl>
            )}
          />
        </div>
        <div className="w-full md:w-1/4 flex justify-end">
          <ExportExcelContainer
            columnList={PurchaseOrderUtil.columns}
            currentData={purchaseOrder?.rows}
            dataAPIEndPoint="purchase-order"
            dataQuery={{ statusCode }}
            spreadedColumn="material"
            sheetName="PurchaseOrder"
          />
        </div>
      </div>
    </Card>
  );

  const showStatus = (status) => {
    if (status === MATERIAL_PURCHASE_STATUS.PURCHASE.status_code) {
      return (
        <b className="text-yellow-500">
          {MATERIAL_PURCHASE_STATUS[status].description}
        </b>
      );
    }
    if (status === MATERIAL_PURCHASE_STATUS.SUCCESSFUL.status_code) {
      return (
        <b className="text-green-700">
          {MATERIAL_PURCHASE_STATUS[status].description}
        </b>
      );
    }
    if (status === MATERIAL_PURCHASE_STATUS.RECEIVED.status_code) {
      return (
        <b className="text-indigo-500">
          {MATERIAL_PURCHASE_STATUS[status].description}
        </b>
      );
    }
    if (status === MATERIAL_PURCHASE_STATUS.CANCEL.status_code) {
      return (
        <b className="text-red-500">
          {MATERIAL_PURCHASE_STATUS[status].description}
        </b>
      );
    }
  };

  const renderTable = () => (
    <div className="my-2">
      <Paper>
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }}>
            <colgroup>
              <col width="5%" />
              <col width="10%" />
              <col width="20%" />
              <col width="15%" />
              <col width="10%" />
              <col width="10%" />
              <col width="5%" />
              <col width="10%" />
            </colgroup>
            <TableHead>
              <TableRow>
                <TableCell>
                  <div className="font-bold">ลำดับที่</div>
                </TableCell>
                <TableCell>
                  <div className="font-bold"> วันที่</div>
                </TableCell>
                <TableCell>
                  <div className="font-bold"> คู่ค้า</div>
                </TableCell>
                <TableCell>
                  <div className="font-bold"> วัตถุดิบ</div>
                </TableCell>
                <TableCell>
                  <div className="font-bold"> ผู้จัดซื้อ</div>
                </TableCell>
                <TableCell>
                  <div className="font-bold"> สถานะ</div>
                </TableCell>
                <TableCell>
                  <div className="font-bold"> หมายเหตุ</div>
                </TableCell>
                <TableCell>
                  <div className="font-bold"> ดำเนินการ</div>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {!_.isEmpty(purchaseOrder?.rows) ? (
                purchaseOrder?.rows.map((row, index) => (
                  <TableRow
                    key={row.id}
                    sx={{
                      '&:last-child td, &:last-child th': { border: 0 },
                    }}
                  >
                    <TableCell component="th" scope="row">
                      <div className="text-xs">
                        {' '}
                        {(page - 1) * size + index + 1}
                      </div>
                    </TableCell>
                    <TableCell>
                      <div className="text-xs">
                        {dayjs(row?.createdAt)
                          .locale('th')
                          .format('DD MMM BBBB')}
                        <p>
                          เวลา{' '}
                          {new Date(row?.createdAt).toLocaleTimeString('th')} น.
                        </p>
                      </div>
                    </TableCell>
                    <TableCell>
                      <div className=" bg-gray-100 mb-2 p-3 rounded-md text-sm mr-2">
                        <div>
                          <b>ชื่อ :</b> {row?.supplier?.name || ' - '}
                        </div>
                        <div>
                          <b>อีเมล : </b>
                          {row?.supplier?.email || ' - '}
                        </div>
                        <div>
                          <b>โทร : </b>
                          {row?.supplier?.phone_number || ' - '}
                        </div>
                        <div>
                          <b>ที่อยู่ : </b>
                          {row?.supplier?.address || ' - '}
                        </div>
                      </div>
                    </TableCell>
                    <TableCell>
                      {_.size(row?.material) ? (
                        row?.material.map((_material, _index) => (
                          <div key={_index}>
                            <span>
                              {_index + 1}
                              {'. '}
                            </span>
                            <span className=" font-semibold font-display">
                              {_material?.material?.type_code}{' '}
                            </span>
                            <span>{_material?.material?.name} </span>
                            <span>
                              {currencyFormatterTH.format(
                                _material?.quantity,
                              ) || ' - '}{' '}
                              {_material?.material?.unit}
                            </span>
                          </div>
                        ))
                      ) : (
                        <div>{' - '}</div>
                      )}
                    </TableCell>
                    <TableCell>
                      {row?.purchaser ? (
                        <div>
                          <div>
                            {row?.purchaser?.firstname}{' '}
                            {row?.purchaser?.lastname}
                          </div>
                        </div>
                      ) : (
                        <div>-</div>
                      )}
                    </TableCell>
                    <TableCell>
                      <div className="py-1 bg-gray-100 rounded-md  text-center">
                        {row?.status ? showStatus(row?.status) : ' - '}
                      </div>
                    </TableCell>
                    <TableCell>
                      <div className="text-xs">
                        <p>{row?.remark ? row?.remark : ' '}</p>
                      </div>
                    </TableCell>
                    <TableCell>
                      {' '}
                      <div className="flex flex-row flex-wrap gap-1">
                        <div>
                          <Button
                            variant="contained"
                            color={'secondary'}
                            size={'small'}
                            onClick={() =>
                              handlePushToDetailPurchaseOrder(row._id)
                            }
                          >
                            รายละเอียด
                          </Button>
                        </div>
                        <div>
                          <Button
                            variant="contained"
                            color={'teal'}
                            size={'small'}
                            onClick={() => {
                              handlePrintPdf(row);
                            }}
                          >
                            พิมพ์
                          </Button>
                        </div>
                      </div>
                    </TableCell>
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell colSpan={6}>
                    <div className="text-center">ไม่มีข้อมูล</div>
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          component="div"
          rowsPerPageOptions={[]}
          onRowsPerPageChange={handleChangeRowsPerPage}
          page={page - 1}
          count={total || 1}
          rowsPerPage={size}
          onPageChange={handleChangePage}
        />
      </Paper>
    </div>
  );

  if (purchaseOrder?.isLoading) {
    return <Loading />;
  }
  if (!purchaseOrder?.isLoading && purchaseOrder?.isCompleted) {
    return (
      <div>
        <div className="flex justify-between">
          <div>{renderTitle()}</div>
        </div>
        {renderSearch()}
        {renderTable()}
      </div>
    );
  }
  return <Error message={purchaseOrder?.message} />;
}

PurchaseOrder.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
};

PurchaseOrder.defaultProps = {
  title: '',
  subtitle: '',
};
