import React from 'react';
import { Controller } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { PRODUCT_TRANSACTION_TYPE } from '@iarcpsu/emanufac-constant';
import {
  Autocomplete,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from '@mui/material';
import dayjs from 'dayjs';
import _ from 'lodash';
import PropTypes from 'prop-types';

import { useQuery } from '@/utils/functions';

import { DesktopDatepicker } from '../../DatePicker';

export function ProductStockForm({
  errors,
  control,
  warehouse,
  productTransactionType,
  customer,
  me,
  type,
  setFilterWarehouse,
  paymentType,
  shippingType,
}) {
  const { id } = useParams();
  const query = useQuery();
  const information = useSelector((state) => state.information);
  const selectedTxnType = _.find(
    productTransactionType,
    (each) => each?._id === query.get('txnType'),
  );

  const wmsSetting = information?.setting?.wms;

  const checkWarehouse = (data) => {
    const findWarehouse = _.find(warehouse, { id: data });
    setFilterWarehouse(findWarehouse);
  };

  const productOptions = () => {
    if (type?.direction === PRODUCT_TRANSACTION_TYPE.add.status_code) {
      return (
        <div className="w-full flex flex-row flex-wrap">
          <div className="w-full px-1 py-2">
            {!_.isEmpty(customer) && (
              <Controller
                name={'supplier'}
                control={control}
                rules={{ required: false }}
                defaultValue={customer?.[0]}
                render={({ field }) => (
                  <Autocomplete
                    size={'small'}
                    disableClearable
                    {...field}
                    onChange={(e, newValue) => {
                      field.onChange(newValue);
                    }}
                    options={customer}
                    getOptionLabel={(option) =>
                      `${option?.type_code || ''} | ${option?.name}`
                    }
                    renderInput={(params) => (
                      <TextField {...params} label="คู่ค้า" />
                    )}
                  />
                )}
              />
            )}
          </div>
          <div className="w-full px-1 my-2">
            <Controller
              name={'inventory_date'}
              control={control}
              defaultValue={dayjs().format('YYYY-MM-DD')}
              rules={{ required: true }}
              render={({ field }) => (
                <DesktopDatepicker
                  {...field}
                  label="วันที่เข้าคลัง *"
                  fullWidth
                  size="small"
                />
              )}
            />
          </div>
          <div className="w-full px-1 py-2">
            <Controller
              name={'consignee_name'}
              control={control}
              rules={{ required: true }}
              defaultValue={me ? `${me?.firstname} ${me?.lastname}` : ''}
              render={({ field }) => (
                <TextField
                  {...field}
                  label="ชื่อผู้เพิ่มสินค้า"
                  fullWidth
                  required
                  size={'small'}
                  helperText={errors.consignee_name && 'กรุณาใส่ข้อมูล'}
                />
              )}
            />
          </div>
          <div className="w-full lg:w-full px-1 py-2">
            <Controller
              name={'remark'}
              control={control}
              defaultValue={''}
              rules={{ required: false }}
              render={({ field }) => (
                <TextField
                  {...field}
                  label="หมายเหตุ"
                  fullWidth
                  size={'small'}
                  multiline={true}
                  rows={3}
                  helperText={errors.remark && 'กรุณาใส่ข้อมูล'}
                />
              )}
            />
          </div>
        </div>
      );
    }
    if (type?.direction === PRODUCT_TRANSACTION_TYPE.desc.status_code) {
      return (
        <div className="w-full flex flex-row flex-wrap">
          <div className="w-full px-1 py-2">
            {!_.isEmpty(customer) && (
              <Controller
                name={'customer'}
                control={control}
                defaultValue={customer?.[0]}
                render={({ field }) => (
                  <Autocomplete
                    size={'small'}
                    disableClearable
                    {...field}
                    onChange={(e, newValue) => {
                      field.onChange(newValue);
                    }}
                    options={customer}
                    getOptionLabel={(option) =>
                      `${option?.type_code || ''} | ${option?.name}`
                    }
                    renderInput={(params) => (
                      <TextField {...params} label="ลูกค้า" />
                    )}
                  />
                )}
              />
            )}
          </div>
          {wmsSetting?.display?.payment && (
            <div className="w-full px-1 py-2">
              <Controller
                name={'payment'}
                control={control}
                rules={{ required: false }}
                render={({ field }) => (
                  <FormControl sx={{ minWidth: 120 }} fullWidth={true}>
                    <InputLabel id="type" size={'small'}>
                      ประเภทการชำระเงิน
                    </InputLabel>
                    <Select
                      {...field}
                      label="ประเภทการชำระเงิน"
                      size={'small'}
                      fullWidth
                    >
                      {_.size(paymentType) ? (
                        _.map(paymentType, (_paymentType) => (
                          <MenuItem
                            key={_paymentType.id}
                            value={_paymentType.id}
                          >
                            {_paymentType.name}
                          </MenuItem>
                        ))
                      ) : (
                        <MenuItem>
                          <MenuItem disabled value="ไม่มีข้อมูล">
                            <em>ไม่มีข้อมูล</em>
                          </MenuItem>
                        </MenuItem>
                      )}
                    </Select>
                  </FormControl>
                )}
              />
            </div>
          )}
          <div className="w-full px-1 my-2 ">
            <Controller
              name={'issue_date'}
              control={control}
              defaultValue={dayjs().format('YYYY-MM-DD')}
              rules={{ required: true }}
              render={({ field }) => (
                <DesktopDatepicker
                  {...field}
                  label="วันที่เบิก *"
                  fullWidth
                  size="small"
                />
              )}
            />
          </div>
          {wmsSetting?.display?.sale_date && (
            <div className="w-full px-1 mb-2 ">
              <Controller
                name={'sale_date'}
                control={control}
                defaultValue={new Date()}
                rules={{ required: true }}
                render={({ field }) => (
                  <DesktopDatepicker
                    {...field}
                    label="วันที่ขาย *"
                    fullWidth
                    size="small"
                  />
                )}
              />
            </div>
          )}

          {wmsSetting?.display?.expiration_date && (
            <div className="w-full px-1 py-2">
              <Controller
                name={'shipping'}
                control={control}
                rules={{ required: false }}
                render={({ field }) => (
                  <FormControl sx={{ minWidth: 120 }} fullWidth={true}>
                    <InputLabel id="type" size={'small'}>
                      ประเภทการจัดส่ง
                    </InputLabel>
                    <Select
                      {...field}
                      label="ประเภทการจัดส่ง"
                      size={'small'}
                      fullWidth
                    >
                      {_.size(shippingType) ? (
                        _.map(shippingType, (_shippingType) => (
                          <MenuItem
                            key={_shippingType.id}
                            value={_shippingType.id}
                          >
                            {_shippingType.name}
                          </MenuItem>
                        ))
                      ) : (
                        <MenuItem>
                          <MenuItem disabled value="ไม่มีข้อมูล">
                            <em>ไม่มีข้อมูล</em>
                          </MenuItem>
                        </MenuItem>
                      )}
                    </Select>
                  </FormControl>
                )}
              />
            </div>
          )}
          {wmsSetting?.display?.tracking_number && (
            <div className="w-full lg:w-1/2 px-1 py-2">
              <Controller
                name={'tracking_number'}
                control={control}
                rules={{ required: false }}
                render={({ field }) => (
                  <TextField
                    {...field}
                    label="หมายเลขติดตามสินค้า"
                    fullWidth
                    size={'small'}
                    helperText={errors.tracking_number && 'กรุณาใส่ข้อมูล'}
                  />
                )}
              />
            </div>
          )}
          {wmsSetting?.display?.shipping_cost && (
            <div className="w-full lg:w-1/2 px-1 py-2">
              <Controller
                name={'shipping_cost'}
                control={control}
                rules={{ required: false }}
                render={({ field }) => (
                  <TextField
                    {...field}
                    label="ค่าจัดส่ง"
                    fullWidth
                    size={'small'}
                    helperText={errors.shipping_cost && 'กรุณาใส่ข้อมูล'}
                  />
                )}
              />
            </div>
          )}
          <div className="w-full px-1 py-2">
            <Controller
              name={'seller'}
              control={control}
              rules={{ required: true }}
              defaultValue={me ? `${me?.firstname} ${me?.lastname}` : ''}
              render={({ field }) => (
                <TextField
                  {...field}
                  label="ชื่อพนักงานขาย / พนักงานเบิก*"
                  fullWidth
                  size={'small'}
                  helperText={errors.seller && 'กรุณาใส่ข้อมูล'}
                />
              )}
            />
          </div>
          <div className="w-full lg:w-full px-1 py-2">
            <Controller
              name={'remark'}
              control={control}
              defaultValue={''}
              rules={{ required: false }}
              render={({ field }) => (
                <TextField
                  {...field}
                  label="หมายเหตุ"
                  fullWidth
                  size={'small'}
                  multiline={true}
                  rows={3}
                  helperText={errors.remark && 'กรุณาใส่ข้อมูล'}
                />
              )}
            />
          </div>
        </div>
      );
    }

    return <div></div>;
  };

  return (
    <div className="flex flex-row flex-wrap">
      <div className="w-full lg:w-1/2">
        <div className="w-full px-1 py-2">
          <Controller
            name={'warehouse'}
            control={control}
            defaultValue={id}
            rules={{ required: true }}
            render={({ field }) => (
              <FormControl sx={{ minWidth: 120 }} fullWidth={true}>
                <InputLabel id="type" size={'small'}>
                  คลัง
                </InputLabel>
                <Select
                  {...field}
                  label="คลัง *"
                  size={'small'}
                  fullWidth
                  onChange={(e) => {
                    field.onChange(e.target.value);
                    checkWarehouse(e.target.value);
                  }}
                >
                  {_.size(warehouse) ? (
                    _.map(warehouse, (_warehouse) => (
                      <MenuItem key={_warehouse.id} value={_warehouse.id}>
                        {_warehouse.name}
                      </MenuItem>
                    ))
                  ) : (
                    <MenuItem>
                      <MenuItem disabled value="">
                        <em>ไม่มีข้อมูล</em>
                      </MenuItem>
                    </MenuItem>
                  )}
                </Select>
              </FormControl>
            )}
          />
        </div>
        <div className="w-full px-1 py-2">
          {selectedTxnType ? (
            <div>
              <div
                className={`text-2xl font-bold ${
                  selectedTxnType?.direction === 'add'
                    ? 'text-green-500'
                    : 'text-red-500'
                }`}
              >
                {selectedTxnType?.name}
              </div>
            </div>
          ) : (
            <Controller
              name={'product_transaction_type'}
              control={control}
              rules={{ required: true }}
              defaultValue={productTransactionType?.[0]?._id}
              render={({ field }) => (
                <FormControl sx={{ minWidth: 120 }} fullWidth={true} required>
                  <InputLabel id="type" size={'small'}>
                    ประเภทการดำเนินการ
                  </InputLabel>
                  <Select
                    {...field}
                    label="ประเภทการดำเนินการ"
                    size={'small'}
                    fullWidth
                    onChange={(e) => {
                      field.onChange(e.target.value);
                    }}
                  >
                    {_.size(productTransactionType) ? (
                      _.map(
                        productTransactionType,
                        (_materialTransactionType) => (
                          <MenuItem
                            key={_materialTransactionType.id}
                            value={_materialTransactionType.id}
                          >
                            {_materialTransactionType.name}
                          </MenuItem>
                        ),
                      )
                    ) : (
                      <MenuItem>
                        <MenuItem disabled value="">
                          <em>ไม่มีข้อมูล</em>
                        </MenuItem>
                      </MenuItem>
                    )}
                  </Select>
                </FormControl>
              )}
            />
          )}
        </div>
      </div>
      <div className="w-full lg:w-1/2"> {productOptions()}</div>
    </div>
  );
}

ProductStockForm.propTypes = {
  errors: PropTypes.shape({
    product_transaction_type: PropTypes.object,
    // production_number: PropTypes.object,
    supplier: PropTypes.object,
    customer: PropTypes.object,
    payment: PropTypes.object,
    send_type: PropTypes.object,
    warehouse: PropTypes.object,
    inventory_date: PropTypes.object,
    issue_date: PropTypes.object,
    sale_date: PropTypes.object,
    remark: PropTypes.object,
    consignee_name: PropTypes.object,
    seller: PropTypes.object,
    price: PropTypes.object,
    discount: PropTypes.object,
    tracking_number: PropTypes.object,
    shipping_cost: PropTypes.object,
  }),
  productTransactionType: PropTypes.object,
  warehouse: PropTypes.object,
  customer: PropTypes.object,
  me: PropTypes.object,
  paymentType: PropTypes.object,
  shippingType: PropTypes.object,
  type: PropTypes.object,
  setType: PropTypes.func,
  setFilterWarehouse: PropTypes.func,
  setSelectProduct: PropTypes.func,
  setSelectProductLot: PropTypes.func,
  control: PropTypes.object,
};

ProductStockForm.defaultProps = {
  productTransactionType: null,
  warehouse: null,
  customer: null,
  me: null,
  paymentType: null,
  shippingType: null,
  type: null,
  filterWarehouse: null,
};

export default ProductStockForm;
