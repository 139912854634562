import React from 'react';
import { Controller } from 'react-hook-form';
import { Card, TextField } from '@mui/material';
import PropTypes from 'prop-types';

const PAMSSettingForm = ({ control, pamsSetting }) => (
  <Card className="p-4">
    <div className="flex flex-wrap w-full ">
      <div className="w-full py-2 px-2 font-semibold font-display ">
        ตั้งค่าทั่วไป
      </div>

      <div className="w-1/2 py-2 px-2  ">รอบการจ่ายเงินเดือน จ่ายทุกวันที่</div>
      <div className="w-1/2 py-2 px-2">
        <Controller
          control={control}
          name="setting.pams.period_date"
          defaultValue={pamsSetting?.period_date}
          render={({ field }) => (
            <TextField {...field} label="จ่ายทุกวันที่" size="small" />
          )}
        />
      </div>
      <div className="w-1/2 py-2 px-2  ">เงินที่จ่ายได้สูงสุดต่อเดือน</div>
      <div className="w-1/2 py-2 px-2">
        <Controller
          control={control}
          name="setting.pams.monthly_margin"
          defaultValue={pamsSetting?.monthly_margin}
          render={({ field }) => (
            <TextField {...field} label="เงินสูงสุดที่จ่ายได้" size="small" />
          )}
        />
      </div>
      <div className="w-full py-2 px-2 font-semibold font-display ">
        ค่าตอบแทนรายวัน
      </div>
      <div className="w-1/2 py-2 px-2  ">ชั่วโมงทำงาน / วัน</div>
      <div className="w-1/2 py-2 px-2">
        <Controller
          control={control}
          name="setting.pams.daily_payroll.working_hour"
          defaultValue={pamsSetting?.daily_payroll?.working_hour}
          render={({ field }) => (
            <div className="flex gap-2">
              <TextField {...field} label="จำนวนชั่วโมง / วัน" size="small" />
              <div className="self-center">ชั่วโมง</div>
            </div>
          )}
        />
      </div>
      <div className="w-1/2 py-2 px-2">ชั่วโมงพัก/วัน</div>
      <div className="w-1/2 py-2 px-2">
        <Controller
          control={control}
          name="setting.pams.daily_payroll.break_hour"
          defaultValue={pamsSetting?.daily_payroll?.break_hour}
          render={({ field }) => (
            <div className="flex gap-2">
              <TextField
                {...field}
                label="จำนวนชั่วโมงพัก / วัน"
                size="small"
              />
              <div className="self-center">ชั่วโมง</div>
            </div>
          )}
        />
      </div>
      <div className="w-1/2 py-2 px-2  ">จะเข้าง่านสายต่อเมื่อผ่านไป</div>
      <div className="w-1/2 py-2 px-2">
        <Controller
          control={control}
          name="setting.pams.daily_payroll.late_threshold"
          defaultValue={pamsSetting?.daily_payroll?.late_threshold}
          render={({ field }) => (
            <div className="flex gap-2">
              <TextField {...field} label="เวลาที่ถือว่าสาย" size="small" />
              <div className="self-center">นาที</div>
            </div>
          )}
        />
      </div>
      <div className="w-1/2 py-2 px-2  ">จะถือว่าขาดงานเมื่อผ่านไป</div>
      <div className="w-1/2 py-2 px-2">
        <Controller
          control={control}
          name="setting.pams.daily_payroll.brushoff_threshold"
          defaultValue={pamsSetting?.daily_payroll?.brushoff_threshold}
          render={({ field }) => (
            <div className="flex gap-2">
              <TextField {...field} label="เวลาที่ถือว่าขาดงาน" size="small" />
              <div className="self-center">นาที</div>
            </div>
          )}
        />
      </div>
      <div className="w-1/2 py-2 px-2  ">
        จำนวนเท่าของค่าจ้างนอกเวลา ต่อค่าจ้างในเวลา
      </div>
      <div className="w-1/2 py-2 px-2">
        <Controller
          control={control}
          name="setting.pams.daily_payroll.overtime_rate"
          defaultValue={pamsSetting?.daily_payroll?.overtime_rate}
          render={({ field }) => (
            <div className="flex gap-2">
              <TextField {...field} label="จำนวนเท่าของค่าจ้าง" size="small" />
              <div className="self-center">เท่า</div>
            </div>
          )}
        />
      </div>
    </div>
  </Card>
);

PAMSSettingForm.propTypes = {
  control: PropTypes.object,
  pamsSetting: PropTypes.object,
};

export default PAMSSettingForm;
