import api from '../../../configs/api';
import graphqlClient from '../../../configs/graphqlClient';
import {
  MATERIAL_ALL,
  MATERIAL_DEL,
  MATERIAL_ERROR,
  MATERIAL_GET,
  MATERIAL_LOADING,
  MATERIAL_POST,
  MATERIAL_PUT,
} from '../types';

export const materialCreate = (payload) => async (dispatch) => {
  try {
    dispatch({ type: MATERIAL_LOADING });
    const { data } = await api.post(
      `${process.env.REACT_APP_API_URL}/material`,
      {
        ...payload,
      },
    );
    dispatch({ type: MATERIAL_POST, payload: data });
  } catch (error) {
    console.error(error);
    dispatch({ type: MATERIAL_ERROR });
    throw new Error(error);
  }
};

export const materialAll = ({
  name = '',
  size = 2000,
  page = 1,
  stockStatus = '',
  place = '',
  statusCode = '',
  selectMaterialType = '',
  startDate = '',
  endDate = '',
  fetchStockLot = false,
  fetchProcess = false,
  forceWithoutCache = '',
  wipOnly = '',
  placeRestriction = '',
  placeRestricNotify = '',
}) => async (dispatch) => {
  try {
    const { data, status } = await api.get(
      `${
        process.env.REACT_APP_API_URL
      }/material?name=${name}&size=${size}&page=${page}&status=${stockStatus}&place=${place}&statusCode=${statusCode}&selectMaterialType=${selectMaterialType}&startDate=${startDate}&endDate=${endDate}&fetchStockLot=${
        fetchStockLot || ''
      }&fetchProcess=${
        fetchProcess || ''
      }&forceWithoutCache=${forceWithoutCache}&wipOnly=${wipOnly}&placeRestriction=${placeRestriction}&placeRestricNotify=${placeRestricNotify}`,
    );
    if (status === 200) {
      dispatch({ type: MATERIAL_ALL, payload: data });
    }
  } catch (error) {
    dispatch({ type: MATERIAL_ERROR });
    throw new Error(error);
  }
};

export const materialAllQuery = ({ query }) => async (dispatch) => {
  try {
    const queryResult = await graphqlClient.request(query);
    const data = queryResult?.findMaterials;
    //  console.log('Query Data', queryResult);
    if (!data) {
      dispatch({ type: MATERIAL_ERROR });
    }

    dispatch({ type: MATERIAL_ALL, payload: data });
  } catch (error) {
    console.error(error);
    dispatch({ type: MATERIAL_ERROR });
    throw new Error(error);
  }
};

export const materialStateSet = (data) => async (dispatch) => {
  dispatch({ type: MATERIAL_ALL, payload: data });
};

export const materialStateOneSet = (data) => async (dispatch) => {
  dispatch({ type: MATERIAL_GET, payload: data });
};

export const materialReset = () => async (dispatch) => {
  dispatch({ type: MATERIAL_LOADING });
};

export const materialError = () => async (dispatch) => {
  dispatch({ type: MATERIAL_ERROR });
};

export const materialGet = (
  id,
  {
    placeId = '',
    startDate = '',
    endDate = '',
    fetchStockLot = '',
    fetchProcess = '',
    stockStatus = 'IN_STOCK',
  },
) => async (dispatch) => {
  try {
    const { data, status } = await api.get(
      `${process.env.REACT_APP_API_URL}/material/${id}?place=${placeId}&startDate=${startDate}&endDate=${endDate}&fetchStockLot=${fetchStockLot}&fetchProcess=${fetchProcess}&status=${stockStatus}`,
    );

    if (status === 200) {
      dispatch({ type: MATERIAL_GET, payload: data });
    }
  } catch (error) {
    console.error(error);
    dispatch({ type: MATERIAL_ERROR });
    throw new Error(error);
  }
};

export const materialPut = (id, payload) => async (dispatch) => {
  try {
    dispatch({ type: MATERIAL_LOADING });
    const { data } = await api.put(
      `${process.env.REACT_APP_API_URL}/material/${id}`,
      payload,
    );
    dispatch({ type: MATERIAL_PUT, payload: data });
  } catch (error) {
    console.error(error);
    dispatch({ type: MATERIAL_ERROR });
    throw new Error(error);
  }
};
export const materialDelete = (id) => async (dispatch) => {
  try {
    dispatch({ type: MATERIAL_LOADING });
    const { data } = await api.delete(
      `${process.env.REACT_APP_API_URL}/material/${id}`,
    );
    dispatch({ type: MATERIAL_DEL, payload: data });
  } catch (error) {
    console.error(error);
    dispatch({ type: MATERIAL_ERROR });
    throw new Error(error);
  }
};

export const matrialReset = () => async (dispatch) => {
  try {
    dispatch({ type: MATERIAL_LOADING });
  } catch (error) {
    console.error(error);
    dispatch({ type: MATERIAL_ERROR });
    throw new Error(error);
  }
};
