import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import {
  Button,
  Card,
  FormControl,
  FormControlLabel,
  FormLabel,
  InputAdornment,
  Paper,
  Radio,
  RadioGroup,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
} from '@mui/material';
import dayjs from 'dayjs';
import { gql } from 'graphql-request';
import _ from 'lodash';
import PropTypes from 'prop-types';

import {
  DesktopDatepicker,
  Error,
  ExportExcelContainer,
  Loading,
  ReportMaterialUsageTableRowElement,
  ViewTitle,
} from '@iarcpsu/emanufac-components/src/components';
import graphqlClient from '@/configs/graphqlClient';
import * as actions from '@/redux/actions';
import { TIMING } from '@/utils/constants';
import { MMS } from '@/utils/functions';
import { ReportMaterialUsageUtil } from '@/utils/modelutils';

/**
 * @function ReportMaterialUsage
 * @description Display a collections or a list of ReportMaterialUsage from database
 */

export default function ReportMaterialUsage({ title, subtitle }) {
  const dispatch = useDispatch();
  const material = useSelector((state) => state.material);
  const [page, setPage] = useState(1);
  const [size, setSize] = useState(10);
  const [total, setTotal] = useState();
  const [searchTerm, setSearchTerm] = useState('');
  const [name, setName] = useState('');
  const [startDate, setStartDate] = useState(
    dayjs().subtract(1, 'month').format('YYYY-MM-DD'),
  );
  const [endDate, setEndDate] = useState(
    dayjs().add(1, 'day').format('YYYY-MM-DD'),
  );
  const [useTimePeriod, setUseTimePeriod] = useState();
  const [selectedTimingMode, setSelectedTimingMode] = useState(
    TIMING.DAILY.status_code,
  );
  const [selectedPointDate, setSelectedPointDate] = useState();
  const [dataList, setDataList] = useState([]);
  const { materialReportDataListGenerator } = MMS;
  const { columns: reportMaterialUsageColumn } = ReportMaterialUsageUtil;

  const query = gql`
    query FindReportMaterialUsage($materialinput: MaterialInput) {
      findMaterials(input: $materialinput) {
        currPage
        lastPage
        total
        rows {
          _id
          name
          type_code
          use_unit_conversion
          unit
          conversion_from {
            unit_a
            unit_b
            conversion_factor
          }
          conversion_to {
            unit_b
            unit_a
            conversion_factor
          }
          unit_input {
            _id
            short_sign
            name
          }
          unit_output {
            _id
            short_sign
            name
          }
        }
      }
    }
  `;

  const queryDataFromServer = async () => {
    try {
      const queryResult = await graphqlClient.request(query, {
        materialinput: {
          page,
          size,
          fetchStockLot: false,
          fetchProcess: false,
          name,
        },
      });
      const materialData = queryResult?.findMaterials;
      dispatch(actions.materialStateSet(materialData));
    } catch (error) {
      dispatch(actions.materialError());
    }
  };

  const handleChangePage = (e, newPage) => {
    setPage(newPage + 1);
  };

  const handleChangeRowPerPage = (e) => {
    setSize(e?.target?.value);
    setPage(1);
  };

  useEffect(() => {
    queryDataFromServer();
    return () => {};
  }, [page, size, name]);

  useEffect(() => {
    if (material?.rows) {
      setTotal(material?.total);
    }

    return () => {};
  }, [material]);

  useEffect(() => {
    dispatch(actions.materialReset());

    return () => {};
  }, []);

  const adjustTimeOnPeriod = () => {
    if (selectedTimingMode === TIMING.DAILY.status_code) {
      const startPoint = dayjs(selectedPointDate)
        .startOf('date')
        .format('YYYY-MM-DDTHH:mm');
      const endPoint = dayjs(selectedPointDate)
        .endOf('date')
        .format('YYYY-MM-DDTHH:mm');
      setStartDate(startPoint);
      setEndDate(endPoint);
    }
    if (selectedTimingMode === TIMING.MONTHY.status_code) {
      const startPoint = dayjs(selectedPointDate)
        .startOf('month')
        .format('YYYY-MM-DDTHH:mm');
      const endPoint = dayjs(selectedPointDate)
        .endOf('month')
        .format('YYYY-MM-DDTHH:mm');
      setStartDate(startPoint);
      setEndDate(endPoint);
    }
    if (selectedTimingMode === TIMING.YEARLY.status_code) {
      const startPoint = dayjs(selectedPointDate)
        .startOf('year')
        .format('YYYY-MM-DDTHH:mm');
      const endPoint = dayjs(selectedPointDate)
        .endOf('year')
        .format('YYYY-MM-DDTHH:mm');
      setStartDate(startPoint);
      setEndDate(endPoint);
    }
  };

  useEffect(() => {
    if (useTimePeriod) {
      adjustTimeOnPeriod();
    }

    return () => {};
  }, [useTimePeriod, selectedTimingMode, selectedPointDate]);

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      setName(searchTerm);
      setPage(1);
    }, 500);
    return () => clearTimeout(delayDebounceFn);
  }, [searchTerm]);

  const renderTitle = () => <ViewTitle title={title} subtitle={subtitle} />;

  if (material.isLoading) {
    return <Loading />;
  }
  if (!material.isLoading && material.isCompleted) {
    return (
      <div>
        {renderTitle()}
        <div className="my-2">
          <Card>
            <div className="p-4 flex flex-row">
              <div className="w-full md:w-1/3 pr-4">
                <TextField
                  label="ค้นหา"
                  fullWidth
                  size="small"
                  id="outlined-start-adornment"
                  onChange={(e) => {
                    setSearchTerm(e.target.value);
                  }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <i className="fas fa-search"></i>
                      </InputAdornment>
                    ),
                  }}
                />
              </div>
              <div className="w-full md:w-1/4 flex px-1 gap-2">
                <div className="self-center">เลือกวันเริ่มต้น/สิ้นสุด</div>
                <FormControlLabel
                  label="เลือกเป็นช่วงเวลา"
                  control={
                    <Switch
                      checked={useTimePeriod}
                      onChange={(e) => setUseTimePeriod(e.target.checked)}
                    />
                  }
                />
              </div>
              {!useTimePeriod && (
                <div className="w-full md:w-1/6 px-1">
                  <DesktopDatepicker
                    fullWidth
                    label="จากวันที่"
                    onChange={setStartDate}
                    value={startDate}
                    size="small"
                  />
                </div>
              )}

              {!useTimePeriod && (
                <div className="w-full md:w-1/6 px-1">
                  <DesktopDatepicker
                    fullWidth
                    label="ถึงวันที่"
                    onChange={setEndDate}
                    value={endDate}
                    size="small"
                  />
                </div>
              )}

              {useTimePeriod && (
                <div className="w-full md:w-1/4 px-1">
                  <FormControl>
                    <FormLabel id="demo-row-radio-buttons-group-label">
                      เลือกช่วงเวลา
                    </FormLabel>
                    <RadioGroup
                      row
                      aria-labelledby="demo-row-radio-buttons-group-label"
                      name="row-radio-buttons-group"
                      value={selectedTimingMode}
                      onChange={(e) => setSelectedTimingMode(e.target.value)}
                    >
                      {_.map(TIMING, (eachTime, index) => (
                        <FormControlLabel
                          value={eachTime.status_code}
                          control={<Radio />}
                          label={eachTime.description}
                          key={index}
                        />
                      ))}
                    </RadioGroup>
                  </FormControl>
                </div>
              )}

              {useTimePeriod &&
                selectedTimingMode === TIMING.DAILY.status_code && (
                  <div className="w-full md:w-1/6 px-1">
                    <DesktopDatepicker
                      fullWidth
                      views={['day', 'month']}
                      label="เลือกวันที่"
                      size="small"
                      onChange={setSelectedPointDate}
                      value={selectedPointDate}
                      inputFormat="DD MMM BBBB"
                    />
                  </div>
                )}
              {useTimePeriod &&
                selectedTimingMode === TIMING.MONTHY.status_code && (
                  <div className="w-full md:w-1/6 px-1">
                    <DesktopDatepicker
                      fullWidth
                      views={['year', 'month']}
                      label="เลือกเดือน"
                      size="small"
                      onChange={setSelectedPointDate}
                      value={selectedPointDate}
                      inputFormat="MMM BBBB"
                    />
                  </div>
                )}
              {useTimePeriod &&
                selectedTimingMode === TIMING.YEARLY.status_code && (
                  <div className="w-full md:w-1/6 px-1">
                    <DesktopDatepicker
                      fullWidth
                      views={['year']}
                      label="เลือกปี"
                      size="small"
                      onChange={setSelectedPointDate}
                      value={selectedPointDate}
                      inputFormat="BBBB"
                    />
                  </div>
                )}

              <ExportExcelContainer
                buttonSize="small"
                columnList={reportMaterialUsageColumn}
                currentData={() => materialReportDataListGenerator(dataList)}
                disableAllExport
                sheetName="MaterialReport"
              />
            </div>
          </Card>
        </div>
        <div className="my-2">
          <Paper>
            <TableContainer component={Paper}>
              <Table sx={{ minWidth: 650 }}>
                <colgroup>
                  <col width="10%" />
                  <col width="25%" />
                  <col width="50%" />
                  <col width="15%" />
                </colgroup>
                <TableHead>
                  <TableRow>
                    <TableCell>
                      <div className="font-bold">ลำดับที่</div>
                    </TableCell>
                    <TableCell>
                      <div className="font-bold"> วัตถุดิบ</div>
                    </TableCell>
                    <TableCell>
                      <div className="font-bold">ปริมาณที่ใช้ไป</div>
                    </TableCell>
                    <TableCell>
                      <div className="font-bold">ดำเนินการ</div>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {!_.isEmpty(material?.rows) ? (
                    _.map(material?.rows, (row, index) => (
                      <TableRow
                        key={index}
                        sx={{
                          '&:last-child td, &:last-child th': { border: 0 },
                        }}
                      >
                        <TableCell component="th" scope="row">
                          <div className="text-xs">
                            {(page - 1) * size + index + 1}
                          </div>
                        </TableCell>
                        <TableCell>
                          <span className="font-semibold">
                            {row?.type_code || ''}
                          </span>{' '}
                          {row?.name}{' '}
                        </TableCell>
                        <TableCell>
                          <ReportMaterialUsageTableRowElement
                            material={row}
                            endDate={endDate}
                            startDate={startDate}
                            allDataList={dataList}
                            index={index}
                            setAllDataList={setDataList}
                          />
                        </TableCell>
                        <TableCell>
                          <Link
                            to={`material-report/${row?._id}?start=${dayjs(
                              startDate,
                            ).format('YYYY-MM-DD')}&end=${dayjs(endDate).format(
                              'YYYY-MM-DD',
                            )}`}
                          >
                            <Button
                              size="small"
                              color="info"
                              variant="contained"
                            >
                              รายละเอียด
                            </Button>
                          </Link>
                        </TableCell>
                      </TableRow>
                    ))
                  ) : (
                    <TableRow>
                      <TableCell colSpan={6}>
                        <div className="text-center">ไม่มีข้อมูล</div>
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              component="div"
              page={page - 1}
              count={total || 1}
              rowsPerPage={size}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowPerPage}
              rowsPerPageOptions={[10, 25, 50, 100, 1000]}
            />
          </Paper>
        </div>
      </div>
    );
  }
  return <Error />;
}

ReportMaterialUsage.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
};

ReportMaterialUsage.defaultProps = {
  title: '',
  subtitle: '',
};
