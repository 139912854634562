import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Card, CardContent } from '@mui/material';
import _ from 'lodash';
import PropTypes from 'prop-types';

import {
  BackButton,
  Error,
  Loading,
  MaterialTransactionTable,
  MultiUnitProdOrMatBox,
  PieChart,
  ViewTitle,
} from '@iarcpsu/emanufac-components/src/components';
import * as actions from '@/redux/actions';

/**
 * @function DetailWithdrawDestination
 * @description Display a collections or a list of DetailWithdrawDestination from database
 */

export default function DetailWithdrawDestination({ title, subtitle }) {
  const dispatch = useDispatch();
  const withdrawDestination = useSelector((state) => state.withdrawDestination);
  const materialTransaction = useSelector((state) => state.materialTransaction);
  const params = useParams();
  const [page, setPage] = useState(1);
  const [size, setSize] = useState(10);
  const [total, setTotal] = useState();

  const queryDataFromServer = async () => {
    try {
      dispatch(
        actions.materialTransactionAll({
          page,
          size,
          withdrawDestination: params.id,
        }),
      );
      dispatch(actions.withdrawDestinationGet(params.id));
    } catch (error) {
      dispatch(actions.withdrawDestinationError());
    }
  };

  useEffect(() => {
    queryDataFromServer();
    return () => {};
  }, [params]);

  useEffect(() => {
    setTotal(materialTransaction?.total);
    return () => {};
  }, [materialTransaction]);

  const renderTitle = () => (
    <ViewTitle
      title={`${title} ${withdrawDestination?.name || ''}`}
      subtitle={subtitle}
    />
  );

  const renderSummary = () => (
    <div className="my-2">
      <Card>
        <CardContent>
          <div className="font-display">รายการการใช้วัตถุดิบ</div>
          <div className="flex flex-wrap">
            <div className="w-full lg:w-1/2">
              {_.map(
                materialTransaction?.outAnalysis,
                (eachTransactionType, index) => (
                  <div key={index}>
                    <PieChart
                      labels={_.map(
                        eachTransactionType?.summary,
                        (each) => each?.material?.name,
                      )}
                      series={_.map(
                        eachTransactionType?.summary,
                        (each) => each?.quantity,
                      )}
                      height={300}
                    />
                  </div>
                ),
              )}
            </div>
            <div className="w-full lg:w-1/2">
              {_.map(
                materialTransaction?.outAnalysis,
                (eachType, typeIndex) => (
                  <div key={`type-${typeIndex}`}>
                    <div className="text-lg font-semibold">
                      {eachType?._id?.name}
                    </div>
                    {_.map(eachType?.summary, (eachOut, index) => (
                      <div key={index} className="my-2 flex">
                        <div className="w-2/3">
                          <div className="text-sm text-gray-500">
                            หมวดหมู่ {eachOut?.material?.material_type?.name}
                          </div>
                          <div className="text-xl font-semibold font-display my-2">
                            <span className="bg-gray-200 p-1 rounded-md my-1 mr-2">
                              {eachOut?.material?.type_code}
                            </span>
                            {eachOut?.material?.name}
                          </div>
                        </div>
                        <div className="w-1/3 self-center text-lg">
                          <MultiUnitProdOrMatBox
                            foundMaterial={eachOut?.material}
                            quantity={eachOut?.quantity}
                          />
                        </div>
                      </div>
                    ))}
                  </div>
                ),
              )}
            </div>
          </div>
        </CardContent>
      </Card>
    </div>
  );

  const renderTable = () => (
    <div>
      <MaterialTransactionTable
        page={page}
        setPage={setPage}
        size={size}
        setSize={setSize}
        total={total}
        transaction={materialTransaction}
      />
    </div>
  );

  if (materialTransaction.isLoading) {
    return <Loading />;
  }

  if (!materialTransaction.isLoading && materialTransaction.isCompleted) {
    return (
      <div>
        {renderTitle()}
        <div>
          <BackButton />
        </div>
        <div>{renderSummary()}</div>
        <div>{renderTable()}</div>
      </div>
    );
  }
  return <Error />;
}

DetailWithdrawDestination.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
};

DetailWithdrawDestination.defaultProps = {
  title: '',
  subtitle: '',
};
