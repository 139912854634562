import React, { useEffect, useState } from 'react';
import { Avatar, Card, CardContent } from '@mui/material';
import _ from 'lodash';
import PropTypes from 'prop-types';

const EmployeeWorkLoadCard = ({ step, employee, periodText, stepLog }) => {
  const [workLoad, setWorkLoad] = useState(null);
  const allSteps = [...(stepLog?.rows || []), ...(step?.rows || [])];

  useEffect(() => {
    if (allSteps) {
      const workByEmployee = allSteps.reduce((result, currentValue) => {
        (result[`${currentValue?.responsible?.employee?._id}`] =
          result[`${currentValue?.responsible?.employee?._id}`] || [])?.push(
          currentValue,
        );

        return result;
      }, {});
      const employeeWork = _.map(workByEmployee, (work, index) => {
        const owner = _.find(employee.rows, (row) => row?._id === index);
        return { employee: owner, work };
      });
      // sort data from work amount
      const sort = _.sortBy(employeeWork, (work) => _.size(work.work));
      setWorkLoad(_.reverse(sort));
    }
    return () => {};
  }, [step, stepLog]);

  return (
    <div>
      <div className="font-semibold my-4">งานของพนักงาน{periodText}</div>
      <Card>
        <CardContent>
          <div className="flex flex-wrap my-2">
            <div className="w-full font-display">
              <div className="text-5xl text-right p-4">
                <span className="text-xl px-4">สำเร็จแล้ว</span>
                <span className="text-green-500">
                  {_.reduce(
                    allSteps,
                    (result, row) => {
                      if (row.completed) {
                        result += 1;
                      }
                      return result;
                    },
                    0,
                  )}{' '}
                </span>
                <span className="text-xl px-4">จากทั้งหมด</span>{' '}
                {step.total || 0} <span className="text-xl px-4">รายการ</span>
              </div>
            </div>
          </div>
        </CardContent>
      </Card>
      <div className="flex flex-wrap my-4">
        <div className="w-full max-h-64 overflow-y-scroll px-2">
          {workLoad &&
            _.map(workLoad, (work, index) => (
              <Card className="p-2 my-2" key={index}>
                <div className="flex flex-wrap justify-between gap-2">
                  <div className="flex flex-wrap gap-4">
                    <Avatar
                      src={work?.employee?.image?.url || ''}
                      sx={{
                        width: 48,
                        height: 48,
                        boxShadow: 2,
                      }}
                    />
                    <span>
                      <div className="font-bold font-display">
                        {work?.employee?.firstname || 'ไม่ระบุผู้รับผิดชอบ'}{' '}
                        {work?.employee?.lastname}
                      </div>
                      <div className="text-sm">
                        {work?.employee?.department?.name || '-'}
                      </div>
                    </span>
                  </div>
                  <div className="flex flex-wrap gap-y-4">
                    <div className="w-0 lg:w-full"></div>
                    <span>
                      <div>
                        {'สำเร็จแล้ว '}{' '}
                        <span className="text-green-500">
                          {_.reduce(
                            work.work,
                            (result, eachWork) => {
                              if (eachWork.completed) {
                                result += 1;
                              }
                              return result;
                            },
                            0,
                          )}
                        </span>
                        {' จากทั้งหมด '}
                        {_.size(work.work)}
                        {' รายการ'}
                      </div>
                    </span>
                  </div>
                </div>
              </Card>
            ))}
        </div>
      </div>
    </div>
  );
};

export default EmployeeWorkLoadCard;

EmployeeWorkLoadCard.propTypes = {
  step: PropTypes.shape({
    rows: PropTypes.arrayOf(PropTypes.object),
    total: PropTypes.number,
  }),
  employee: PropTypes.shape({
    rows: PropTypes.arrayOf(PropTypes.object),
  }),
  periodText: PropTypes.string,
};
