import CampaignModal from './General/CampaignModal';
import ModalUploadFile from './General/ModalUploadFile';
import ModalUploadImage from './General/ModalUploadImage';
import SystemSlowModal from './General/SystemSlowModal';
import ImportWithStockLotModal from './IMS/ImportWithStockLotModal';
import ModalConfirmTransformMaterial from './IMS/ModalConfirmTransformMaterial';
import CancleProcessModal from './MMS/CancleProcessModal';
import DeleteManufacOrderModal from './MMS/DeleteManufacOrderModal';
import AddInventoryModal from './PMS/AddInventoryModal';

export {
  AddInventoryModal,
  CampaignModal,
  CancleProcessModal,
  DeleteManufacOrderModal,
  ImportWithStockLotModal,
  ModalConfirmTransformMaterial,
  ModalUploadFile,
  ModalUploadImage,
  SystemSlowModal,
};

export default {
  AddInventoryModal,
  CancleProcessModal,
  DeleteManufacOrderModal,
  ModalUploadFile,
  ModalUploadImage,
  CampaignModal,
  ImportWithStockLotModal,
  SystemSlowModal,
  ModalConfirmTransformMaterial,
};
