module.exports = {
  HRMS: true,
  CRM: true,
  IMS: true,
  MMS: true,
  SPM: true,
  WMS: true,
  PMS: true,
  MOM: true,
  INFORMATION: true,
  REPORT: true,
  DASHBOARD: true,
};
