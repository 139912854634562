import React from 'react';
import { Controller } from 'react-hook-form';
import { Checkbox, FormControlLabel, Switch, TextField } from '@mui/material';
import PropTypes from 'prop-types';

import ConveyorSketchGeneraror from '../../Box/Machine/ConveyorSketchGeneraror';
import { DesktopDatepicker } from '../../DatePicker';
import ImageUpload from '../../ImageUpload/ImageUpload';

const MachineForm = ({
  control,
  errors,
  machine,
  images,
  setImages,
  watch,
}) => (
  <div className="flex flex-row flex-wrap">
    <div className="w-full px-1 py-2">
      <Controller
        name="is_conveyor"
        control={control}
        defaultValue={machine?.is_conveyor}
        render={({ field }) => (
          <div className="flex gap-2 border p-2 round-md justify-center">
            <div className="self-center">เป็นเครื่องจักร</div>
            <FormControlLabel
              label="เป็นรางการผลิต/สายพานลำเลียง"
              control={<Switch {...field} defaultChecked={field.value} />}
            />
          </div>
        )}
      />
    </div>
    {watch('is_conveyor') && (
      <div className="w-full px-1">
        <div className="py-2 font-display font-semibold">
          ตัวอย่างแบบจำลอง รางการผลิต / สายพานลำเลียง
        </div>
        <div className="p-2 m-2 border rounded-md">
          <ConveyorSketchGeneraror
            length={parseFloat(watch('conveyor_length'))}
            width={parseFloat(watch('conveyor_width'))}
            numberOfParallel={parseFloat(watch('conveyor_parallel_number'))}
            referencePoint={
              watch('conveyor_reference_point.enable')
                ? watch('conveyor_reference_point.reference_every')
                : 0
            }
          />
        </div>
      </div>
    )}
    <div className="w-full px-1 py-2 font-semibold font-display">
      ข้อมูลทั่วไป
    </div>
    <div className="w-full px-1 py-2">
      <Controller
        name={'name'}
        control={control}
        rules={{ required: true }}
        defaultValue={machine?.name}
        render={({ field }) => (
          <TextField
            {...field}
            label="ชื่อเครื่องจักร / รางการผลิต"
            fullWidth
            required
            size={'small'}
            helperText={errors?.name && 'ชื่อเครื่องจักร / รางการผลิต'}
          />
        )}
      />
    </div>
    <div className="w-full px-1 py-2">
      <Controller
        name={'licence_number'}
        control={control}
        rules={{ required: false }}
        defaultValue={machine?.licence_number}
        render={({ field }) => (
          <TextField
            {...field}
            label="เลขทะเบียน Serial Number"
            fullWidth
            size={'small'}
          />
        )}
      />
    </div>
    <div className="w-full px-1 py-2 font-semibold font-display">
      รายละเอียด
    </div>
    <div className="w-full md:w-1/2 px-1 py-2">
      <Controller
        name={'attribute.brand'}
        control={control}
        defaultValue={machine?.attribute?.brand}
        render={({ field }) => (
          <TextField {...field} label="ยี่ห้อ" fullWidth size={'small'} />
        )}
      />
    </div>{' '}
    <div className="w-full md:w-1/2 px-1 py-2">
      <Controller
        name={'attribute.generation'}
        control={control}
        defaultValue={machine?.attribute?.generation}
        render={({ field }) => (
          <TextField {...field} label="รุ่น" fullWidth size={'small'} />
        )}
      />
    </div>{' '}
    <div className="w-full md:w-1/2 px-1 py-2">
      <Controller
        name={'attribute.installation_date'}
        control={control}
        rules={{ required: false }}
        defaultValue={machine?.attribute?.installation_date}
        render={({ field }) => (
          <DesktopDatepicker
            {...field}
            label="วันที่ซื้อ"
            fullWidth
            size={'small'}
          />
        )}
      />
    </div>{' '}
    <div className="w-full md:w-1/2 px-1 py-2">
      <Controller
        name={'attribute.price'}
        control={control}
        defaultValue={machine?.attribute?.price}
        render={({ field }) => (
          <TextField {...field} label="ราคา" fullWidth size={'small'} />
        )}
      />
    </div>{' '}
    <div className="w-full md:w-1/3 px-1 py-2">
      <Controller
        name={'attribute.service_life'}
        control={control}
        defaultValue={machine?.attribute?.service_life}
        render={({ field }) => (
          <TextField
            {...field}
            label="อายุการใข้งาน (ปี)"
            fullWidth
            size={'small'}
          />
        )}
      />
    </div>{' '}
    <div className="w-full md:w-1/3 px-1 py-2">
      <Controller
        name={'attribute.hour_per_day'}
        control={control}
        defaultValue={machine?.attribute?.hour_per_day}
        render={({ field }) => (
          <TextField
            {...field}
            label="ชั่วโมงการใช้งานต่อวัน"
            fullWidth
            size={'small'}
          />
        )}
      />
    </div>{' '}
    <div className="w-full md:w-1/3 px-1 py-2">
      <Controller
        name={'attribute.day_per_month'}
        control={control}
        defaultValue={machine?.attribute?.day_per_month}
        render={({ field }) => (
          <TextField
            {...field}
            label="จำนวนวันทำงานต่อเดือน"
            fullWidth
            size={'small'}
          />
        )}
      />
    </div>{' '}
    {watch('is_conveyor') && (
      <div className="w-full md:w-1/2 px-1 py-2">
        <Controller
          name={'conveyor_width'}
          control={control}
          rules={{ required: false }}
          defaultValue={machine?.conveyor_width}
          render={({ field }) => (
            <TextField
              {...field}
              label="ความกว้าง (เมตร)"
              fullWidth
              size={'small'}
            />
          )}
        />
      </div>
    )}
    {watch('is_conveyor') && (
      <div className="w-full md:w-1/2 px-1 py-2">
        <Controller
          name={'conveyor_length'}
          control={control}
          rules={{ required: false }}
          defaultValue={machine?.conveyor_length}
          render={({ field }) => (
            <TextField
              {...field}
              label="ความยาว (เมตร)"
              fullWidth
              size={'small'}
            />
          )}
        />
      </div>
    )}
    {watch('is_conveyor') && (
      <div className="w-full md:w-1/2 px-1 py-2">
        <Controller
          name={'conveyor_parallel_number'}
          control={control}
          rules={{ required: false }}
          defaultValue={machine?.conveyor_parallel_number}
          render={({ field }) => (
            <TextField
              {...field}
              label="จำนวนรางที่ขนานกัน"
              fullWidth
              size={'small'}
              helperText="เช่น หากเป็นรางคู่ก็ใส่เป็นเลข 2 เป็นต้น"
            />
          )}
        />
      </div>
    )}{' '}
    {watch('is_conveyor') && (
      <div className="w-full px-1 py-2">
        <Controller
          name={'conveyor_reference_point.enable'}
          control={control}
          rules={{ required: false }}
          defaultValue={machine?.conveyor_reference_point?.enable}
          render={({ field }) => (
            <FormControlLabel
              label="เปิดใช้งานการรระบุตำแหน่งบนสายพาน"
              control={<Switch {...field} defaultChecked={field.value} />}
            />
          )}
        />
      </div>
    )}
    {watch('conveyor_reference_point.enable') && (
      <div className="w-full lg:w-1/2 px-1 py-2">
        <Controller
          name={'conveyor_reference_point.reference_every'}
          control={control}
          rules={{ required: false }}
          defaultValue={machine?.conveyor_reference_point?.reference_every}
          render={({ field }) => (
            <TextField
              {...field}
              type="number"
              label="ระบุตำแหน่งทุก ๆ (เมตร)"
              fullWidth
              size={'small'}
            />
          )}
        />
      </div>
    )}
    <div className="w-full px-1 py-2">
      <Controller
        name={'spec'}
        control={control}
        rules={{ required: false }}
        defaultValue={machine?.spec}
        render={({ field }) => (
          <TextField
            {...field}
            multiline
            rows={3}
            label="รายละเอียดเพิ่มเติม"
            fullWidth
            size={'small'}
          />
        )}
      />
    </div>
    <div className="w-full px-1 py-2">
      {machine?.image && (
        <img src={machine?.image?.url} className="w-48 my-2" />
      )}
      <ImageUpload images={images} maxNumber={1} setImages={setImages} />
    </div>
  </div>
);

export default MachineForm;

MachineForm.propTypes = {
  control: PropTypes.func,
  errors: PropTypes.func,
  machine: PropTypes.object,
  images: PropTypes.arrayOf(PropTypes.object),
  setImages: PropTypes.func,
  watch: PropTypes.func,
};
