import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { gql } from 'graphql-request';
import { useParams } from 'react-router-dom';

import {
  ViewTitle,
  Error,
  Loading,
  BackButton,
  MeasurementDataTable,
} from '@iarcpsu/emanufac-components/src/components';
import * as actions from '../../../redux/actions';
import graphqlClient from '../../../configs/graphqlClient';

/**
 * @function DetailMeasuredData
 * @description Display a collections or a list of Measurement Data
 * getting Measurement Type from Params
 *  ดึง Measurement Type (variable type) จากนห้าที่แล้วมาใส่ไว้ใน Params
 */

export default function DetailMeasuredData({ title, subtitle }) {
  const dispatch = useDispatch();
  const measurement = useSelector((state) => state.measurement);
  const measurementType = useSelector((state) => state.measurementType);
  const { id: measurementTypeId } = useParams();
  const [page, setPage] = useState(1);
  const [size, setSize] = useState(10);
  const [total, setTotal] = useState();

  /**
   * @description GraphQL สามารถเรียกแบบเป็น Variable ได้
   * @link  https://github.com/prisma-labs/graphql-request
   */
  const query = gql`
    query FindDetailMeasuredData(
      $input: MeasurementInput
      $measurementTypeInput: MeasurementTypeInput
    ) {
      findMeasurements(input: $input) {
        currPage
        lastPage
        total
        rows {
          measurement_type {
            name
            data_type
          }
          value
          step {
            name
          }
          updatedAt
        }
      }
      findOneMeasurementType(input: $measurementTypeInput) {
        name
        type_code
        data_type
      }
    }
  `;

  const queryDataFromServer = async () => {
    try {
      const queryResult = await graphqlClient.request(query, {
        input: { page, size, measurement_type: measurementTypeId || null },
        measurementTypeInput: {
          id: measurementTypeId,
        },
      });
      const measurementData = queryResult?.findMeasurements;
      const measurementTypeData = queryResult?.findOneMeasurementType;

      dispatch(actions.measurementStateSet(measurementData));
      dispatch(actions.measurementTypeStateOneSet(measurementTypeData));
    } catch (error) {
      console.error('Query Data Error', error);
    }
  };

  useEffect(() => {
    queryDataFromServer();
    return () => {};
  }, [page, size, measurementTypeId]);

  useEffect(() => {
    if (measurement?.rows) {
      setTotal(measurement?.total);
    }

    return () => {};
  }, [measurement]);

  const measurementDataTableFuncton = {
    measurement,
    page,
    size,
    total,
    handleChangePage: (event, currPage) => {
      setPage(currPage + 1);
    },
    handleChangeRowPerPage: (event) => {
      setSize(event.target.value);
      setPage(1);
    },
  };

  const renderTitle = () => (
    <ViewTitle
      title={`${title} ${measurementType?.name}`}
      subtitle={subtitle}
    />
  );

  if (measurement.isLoading) {
    return <Loading />;
  }

  if (!measurement.isLoading && measurement.isCompleted) {
    return (
      <div>
        {renderTitle()}
        <div className=" flex">
          <BackButton />
        </div>

        <div className="my-2">
          <MeasurementDataTable {...measurementDataTableFuncton} />
        </div>
      </div>
    );
  }
  return <Error />;
}

DetailMeasuredData.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
};

DetailMeasuredData.defaultProps = {
  title: '',
  subtitle: '',
};
