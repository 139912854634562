import _ from 'lodash';

import { unNullManufacMaterialAnalyzedArrayAnalzer } from './materialAnalyzer';

export default function findMaterialCost(
  manufacturingMaterial,
  materialTransaction,
) {
  const materialStocklotList = _.reduce(
    materialTransaction,
    (result, value, key) => {
      _.map(value.material_stock_lot, (eachStocklot) => {
        result.push(eachStocklot);
      });

      return result;
    },
    [],
  );

  const materialStockLotListGroupByLot = _.values(
    _.groupBy(materialStocklotList, (eachStocklot) => eachStocklot.lotId._id),
  );

  const materialStockLotCombine = _.map(
    materialStockLotListGroupByLot,
    (each) => {
      const materialStockLot = each?.[0]?.lotId;
      return {
        material_stock_lot: each,
        lotId: materialStockLot,
        quantity: _.sumBy(each, (eachStocklot) => eachStocklot.quantity),
      };
    },
  );

  const unNullManufactuingMateiral = unNullManufacMaterialAnalyzedArrayAnalzer(
    manufacturingMaterial,
  );

  const manufacMaterialWithLot = _.map(unNullManufactuingMateiral, (eachMM) => {
    const foundStockLot = _.filter(
      materialStockLotCombine,
      (eachStocklot) =>
        eachStocklot.lotId?.material?._id === eachMM.materialInfo?._id,
    );
    return {
      ...eachMM,
      material_stock_lot: _.map(foundStockLot, (eachStocklot) => {
        const unitCost =
          parseFloat(eachStocklot?.lotId?.price) /
          parseFloat(eachStocklot?.lotId?.quantity);
        return { ...eachStocklot, unit_cost: unitCost };
      }),
    };
  });

  return manufacMaterialWithLot;
}
