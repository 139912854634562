/* eslint-disable no-param-reassign */
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import {
  BackButton,
  CardManufacturingOrderAnalysis,
  DaySpotAndRangePicker,
  EmployeeWorkLoadCard,
  Error,
  Loading,
  ManufacturingOrderCalendar,
  StepAnalysisDashboardElements,
  ViewTitle,
} from '@iarcpsu/emanufac-components/src/components';
import {
  Button,
  Card,
  CardContent,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Skeleton,
} from '@mui/material';
import dayjs from 'dayjs';
import PropTypes from 'prop-types';

import { config } from '@/configs';

import * as actions from '../../redux/actions';
import { getStorage, setStorage } from '../../utils/functions/localstorage';

function ManufacturingDashboard({ title, subtitle }) {
  const dispatch = useDispatch();
  const employee = useSelector((state) => state.employee);
  const manufacturingOrder = useSelector((state) => state.manufacturingOrder);
  const material = useSelector((state) => state.material);
  const step = useSelector((state) => state.step);
  const me = useSelector((state) => state.me);
  const stepLog = useSelector((state) => state.stepLog);

  const isFullCalendar = getStorage('full_calendar');
  const [startDate, setStartDate] = useState(
    dayjs().startOf('month').toISOString(),
  );
  const [periodText, setPeriodText] = useState('ในเดือนล่าสุด');
  const [endDate, setEndDate] = useState(dayjs().endOf('month').toISOString());
  const [isReady, setIsReady] = useState(false);

  // console.log('isFullCalendar', isFullCalendar);

  useEffect(() => {
    const fetchManufacturingOrder = async () => {
      try {
        setIsReady(false);
        await dispatch(
          actions.manufacturingOrderAll({
            all: true,
            startDate,
            endDate,
            analyzed: true,
            page: 1,
          }),
        );
        setIsReady(true);
      } catch (error) {
        console.error(error);
      }
    };

    const fetchStep = async () => {
      try {
        await dispatch(
          actions.stepAll({
            all: true,
            startDate,
            endDate,
            size: config.maxFetchSize,
          }),
        );
        await dispatch(
          actions.stepLogAll({
            all: true,
            startDate,
            endDate,
            size: config.maxFetchSize,
          }),
        );
      } catch (error) {
        console.error(error);
      }
    };

    const fetchMe = async () => {
      try {
        await dispatch(actions.meGet(''));
      } catch (error) {
        console.error(error);
      }
    };

    const fetchEmployee = async () => {
      try {
        await dispatch(actions.employeeAll({}));
      } catch (error) {
        console.error(error);
      }
    };

    const checkFullCalendar = async () => {
      if (!isFullCalendar) {
        await setStorage('full_calendar', true);
      }
    };

    checkFullCalendar();
    fetchEmployee();
    fetchManufacturingOrder();
    fetchStep();
    fetchMe();
    return () => {};
  }, [startDate, endDate]);

  const renderTitle = () => <ViewTitle title={title} subtitle={subtitle} />;

  const handleChangePeriod = (value) => {
    if (value === 'week') {
      setStartDate(dayjs().startOf('week').toISOString());
      setEndDate(dayjs().endOf('week').toISOString());
      setPeriodText('ในสัปดาห์ล่าสุด');
    } else if (value === 'month') {
      setStartDate(dayjs().startOf('month').toISOString());
      setEndDate(dayjs().endOf('month').toISOString());
      setPeriodText('ในเดือนล่าสุด');
    } else if (value === 'year') {
      setStartDate(dayjs().startOf('year').toISOString());
      setEndDate(dayjs().endOf('year').toISOString());
      setPeriodText('ในปีล่าสุด');
    }
  };
  const renderPeriod = () => (
    <div>
      <FormControl sx={{ minWidth: 120 }}>
        <InputLabel id="type" size={'small'}>
          ช่วงเวลา
        </InputLabel>
        <Select
          label="ช่วงเวลา"
          size={'small'}
          fullWidth
          defaultValue="month"
          onChange={(e) => {
            handleChangePeriod(e.target.value);
          }}
        >
          <MenuItem value="week">สัปดาห์ล่าสุด</MenuItem>
          <MenuItem value="month">เดือนล่าสุด</MenuItem>
          <MenuItem value="year">ปีล่าสุด</MenuItem>
        </Select>
      </FormControl>
    </div>
  );

  const setCurrentDayFunction = (selectedDate) => {
    console.log('Selected Date');
    setStartDate(dayjs(selectedDate).startOf('month').toISOString());
    setEndDate(dayjs(selectedDate).endOf('month').toISOString());
  };

  if (
    me.isLoading &&
    material.isLoading &&
    step.isLoading &&
    employee.isLoading &&
    manufacturingOrder.isLoading
  ) {
    return <Loading />;
  }
  if (
    me.isCompleted &&
    me.isCompleted &&
    material.isCompleted &&
    step.isCompleted &&
    employee.isCompleted &&
    manufacturingOrder.isCompleted
  ) {
    return (
      <div>
        {renderTitle()}
        <div className="flex justify-between my-2">
          <BackButton />
          <div className="flex gap-2">
            {renderPeriod()}
            <div>
              <Link to="/mms/manufacturing-order-report">
                <Button variant="contained">รายงานรายเดือน</Button>
              </Link>
            </div>
          </div>
        </div>
        <div className="my-2 flex flex-wrap">
          <div className="w-full lg:w-3/3 ">
            <CardManufacturingOrderAnalysis
              manufacturingOrder={manufacturingOrder}
              selectedMonthDate={
                periodText === 'ในเดือนล่าสุด' ? startDate : null
              }
              showRevenue={false}
              showProductionResult={false}
              showMoreInfoBox
              isReady={isReady}
            />
          </div>
          <div className="w-full my-1">
            <Card>
              <CardContent>
                <DaySpotAndRangePicker
                  endDate={endDate}
                  setEndDate={setEndDate}
                  startDate={startDate}
                  setStartDate={setStartDate}
                  state="month"
                  flex
                />
              </CardContent>
            </Card>
          </div>
          <div className="w-full  my-2">
            <StepAnalysisDashboardElements
              step={step}
              stepLog={stepLog}
              isReady={isReady}
            />
          </div>
        </div>
        {isReady ? (
          <ManufacturingOrderCalendar
            periodText={periodText}
            manufacturingOrder={manufacturingOrder}
            setCurrentDay={setCurrentDayFunction}
            step={step}
            currentDay={startDate}
          />
        ) : (
          <Skeleton />
        )}
        <EmployeeWorkLoadCard
          employee={employee}
          step={step}
          stepLog={stepLog}
          periodText={periodText}
        />
      </div>
    );
  }
  return <Error />;
}
ManufacturingDashboard.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
};

ManufacturingDashboard.defaultProps = {
  title: '',
  subtitle: '',
};

export default ManufacturingDashboard;
