import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Card } from '@mui/material';
import { useParams } from 'react-router';
import PropTypes from 'prop-types';
import _ from 'lodash';
import * as actions from '../../redux/actions';
import { BackButton } from '@iarcpsu/emanufac-components/src/components/Button';
import { Loading } from '@iarcpsu/emanufac-components/src/components/Loading';
import { Error } from '@iarcpsu/emanufac-components/src/components/Error';
import { ViewTitle } from '@iarcpsu/emanufac-components/src/components/ViewTitle';

export default function DetailCustomer({ title, subtitle }) {
  const dispatch = useDispatch();
  const { id } = useParams();
  const customer = useSelector((state) => state.customer);

  useEffect(() => {
    dispatch(actions.customerGet(id));
    dispatch(actions.customerTypeAll(''));
    return () => {};
  }, []);

  const renderTitle = () => <ViewTitle title={title} subtitle={subtitle} />;

  if (customer.isLoading || customer.rows) {
    return <Loading />;
  }
  if (!customer.isLoading && customer.isCompleted) {
    return (
      <div>
        {renderTitle()}
        <div className="flex flex-row justify-start pb-4">
          <div>
            <BackButton />
          </div>
        </div>
        <div>
          <Card className="p-4 ">
            <div className="flex flex-wrap divide-y">
              <div className="w-full py-4 text-lg font-semibold   font-display">
                {'รายละเอียด'}
              </div>
              <div className="w-1/2 py-4 px-2 font-semibold  font-display ">
                {'ประเภท'}
              </div>
              <div className="w-1/2 py-4 ">{`${
                customer.type?.name || ''
              }`}</div>
              <div className="w-1/2 py-4 px-2 font-semibold  font-display ">
                {'ชื่อ'}
              </div>
              <div className="w-1/2 py-4 ">{`${customer.name || ''}`}</div>
              <div className="w-1/2 py-4 px-2 font-semibold  font-display ">
                {'ประเภทธุรกิจ'}
              </div>
              <div className="w-1/2 py-4 ">{`${
                customer.organization_type || ''
              }`}</div>
              <div className="w-1/2 py-4 px-2 font-semibold  font-display ">
                {'ที่อยู่'}
              </div>
              <div className="w-1/2 py-4 ">{`${customer.address || ''}`}</div>
              <div className="w-1/2 py-4 px-2 font-semibold  font-display ">
                {'เลขที่ผู้เสียภาษี'}
              </div>
              <div className="w-1/2 py-4 ">{`${customer.taxes || ''}`}</div>
              <div className="w-1/2 py-4 px-2 font-semibold  font-display ">
                {'เบอร์โทรศัพท์'}
              </div>
              <div className="w-1/2 py-4 ">{`${
                customer.phone_number || ''
              }`}</div>
              <div className="w-1/2 py-4 px-2 font-semibold  font-display ">
                {'อีเมล'}
              </div>
              <div className="w-1/2 py-4 ">{`${customer.email || ''}`}</div>
              <div className="w-1/2 py-4 px-2 font-semibold  font-display ">
                {'แฟกซ์'}
              </div>
              <div className="w-1/2 py-4 ">{`${customer.fax || ''}`}</div>
              <div className="w-1/2 py-4 px-2 font-semibold  font-display ">
                {'รูปแบบการชำระเงิน'}
              </div>
              <div className="w-1/2 py-4 ">{`${
                customer?.payment?.name || ''
              }`}</div>
              <div className="w-1/2 py-4 px-2 font-semibold  font-display ">
                สำนักงานใหญ่ / สาขา
              </div>
              <div className="w-1/2 py-4 ">
                {customer?.is_headquator && <div>สำนักงานใหญ่</div>}
                {!customer?.is_headquator && (
                  <div>
                    {' '}
                    {customer?.branch_code} สาขา {customer?.branch_name || '-'}{' '}
                  </div>
                )}
              </div>
              {!_.isEmpty(customer?.contact) ? (
                <>
                  <div className="w-1/2 py-4 px-2 font-semibold  font-display ">
                    {'ผู้ติดต่อ'}
                  </div>
                  <div className="w-1/2 py-4 ">
                    <div className="flex flex-wrap">
                      {_.map(customer?.contact, (_contact) => (
                        <div
                          key={_contact?._id}
                          className="bg-gray-300 mb-2 p-3 rounded-md text-sm mr-2"
                        >
                          <div>
                            <b>ชื่อ :</b> {`${_contact?.name}`}
                          </div>
                          <div>
                            <b>โทร :</b>
                            {` ${_contact?.phone_number}`}
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                </>
              ) : (
                <></>
              )}
            </div>
          </Card>
        </div>
      </div>
    );
  }
  return <Error />;
}

DetailCustomer.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
};

DetailCustomer.defaultProps = {
  title: '',
  subtitle: '',
};
