import React, { useEffect, useRef } from 'react';
import { Controller } from 'react-hook-form';
import MonacoEditor from 'react-monaco-editor';
import { REPORT_SUB_PLACE_IN_WAREHOUSE } from '@iarcpsu/emanufac-constant';
import {
  Card,
  CardContent,
  Checkbox,
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from '@mui/material';
import _ from 'lodash';
import PropTypes from 'prop-types';

import { menuList } from '@/utils/constants';

function PDFReportForm({
  control,
  pdfReport,
  warehouse,
  place,
  setValue,
  watch,
}) {
  const placeAndWarehouse = _.concat(place?.rows, warehouse?.rows);

  return (
    <div className="flex flex-wrap">
      <div className="w-full py-2">
        <Controller
          name="name"
          control={control}
          defaultValue={pdfReport?.name}
          render={({ field }) => (
            <TextField label="ชื่อรายงาน" fullWidth {...field} size="small" />
          )}
        />
      </div>
      <div className="w-full py-2">
        <Controller
          name="module"
          control={control}
          defaultValue={pdfReport?.module}
          render={({ field }) => (
            <FormControl fullWidth size="small">
              <InputLabel>โมดูลของระบบ</InputLabel>
              <Select {...field} fullWidth size="small" label="โมดูลของระบบ">
                {_.map(menuList, (menu, index) => (
                  <MenuItem key={index} value={menu?.moduleName}>
                    {menu?.title}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          )}
        />
      </div>
      {watch('module') === 'IMS' || watch('module') === 'WMS' ? (
        <div className="flex flex-wrap w-full">
          <div className="w-full py-2">
            {!_.isEmpty(placeAndWarehouse) && !watch('all_warehouse') && (
              <Controller
                name="warehouse"
                control={control}
                defaultValue={pdfReport?.warehouse}
                render={({ field }) => (
                  <FormControl fullWidth size="small">
                    <InputLabel>คลังวัตถุดิบ/สินค้าสินค้า</InputLabel>
                    <Select
                      {...field}
                      fullWidth
                      size="small"
                      label="คลังวัตถุดิบ/สินค้า"
                    >
                      {_.map(placeAndWarehouse, (data, index) => (
                        <MenuItem key={index} value={data?._id}>
                          {data?.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                )}
              />
            )}
          </div>
          <div className="w-full py-2">
            <Controller
              control={control}
              name="all_warehouse"
              defaultValue={pdfReport?.all_warehouse}
              render={({ field }) => (
                <FormControlLabel
                  label="แสดงในทุกๆ คลัง"
                  control={<Checkbox {...field} defaultChecked={field.value} />}
                />
              )}
            />
          </div>
          <div className="w-full py-2">
            <Controller
              name="sub_place_in_warehose"
              control={control}
              defaultValue={pdfReport?.sub_place_in_warehose}
              render={({ field }) => (
                <FormControl fullWidth size="small">
                  <InputLabel>หน้าที่จะแสดง</InputLabel>
                  <Select
                    {...field}
                    fullWidth
                    size="small"
                    label="หน้าที่จะแสดง"
                  >
                    {_.map(REPORT_SUB_PLACE_IN_WAREHOUSE, (data, index) => (
                      <MenuItem key={index} value={data?.status_code}>
                        {data?.description}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              )}
            />
          </div>
        </div>
      ) : (
        <div />
      )}

      <div className="w-full py-2">
        <Controller
          control={control}
          name="printing_label"
          defaultValue={pdfReport?.printing_label}
          render={({ field }) => (
            <TextField
              label="คำที่จะแสดงบนปุ่มพิมพ์"
              fullWidth
              {...field}
              size="small"
            />
          )}
        />
      </div>

      <div className="w-full py-2">
        <Card>
          <CardContent>
            ตัวแปร
            <code> manufacturingOrder</code>
            จะใช้ได้ถ้าเป็นรายงานของการผลิต ในขณะที่ตัวแรป
            <code> materialStockLot</code>
            <code>startDate</code>,<code>endDate</code>,<code>pointDate</code>{' '}
            จะใช้ได้เมื่อเป็นของคลังวัตถุดิบ หรือ คลังสินค้า
          </CardContent>
        </Card>
      </div>
      <div className="w-full py-2 font-semibold font-display">
        โค้ดที่ใช้สร้างรายงาน
      </div>
      <div className="w-full py-2">
        <Controller
          name="structure"
          control={control}
          defaultValue={pdfReport?.structure}
          render={({ field }) => (
            <div>
              <MonacoEditor
                height="600"
                language="javascript"
                theme="vs"
                value={field.value}
                options={{}}
                onChange={(value) => field.onChange(value)}
              />
            </div>
          )}
        />
      </div>
    </div>
  );
}

export default PDFReportForm;

PDFReportForm.propTypes = {
  control: PropTypes.object,
  pdfReport: PropTypes.object,
  warehouse: PropTypes.object,
  place: PropTypes.object,
  setValue: PropTypes.func,
  watch: PropTypes.func,
};
