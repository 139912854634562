import React from 'react';
import _ from 'lodash';

import { previousLotReciptDateSafe } from '@/utils/functions';

import EachEmployeeTimePair from './EachEmployeeTimePair';

const EmployeeDrivenOnEachParent = ({ previousLotList }) => {
  const orderedLot = _.orderBy(
    previousLotReciptDateSafe(previousLotList),
    'receipt_date',
  );
  const groupByDatedLots = _.groupBy(orderedLot, 'receipt_date');

  const timeKey = _.keys(groupByDatedLots);
  const pairTimeKey = _.zip(timeKey, timeKey.slice(1));
  const filterPairTime = _.filter(
    pairTimeKey,
    (each) => each?.[1] !== undefined,
  );

  return (
    <div>
      {_.map(filterPairTime, (eachPair, index) => (
        <EachEmployeeTimePair
          materialStockLotGroupByDated={groupByDatedLots}
          timePair={eachPair}
          key={index}
        />
      ))}
    </div>
  );
};

export default EmployeeDrivenOnEachParent;
