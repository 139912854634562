import React from 'react';
import { Alert, AlertTitle, Card } from '@mui/material';
import dayjs from 'dayjs';
import _ from 'lodash';
import PropTypes from 'prop-types';

import {
  converseMaterialOrProductUnit,
  currencyFormatter,
  durationTimeDisplay,
} from '@/utils/functions';

import 'dayjs/locale/th';

import MultiUnitProdOrMatBox from '../Box/Rendering/MultiUnitProdOrMatBox';

const CardProcessInfo = ({
  manufacturingOrder,
  information,
  selectedProcess,
}) => {
  const {
    setting: { mms: mmsSetting },
  } = information;
  const { timeDurationWord } = durationTimeDisplay;
  const isMOComplete = manufacturingOrder?.completed;
  const isMODeleted = manufacturingOrder?.deleted;

  // คำนวณหาว่าเหลือกี่วันจาก Function ที่เขียนไว้ใน Util
  const calculatedDate = manufacturingOrder?.completed
    ? dayjs(manufacturingOrder.updatedAt)
    : dayjs();

  // แสดงกรณี Manufac Order Success แล้ว
  const renderSuccessBadge = () => {
    if (isMOComplete) {
      return (
        <div className="my-2">
          <Alert severity="success">
            <AlertTitle>
              <strong>การผลิตสำเร็จแล้ว</strong>
            </AlertTitle>
            ทุกงานในคำสั่งผลิตนี้สำเร็จแล้ว
          </Alert>
        </div>
      );
    }
    return <div></div>;
  };

  // แสดงกรณี Manufac Order ยกเลิก
  const renderDeleteBadge = () => {
    if (isMODeleted) {
      return (
        <div className="my-2">
          <Alert severity="error">
            <AlertTitle>
              <strong>การผลิตถูกยกเลิก</strong>
            </AlertTitle>
            คำสั่งผลิตนี้ถูกยกเลิกไปแล้ว
            {manufacturingOrder?.delete_note
              ? `ด้วยเหตุผล ${manufacturingOrder?.delete_note}`
              : ''}
          </Alert>
        </div>
      );
    }
    return <div></div>;
  };

  const timeReminder = (expectedDate) => {
    const timeDuration = timeDurationWord(
      expectedDate,
      calculatedDate,
      manufacturingOrder?.completed,
    );
    return <div className={timeDuration.cssClass}> {timeDuration.word} </div>;
  };

  const renderProdAsMaterialQuantityDisplay = () => {
    if (selectedProcess?.quantity > 0) {
      if (selectedProcess?.product_as_material?.use_unit_conversion) {
        return (
          <span>
            จำนวน {currencyFormatter.format(selectedProcess?.quantity)}{' '}
            {selectedProcess?.product_as_material?.unit_input?.short_sign} (
            {currencyFormatter.format(
              converseMaterialOrProductUnit(
                selectedProcess?.product_as_material,
                selectedProcess?.quantity,
              ),
            )}{' '}
            {selectedProcess?.product_as_material?.unit_output?.short_sign})
          </span>
        );
      }
      return (
        <span>
          จำนวน {currencyFormatter.format(selectedProcess?.quantity)}{' '}
          {selectedProcess?.additonal_unit ||
            selectedProcess?.product_as_material?.unit}{' '}
        </span>
      );
    }
    return <span></span>;
  };

  const renderProdQuantityDisplay = () => {
    if (selectedProcess?.quantity > 0) {
      if (selectedProcess?.product?.use_unit_conversion) {
        return (
          <span>
            จำนวน {currencyFormatter.format(selectedProcess?.quantity)}{' '}
            {selectedProcess?.product?.unit_input?.short_sign} (
            {currencyFormatter.format(
              converseMaterialOrProductUnit(
                selectedProcess?.product,
                selectedProcess?.quantity,
              ),
            )}{' '}
            {selectedProcess?.product?.unit_output?.short_sign})
          </span>
        );
      }
      return (
        <span>
          จำนวน {currencyFormatter.format(selectedProcess?.quantity)}{' '}
          {selectedProcess?.additional_unit || selectedProcess?.product?.unit}{' '}
        </span>
      );
    }
    return <span></span>;
  };

  return (
    <div>
      <Card className=" p-4 pb-6">
        <div className="flex flex-wrap w-full ">
          {!selectedProcess?.produce_base_project_instead &&
            (selectedProcess?.produce_material_instead ? (
              <h4 className="text-lg mx-2 flex gap-2 my-2">
                <span className="bg-gray-200 p-1 px-2 rounded-md font-bold">
                  {selectedProcess?.product_as_material?.type_code}{' '}
                  {selectedProcess?.product_as_material?.name}
                </span>{' '}
                {renderProdAsMaterialQuantityDisplay()}
              </h4>
            ) : (
              <h4 className="text-lg mx-2 flex gap-2">
                <span className="bg-gray-200 p-1 px-2 rounded-md font-bold">
                  {selectedProcess?.product?.type_code}{' '}
                  {selectedProcess?.product?.name}
                </span>{' '}
                {renderProdQuantityDisplay()}
              </h4>
            ))}
          {selectedProcess?.produce_base_project_instead && (
            <h4 className="text-lg mx-2 flex gap-2">
              <span className="bg-gray-200 p-1 px-2 rounded-md font-bold">
                {selectedProcess?.base_project?.type_code}{' '}
                {selectedProcess?.base_project?.name}
              </span>{' '}
              <MultiUnitProdOrMatBox
                foundMaterial={selectedProcess?.base_project}
                noWrap
                quantity={selectedProcess?.quantity}
              />
            </h4>
          )}
        </div>
        <div className="flex flex-wrap w-full ">
          <div className="w-full md:w-1/6 py-1  md:py-2 pt-4 md:pt-2 px-2 font-semibold font-display ">
            คำสั่งผลิต
          </div>

          <div className="flex flex-wrap w-full md:w-5/6 py-1 md:py-2 px-2 ">
            {manufacturingOrder?.running_number}
          </div>

          {manufacturingOrder?.customer &&
            !_.isEmpty(manufacturingOrder?.customer) && (
              <div className="w-full md:w-1/6 py-2 px-2 font-semibold font-display ">
                ลูกค้า
              </div>
            )}
          {manufacturingOrder?.customer &&
            !_.isEmpty(manufacturingOrder?.customer) && (
              <div className="w-full md:w-5/6 py-2 px-2">
                {manufacturingOrder?.order?.customer?.type_code ||
                  manufacturingOrder?.customer?.type_code}{' '}
                -{' '}
                {manufacturingOrder?.order?.customer?.name ||
                  manufacturingOrder?.customer?.name}
                <br />
              </div>
            )}

          {mmsSetting?.usingAsDefault?.showExpectedDate && (
            <div className="w-full md:w-1/6 py-1  md:py-2 pt-4 md:pt-2 px-2 font-semibold font-display ">
              วันกำหนดส่ง
            </div>
          )}

          {mmsSetting?.usingAsDefault?.showExpectedDate && (
            <div className="flex flex-wrap w-full md:w-2/6 py-1 md:py-2 px-2 ">
              {/* <div className=" gap-2 md:block"> */}
              <div className="mr-4">
                {dayjs(manufacturingOrder?.expected_date)
                  .locale('th')
                  .format(
                    mmsSetting?.timing?.showTime
                      ? 'D MMMM BBBB HH:mm'
                      : 'D MMMM BBBB',
                  )}
              </div>
              <div>
                {!isMOComplete &&
                  !isMODeleted &&
                  timeReminder(manufacturingOrder?.expected_date)}
              </div>
            </div>
          )}

          <div className="w-full">
            {manufacturingOrder?.process &&
              renderSuccessBadge(manufacturingOrder?.expected_date)}
            {manufacturingOrder?.process && renderDeleteBadge()}
          </div>
        </div>
      </Card>
    </div>
  );
};

CardProcessInfo.propTypes = {
  manufacturingOrder: PropTypes.object,
  selectedProcess: PropTypes.object,
  information: PropTypes.object,
};

export default CardProcessInfo;
