import React, { useContext } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { Button } from '@mui/material';
import dayjs from 'dayjs';
import _ from 'lodash';
import PropTypes from 'prop-types';

import { WordLocalizeContext } from '@/contexts/WordLocalizeContext';
import MultiUnitProdOrMatBox from '../Rendering/MultiUnitProdOrMatBox';

function MaterialLotCostDriverBox({
  previousLotList,
  currentLot,
  parentWithChildLots,
}) {
  const information = useSelector((state) => state.information);
  const lotWithNoPreviousLot = _.filter(previousLotList, (lot) =>
    _.isEmpty(lot?.previous_lot),
  );
  // console.log('Lot With No Previous Lot', lotWithNoPreviousLot);
  const { findWord } = useContext(WordLocalizeContext);

  return (
    <div>
      <h3 className="font-semibold my-1">ต้นทุนจากวัตถุดิบ</h3>
      {_.map(lotWithNoPreviousLot, (lot, index) => (
        <div key={index} className="py-2 px-1">
          <div className="flex justify-between">
            <div className="font-semibold">
              {lot?.material?.type_code} {lot?.material?.name}
            </div>
            <Link to={`/ims/material-stock-lot/${lot?._id}`}>
              <Button>รายละเอียดล็อต</Button>
            </Link>
          </div>
          {lot?.lot_number && <div>หมายเลขล็อต : {lot?.lot_number}</div>}
          <div>หมายเลขอ้างอิงล็อต : {lot?.running_number}</div>
          <div className="flex flex-wrap justify-between">
            {information?.setting?.ims?.display?.purchase_date && (
              <div>
                {findWord('purchase_date')} :{' '}
                {dayjs(lot?.purchase_date).format('D MMM BBBB')}
              </div>
            )}
            <div>
              {findWord('receipt_date')} :{' '}
              {dayjs(lot?.receipt_date).format('D MMM BBBB')}
            </div>
          </div>

          <div className="flex flex-wrap justify-between">
            <div className="flex gap-2">
              รับมาทั้งหมด :{' '}
              <MultiUnitProdOrMatBox
                foundMaterial={lot?.material}
                noWrap
                quantity={lot?.quantity}
              />
            </div>
            <div>ราคาทั้งหมด : {lot?.price || '-'} บาท</div>
          </div>
          <div>
            ราคาต่อหน่วย: {parseFloat(lot?.price / lot?.quantity).toFixed(2)}{' '}
            บาท{' '}
          </div>
          <div className="text-xs my-2">
            บันทึกข้อมูลเมื่อ
            {dayjs(lot?.recording_date).format('D MMM BBBB HH:mm')}
          </div>
        </div>
      ))}
      {currentLot && (
        <div>
          <h3 className="font-semibold my-1">
            สินค้า/วัตถุดิบ ปัจจุบันประกอบไปด้วย
          </h3>
          <div>
            {_.map(currentLot?.previous_lot, (lot, index) => {
              const ancestorLot = _.filter(
                parentWithChildLots,
                (eachParentLot) => {
                  const childLotId = _.map(
                    eachParentLot?.child_lot,
                    (each) => each?._id,
                  );
                  return _.includes(childLotId, lot?._id);
                },
              );
              // console.log('Ancestor Lot', lot, ancestorLot);
              return (
                <div key={index} className="my-1">
                  <div className="flex gap-2">
                    <div>
                      <b>
                        ล็อตที่ {index + 1} {lot?.material?.type_code}{' '}
                        {lot?.material?.name}
                      </b>{' '}
                      ใช้ปริมาณ :
                    </div>
                    <div>
                      <MultiUnitProdOrMatBox
                        foundMaterial={lot?.material}
                        quantity={lot?.amount}
                      />
                    </div>
                  </div>

                  <div className="flex gap-2 my-2 overflow-x-auto">
                    {_.map(ancestorLot, (each, ancestorIndex) => (
                      <div
                        key={ancestorIndex}
                        className="border rounded-md p-4"
                      >
                        {each?.material?.name}
                        <div className="text-sm">
                          หมายเลขอ้างอิงล็อต {each?.running_number}
                        </div>
                        <MultiUnitProdOrMatBox
                          foundMaterial={each?.material}
                          noWrap
                          quantity={each?.quantity}
                        />
                      </div>
                    ))}
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      )}
    </div>
  );
}

export default MaterialLotCostDriverBox;

MaterialLotCostDriverBox.propTypes = {
  previousLotList: PropTypes.arrayOf(PropTypes.object),
  currentLot: PropTypes.object,
  parentWithChildLots: PropTypes.arrayOf(PropTypes.object),
};
