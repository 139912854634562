/* eslint-disable import/no-unresolved */
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import {
  BackButton,
  Error,
  Loading,
  MaterialLotReport,
  MaterialStockTab,
  MaterialTransactionTab,
  MultiUnitProdOrMatBox,
  ViewTitle,
} from '@iarcpsu/emanufac-components/src/components';
import { Box, Button, Card, CardContent, Chip, Tab, Tabs } from '@mui/material';
import _ from 'lodash';
import PropTypes from 'prop-types';

import { findIsMaterialRestrict } from '@/utils/functions';

import * as actions from '../../redux/actions';

export default function DetailMaterialStockLotOfMaterial({ title, subtitle }) {
  const dispatch = useDispatch();
  const { id, placeId } = useParams();
  const place = useSelector((state) => state.place);
  const material = useSelector((state) => state.material);
  const information = useSelector((state) => state.information);
  const materialStockLot = useSelector((state) => state.materialStockLot);

  const [selectedTab, setSelectedTab] = useState('material-stock-lot');
  const [fetched, setFetched] = useState(false);

  const handlePrintPdf = async () => {
    try {
      await MaterialLotReport(material, information);
    } catch (error) {
      await MaterialLotReport(material, information, true);
    }
  };

  useEffect(() => {
    dispatch(
      actions.materialGet(id, {
        placeId: placeId || '',
        stockStatus: 'IN_STOCK',
        fetchStockLot: false,
        fetchProcess: '',
      }),
    );
    if (placeId) {
      dispatch(actions.placeGet(placeId));
    }
    dispatch(actions.informationAll());
    return () => {};
  }, [id]);

  useEffect(() => {
    console.log('Fetch is Changed');

    return () => {};
  }, [fetched]);

  useEffect(() => {
    if (material && !material?.rows && material?.autofetching && !fetched) {
      dispatch(
        actions.materialGet(id, {
          placeId: placeId || '',
          stockStatus: 'IN_STOCK',
          fetchStockLot: true,
          fetchProcess: '',
        }),
      );
      setFetched(true);
    }

    return () => {};
  }, [material]);

  const renderTitle = () => (
    <ViewTitle
      title={`${title}ของวัตถุดิบ ${material?.type_code || ''} ${
        material?.name
      } ${placeId ? place?.name || '' : ''}`}
      subtitle={subtitle}
    />
  );

  if (material.isLoading || material.rows) {
    return <Loading />;
  }

  const renderForNormalMaterial = () => (
    <div>
      <Card>
        <Box sx={{ width: '100%' }}>
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <Tabs
              value={selectedTab}
              onChange={(e, value) => setSelectedTab(value)}
              aria-label="basic tabs example"
            >
              <Tab label="รายการล็อต" value="material-stock-lot" />
              <Tab
                label="ประวัติการเข้าออกวัตถุดิบ"
                value="material-transaction"
              />
            </Tabs>
          </Box>
        </Box>
      </Card>
      {selectedTab === 'material-stock-lot' && (
        <MaterialStockTab selectedMaterial={material} place={place} />
      )}{' '}
      {selectedTab === 'material-transaction' && <MaterialTransactionTab />}
    </div>
  );

  const renderForAutofetchMaterial = () => (
    <div>
      <Card>
        <CardContent>
          <div>วัตถุดิบผสม</div>
          <div className="grid grid-cols-2  md:grid-cols-3 lg:grid-cols-5 my-2">
            {_.map(material?.input_materials, (inputMaterial, index) => (
              <div className="p-2" key={index}>
                <div className="p-4 border shadow-md rounded-md">
                  <div className="font-semibold">
                    {inputMaterial?.material?.type_code}{' '}
                  </div>
                  <div className="font-semibold text-lg">
                    {inputMaterial?.material?.type_code}{' '}
                    {inputMaterial?.material?.name}
                  </div>
                  <div className="text-green-500 text-sm">
                    สัดส่วนวัตถุดิบ {inputMaterial?.fraction}
                  </div>
                  <div className="my-2">
                    ปริมาณทั้งหมด
                    <MultiUnitProdOrMatBox
                      foundMaterial={inputMaterial?.material}
                      quantity={inputMaterial?.total}
                    />
                  </div>

                  <div className="my-2">
                    ใช้เป็นส่วนผสมได้
                    <MultiUnitProdOrMatBox
                      foundMaterial={inputMaterial?.material}
                      quantity={_.min(
                        _.map(
                          material?.input_materials,
                          (each) => each?.total / each?.fraction,
                        ),
                      )}
                    />
                  </div>

                  <div className="my-4">
                    <Link
                      to={`/ims/material-stock/${inputMaterial?.material?._id}`}
                    >
                      <Button variant="contained" size="small">
                        วัตถุดิบต้นทาง
                      </Button>
                    </Link>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </CardContent>
      </Card>
    </div>
  );

  if (!material.isLoading && material.isCompleted) {
    return (
      <div>
        {renderTitle()}
        <div className="flex justify-between">
          <div>
            <BackButton />
          </div>
          <div>
            <Button
              variant="contained"
              color="teal"
              onClick={() => handlePrintPdf()}
            >
              พิมพ์
            </Button>
          </div>
        </div>{' '}
        <div className="my-4">
          <Card>
            <CardContent>
              <div className="flex flex-wrap justify-between">
                <div className="w-full md:w-1/2">
                  <h2 className="font-semibold text-xl items-center">
                    {material?.type_code || ''} {material?.name}
                    {place && findIsMaterialRestrict(material, place) && (
                      <Chip
                        color="warning"
                        size="small"
                        label="อยู่ในคลังที่ไม่ถูกต้อง"
                        className="mx-2 my-1"
                      />
                    )}
                  </h2>
                  <div className="text-gray-600">
                    หมวดหมู่ {material?.material_type?.name}
                  </div>
                </div>
                <div className="w-full md:w-1/4">
                  <div className="">ปริมาณคงเหลือในคลัง</div>
                  <div className="text-lg font-semibold">
                    <MultiUnitProdOrMatBox
                      foundMaterial={material}
                      quantity={
                        material?.autofetching
                          ? material?.total
                          : materialStockLot?.amount
                      }
                    />
                  </div>
                </div>
              </div>
            </CardContent>
          </Card>
        </div>
        {material?.autofetching
          ? renderForAutofetchMaterial()
          : renderForNormalMaterial()}
      </div>
    );
  }
  return <Error />;
}

DetailMaterialStockLotOfMaterial.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
};

DetailMaterialStockLotOfMaterial.defaultProps = {
  title: '',
  subtitle: '',
};
