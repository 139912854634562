import React from 'react';
import { Controller } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router';
import {
  Autocomplete,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from '@mui/material';
import _ from 'lodash';
import PropTypes from 'prop-types';

import useQuery from '@/utils/functions/useQuery';

import MaterialStockTypeAddForm from './MaterialStockTypeAddForm';
import MaterialStockTypeDescForm from './MaterialStockTypeDescForm';

/** ฟอร์มสำหรับใส่ข้อมูลขณะที่จะเบิกหรือนำเข้าล็อตวัตถุดิบ */
export function MaterialStockForm({
  errors,
  control,
  place,
  materialTransactionType,
  customer,
  department,
  me,
  type,
  setType = () => {},
  setFilterPlace = () => {},
  setSelectMaterial = () => {},
  setSelectMaterialLot = () => {},
  watch,
  withdrawDestinations,
  employee,
}) {
  const id = useParams()?.id;
  const information = useSelector((state) => state.information);
  const query = useQuery();
  const imsSetting = information?.setting?.ims;

  const selectedTxnType = _.find(
    materialTransactionType,
    (each) => each?._id === query.get('txnType'),
  );

  const filteredPlace = _.filter(
    place,
    (eachPlace) =>
      eachPlace?.allow_all_department ||
      _.includes(
        _.map(eachPlace?.allow_departments, (each) => each?._id),
        me?.department?._id,
      ),
  );

  const checkType = (data) => {
    const foundType = _.find(materialTransactionType, { _id: data });
    console.log('Found Type', foundType);
    setType(foundType);
    setSelectMaterial([]);
    setSelectMaterialLot([]);
  };

  return (
    <div className="flex flex-row flex-wrap">
      <div className="w-full px-1 py-2">
        {selectedTxnType ? (
          <div>
            <div
              className={`text-2xl font-bold ${
                selectedTxnType?.direction === 'add'
                  ? 'text-green-500'
                  : 'text-red-500'
              }`}
            >
              {selectedTxnType?.name}
            </div>
          </div>
        ) : (
          <Controller
            name={'material_transaction_type'}
            control={control}
            rules={{ required: true }}
            defaultValue={materialTransactionType?.[0]?._id}
            render={({ field }) => (
              <FormControl sx={{ minWidth: 120 }} fullWidth={true} required>
                <InputLabel id="type" size={'small'}>
                  ประเภทการดำเนินการ
                </InputLabel>
                <Select
                  {...field}
                  label="ประเภทการดำเนินการ"
                  size={'small'}
                  fullWidth
                  onChange={(e) => {
                    field.onChange(e.target.value);
                    checkType(e.target.value);
                  }}
                >
                  {_.size(materialTransactionType) ? (
                    _.map(
                      materialTransactionType,
                      (_materialTransactionType) => (
                        <MenuItem
                          key={_materialTransactionType.id}
                          value={_materialTransactionType.id}
                        >
                          {_materialTransactionType.name}
                        </MenuItem>
                      ),
                    )
                  ) : (
                    <MenuItem>
                      <MenuItem disabled value="">
                        <em>ไม่มีข้อมูล</em>
                      </MenuItem>
                    </MenuItem>
                  )}
                </Select>
              </FormControl>
            )}
          />
        )}
      </div>
      <div className="w-full lg:w-1/2">
        <div className="w-full px-1 py-2">
          {!_.isEmpty(filteredPlace) && (
            <Controller
              name={'place'}
              control={control}
              defaultValue={
                _.find(filteredPlace, (eachPlace) => eachPlace?._id === id) ||
                filteredPlace?.[0]
              }
              rules={{ required: true }}
              render={({ field }) => (
                <Autocomplete
                  {...field}
                  disablePortal
                  options={filteredPlace}
                  size="small"
                  placeholder="เลือกคลัง"
                  onChange={(e, newValue) => {
                    setFilterPlace(newValue);
                    field.onChange(newValue);
                  }}
                  className="z-40"
                  // prettier-ignore
                  getOptionLabel={(option) => `${option?.name || ''}`
}
                  renderInput={(params) => (
                    <TextField label="เลือกคลัง" {...params} required focused />
                  )}
                />
              )}
            />
          )}
        </div>
      </div>
      <div className="w-full lg:w-1/2">
        {type?.direction === 'add' ? (
          <MaterialStockTypeAddForm
            control={control}
            customers={customer}
            departments={department}
            errors={errors}
            information={information}
            me={me}
            watch={watch}
            employee={employee}
          />
        ) : (
          <MaterialStockTypeDescForm
            control={control}
            departments={department}
            errors={errors}
            imsSetting={imsSetting}
            me={me}
            withdrawDestinations={withdrawDestinations}
            employee={employee}
          />
        )}
      </div>
    </div>
  );
}

MaterialStockForm.propTypes = {
  errors: PropTypes.shape({
    material_transaction_type: PropTypes.object,
    bill_number: PropTypes.object,
    supplier: PropTypes.object,
    source: PropTypes.object,
    place: PropTypes.object,
    purchase_date: PropTypes.object,
    receipt_date: PropTypes.object,
    issue_date: PropTypes.object,
    remark: PropTypes.object,
    recipient_name: PropTypes.object,
    order_department: PropTypes.object,
    request_department: PropTypes.object,
    request_name: PropTypes.object,
  }),
  materialTransactionType: PropTypes.object,
  place: PropTypes.object,
  customer: PropTypes.object,
  department: PropTypes.arrayOf(PropTypes.object),
  me: PropTypes.object,
  type: PropTypes.object,
  setType: PropTypes.func,
  setFilterPlace: PropTypes.func,
  setSelectMaterial: PropTypes.func,
  setSelectMaterialLot: PropTypes.func,
  control: PropTypes.object,
  watch: PropTypes.func,
  withdrawDestinations: PropTypes.arrayOf(PropTypes.object),
};

MaterialStockForm.defaultProps = {
  materialTransactionType: null,
  place: null,
  customer: null,
  department: null,
  me: null,
  type: null,
  filterPlace: null,
};

export default MaterialStockForm;
