import React, { useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { Button, Card } from '@mui/material';
import dayjs from 'dayjs';
import { gql } from 'graphql-request';
import _ from 'lodash';
import PropTypes from 'prop-types';

import {
  BackButton,
  Error,
  Loading,
  ManufacturingOrderInfoForm,
  ViewTitle,
} from '@iarcpsu/emanufac-components/src/components';
import { config } from '../../../configs';
import graphqlClient from '../../../configs/graphqlClient';
import * as actions from '../../../redux/actions';
import { MMS as MMSMiddleWare } from '../../../utils/middleware';

/**
 * @function EditManufacturingOrder
 * @description Display a collections or a list of EditManufacturingOrder from database
 */

export default function EditManufacturingOrder({ title, subtitle }) {
  const dispatch = useDispatch();
  const manufacturingOrder = useSelector((state) => state.manufacturingOrder);
  const paymentType = useSelector((state) => state.paymentType);
  const customer = useSelector((state) => state.customer);
  const information = useSelector((state) => state.information);
  const history = useHistory();
  const params = useParams();

  const query = gql`
    query FindSettingEditProductBom(
      $manufacturingOrderInput: ManufacturingOrderInput
      $customerInput: CustomerInput
    ) {
      findOneManufacturingOrder(input: $manufacturingOrderInput) {
        _id
        prefix
        running_number
        customer {
          _id
          type_code
          name
        }
        start_date
        expected_date
        quotation_number
        payment_method {
          _id
          name
        }
        discount
        remark
      }
      findCustomers(input: $customerInput) {
        total
        rows {
          _id
          type_code
          name
        }
      }
    }
  `;

  const queryDataFromServer = async () => {
    const queryResult = await graphqlClient.request(query, {
      manufacturingOrderInput: {
        id: params.id,
        enableFetchInside: false,
        fetchCurrentStep: false,
        fetchCustomer: true,
        fetchMaterial: false,
        fetchProduct: false,
        fetchProcess: false,
      },
      customerInput: {
        page: 1,
        size: config.maxFetchSize,
      },
    });
    const manufacturingOrderData = queryResult?.findOneManufacturingOrder;
    const customerData = queryResult?.findCustomers;
    dispatch(actions.manufacturingOrderStateOneSet(manufacturingOrderData));
    dispatch(actions.customerStateSet(customerData));
  };

  const fetchPaymentType = async () => {
    try {
      dispatch(actions.paymentTypeAll({ page: 1, size: config.maxFetchSize }));
    } catch (error) {
      console.error('Cannot fetch patment type', error);
    }
  };

  useEffect(() => {
    queryDataFromServer();
    fetchPaymentType();
    return () => {};
  }, [params]);

  const {
    formState: { errors },
    handleSubmit,
    control,
    watch,
    setValue,
  } = useForm({
    defaultValues: {
      start_date: manufacturingOrder?.start_date
        ? dayjs(manufacturingOrder?.start_date).format('YYYY-MM-DD')
        : null,
      expected_date: manufacturingOrder?.expected_date
        ? dayjs(manufacturingOrder?.expected_date).format('YYYY-MM-DD')
        : null,
      attachCustomer: _.isObject(manufacturingOrder?.customer),
      customer: manufacturingOrder?.customer,
      payment_method: manufacturingOrder?.payment_method?._id,
      quotation_number: manufacturingOrder?.quotation_number,
    },
  });

  useEffect(() => {
    if (manufacturingOrder?.start_date) {
      setValue(
        'start_date',
        dayjs(manufacturingOrder?.start_date).format('YYYY-MM-DD'),
      );
    }
    if (manufacturingOrder?.expected_date) {
      setValue(
        'expected_date',
        dayjs(manufacturingOrder?.expected_date).format('YYYY-MM-DD'),
      );
    }

    setValue('attachCustomer', _.isObject(manufacturingOrder?.customer));
    setValue('customer', manufacturingOrder?.customer);
    return () => {};
  }, [manufacturingOrder]);

  const handleEditManufacturingOrder = async (data) => {
    try {
      await dispatch(
        actions.manufacturingOrderPut(
          params.id,
          MMSMiddleWare.ManufacturingOrder(data),
        ),
      );
      history.goBack();
    } catch (error) {
      console.error('Edit ManufacturingOrder Fail', error);
      window.alert(`ไม่สามารถแก้ไขคำสั่งผลิตได้  ${error?.message}`);
    }
  };

  const renderTitle = () => <ViewTitle title={title} subtitle={subtitle} />;

  if (manufacturingOrder.isLoading) {
    return <Loading />;
  }

  if (!manufacturingOrder.isLoading && manufacturingOrder.isCompleted) {
    return (
      <div>
        {renderTitle()}
        <div className="my-2">
          <BackButton />
        </div>
        <div>
          <Card className="p-4">
            <form>
              <div className="flex  w-full justify-between">
                <div className="flex flex-wrap">
                  <div className="w-full md:w-3/6 py-1 md:py-2 px-2 font-semibold font-display ">
                    เลขที่คำสั่งผลิต
                  </div>
                  <div className="w-full md:w-3/6 py-1 md:py-2 px-2 font-semibold font-display">
                    {manufacturingOrder?.running_number}
                  </div>
                </div>
              </div>
              <ManufacturingOrderInfoForm
                Controller={Controller}
                control={control}
                errors={errors}
                manufacturingOrder={manufacturingOrder}
                watch={watch}
                paymentType={paymentType}
                customer={customer}
                information={information}
              />
              <div className="flex justify-end gap-2 my-2">
                <Button
                  variant="contained"
                  color="success"
                  size="small"
                  onClick={handleSubmit(handleEditManufacturingOrder)}
                >
                  บันทึก
                </Button>
              </div>
            </form>
          </Card>
        </div>
      </div>
    );
  }
  return <Error />;
}

EditManufacturingOrder.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
};

EditManufacturingOrder.defaultProps = {
  title: '',
  subtitle: '',
};
