import React from 'react';
import { useSelector } from 'react-redux';
import { Route } from 'react-router-dom';

import { NotAuthorized } from '@iarcpsu/emanufac-components/src/components/Error';
import accessRight from '../../utils/functions/accessRight';

import {
  MaterialType,
  EditMaterialType,
  DetailMaterialType,
  MaterialTransactionType,
  EditMaterialTransactionType,
  Place,
  EditPlace,
  SettingIMS as SettingIMSPage,
} from '../../views/IMS';

export function SettingIMS() {
  const module = 'IMS';
  const prefix = '/setting/ims';
  const name = 'ตั้งค่าระบบ';
  const me = useSelector((state) => state.me);
  const availableModule = useSelector((state) => state.availableModule);

  if (accessRight(me, module, 1, availableModule)) {
    return (
      <div>
        <Route exact path={`${prefix}/material-types`}>
          <MaterialType title="จัดการประเภทวัตถุดิบ" subtitle={name} />
        </Route>
        <Route exact path={`${prefix}/material-types/edit/:id`}>
          <EditMaterialType title="แก้ไขประเภทวัตถุดิบ" subtitle={name} />
        </Route>
        <Route exact path={`${prefix}/material-type/:id`}>
          <DetailMaterialType
            title="รายละเอียดประเภทวัตถุดิบ"
            subtitle={name}
          />
        </Route>

        <Route exact path={`${prefix}/places`}>
          <Place title="จัดการคลังวัตถุดิบ" subtitle={name} />
        </Route>
        <Route exact path={`${prefix}/place/edit/:id`}>
          <EditPlace title="แก้ไขคลังวัตถุดิบ" subtitle={name} />
        </Route>
        <Route exact path={`${prefix}/transaction-types`}>
          <MaterialTransactionType
            title="จัดการประเภทการดำเนินการ"
            subtitle={name}
          />
        </Route>
        <Route exact path={`${prefix}/transaction-types/edit/:id`}>
          <EditMaterialTransactionType
            title="แก้ไขประเภทการดำเนินการ"
            subtitle={name}
          />
        </Route>
        <Route exact path={`${prefix}/module-setting`}>
          <SettingIMSPage
            title="ตั้งค่าระบบจัดการคลังวัตถุดิบ"
            subtitle={name}
          />
        </Route>
      </div>
    );
  }
  return (
    <div>
      {' '}
      <Route path="*">
        <NotAuthorized />
      </Route>
    </div>
  );
}

export default SettingIMS;
