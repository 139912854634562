import React, { useContext } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import {
  Button,
  Card,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
} from '@mui/material';
import dayjs from 'dayjs';
import _ from 'lodash';
import PropTypes from 'prop-types';

import { WordLocalizeContext } from '@/contexts/WordLocalizeContext';
import { MATERIAL_STOCK_STATUS } from '@/utils/constants';
import currencyFormatter from '@/utils/functions/currencyFormatterTH';

import MultiUnitProdOrMatBox from '../Rendering/MultiUnitProdOrMatBox';

import MaterialPlaceActionCard from './MaterialPlaceActionCard';

function MaterialLotTable({
  selectedMaterial,
  imsSetting,
  materialStockLot,
  page,
  total,
  size,
  setPage,
  setSize,
  multiMaterial = false,
  showPrice = true,
  showStatus = false,
  showActionButton = false,
  place,
  handleDelete = () => {},
}) {
  const { findWord } = useContext(WordLocalizeContext);
  const me = useSelector((state) => state.me);
  return (
    <div>
      <Card>
        <TableContainer>
          <Table className="border-lg mb-4">
            <colgroup>
              <col width="5%" />
              <col width="8%" />
              {multiMaterial && <col width="8%" />}
              <col width="8%" />
              {imsSetting?.display?.purchase_date && <col width="8%" />}
              <col width="8%" />
              {imsSetting?.display?.lot_number && <col width="8%" />}
              {imsSetting?.display?.production_date && <col width="8%" />}
              {imsSetting?.display?.expiration_date && <col width="8%" />}
              <col width="15%" />
              <col width="10%" />
              <col width="5%" />
              {showPrice && <col width="5%" />}
              {showPrice && <col width="5%" />}
              <col width="5%" />
              <col width="10%" />
              {showActionButton && <col width="15%" />}
            </colgroup>
            <TableHead>
              <TableRow>
                <TableCell>
                  <div className="font-bold">ลำดับที่</div>
                </TableCell>{' '}
                <TableCell>
                  <div className="font-bold">เลขอ้างอิง</div>
                </TableCell>{' '}
                {multiMaterial && (
                  <TableCell>
                    <div className="font-bold">วัตถุดิบ</div>
                  </TableCell>
                )}
                <TableCell>
                  <div className="font-bold">
                    {findWord('recipt_date') || 'วันที่รับวัตถุดิบ'}
                  </div>
                </TableCell>{' '}
                {imsSetting?.display?.purchase_date && (
                  <TableCell>
                    <div className="font-bold">
                      {findWord('purchase_date') || 'วันที่ซื้อ'}
                    </div>
                  </TableCell>
                )}
                {imsSetting?.display?.lot_number && (
                  <TableCell>
                    <div className="font-bold"> หมายเลขล็อต</div>
                  </TableCell>
                )}
                {imsSetting?.display?.bill_number && (
                  <TableCell>
                    <div className="font-bold"> เลขที่บิล</div>
                  </TableCell>
                )}
                {imsSetting?.display?.production_date && (
                  <TableCell>
                    <div className="font-bold"> วันที่ผลิต</div>
                  </TableCell>
                )}
                {imsSetting?.display?.expiration_date && (
                  <TableCell>
                    <div className="font-bold"> วันที่หมดอายุ</div>
                  </TableCell>
                )}
                <TableCell>
                  <div className="font-bold"> คู่ค้า</div>
                </TableCell>
                <TableCell>
                  <div className="font-bold"> ผู้รับวัตถุดิบ</div>
                </TableCell>
                {showPrice && (
                  <TableCell>
                    <div className="font-bold"> ราคารวม</div>
                  </TableCell>
                )}
                {showPrice && (
                  <TableCell>
                    <div className="font-bold"> ราคาต่อหน่วย</div>
                  </TableCell>
                )}
                <TableCell>
                  <div className="font-bold"> จำนวน</div>
                </TableCell>
                <TableCell>
                  <div className="font-bold"> คงเหลือ</div>
                </TableCell>
                <TableCell>
                  <div className="font-bold"> หมายเหตุ</div>
                </TableCell>
                {showActionButton && (
                  <TableCell>
                    <div className="font-bold"> ดำเนินการ</div>
                  </TableCell>
                )}
              </TableRow>
            </TableHead>
            <TableBody>
              {!_.isEmpty(materialStockLot?.rows) ? (
                materialStockLot?.rows.map((_lot, index) => (
                  <TableRow key={index}>
                    <TableCell>{index + 1}</TableCell>
                    <TableCell>
                      {_lot?.running_number}
                      {showStatus && (
                        <div>
                          {_lot?.status ===
                          MATERIAL_STOCK_STATUS.IN_STOCK.status_code ? (
                            <div className="w-32">
                              <div className="bg-gray-100 p-1 text-center rounded-md">
                                <p className="text-green-500 ">
                                  {MATERIAL_STOCK_STATUS.IN_STOCK.description}
                                </p>
                              </div>
                            </div>
                          ) : (
                            <div className="w-32">
                              <div className="bg-gray-100 p-1 text-center rounded-md">
                                <p className="text-red-500 ">
                                  {' '}
                                  {MATERIAL_STOCK_STATUS.OUT_STOCK.description}
                                </p>
                              </div>
                            </div>
                          )}{' '}
                        </div>
                      )}
                    </TableCell>
                    {multiMaterial && (
                      <TableCell>
                        <b>{_lot?.material?.type_code}</b>{' '}
                        {_lot?.material?.name}{' '}
                      </TableCell>
                    )}
                    <TableCell>
                      {_lot?.receipt_date
                        ? dayjs(_lot?.receipt_date)
                            .locale('th')
                            .format('D MMM BBBB HH:mm')
                        : '-'}
                    </TableCell>{' '}
                    {imsSetting?.display?.purchase_date && (
                      <TableCell>
                        {_lot?.purchase_date
                          ? dayjs(_lot?.purchase_date)
                              .locale('th')
                              .format('D MMM BBBB')
                          : '-'}
                      </TableCell>
                    )}
                    {imsSetting?.display?.lot_number && (
                      <TableCell>{_lot?.lot_number || '-'}</TableCell>
                    )}
                    {imsSetting?.display?.bill_number && (
                      <TableCell>
                        <b>{_lot?.bill_number || '-'}</b>
                      </TableCell>
                    )}
                    {imsSetting?.display?.production_date && (
                      <TableCell>
                        <b className="text-green-500">
                          {dayjs(_lot?.production_date)
                            .locale('th')
                            .format('DD MMM BBBB')}
                        </b>
                      </TableCell>
                    )}
                    {imsSetting?.display?.expiration_date && (
                      <TableCell>
                        <b className="text-red-500">
                          {dayjs(_lot?.expiration_date)
                            .locale('th')
                            .format('DD MMM BBBB')}
                        </b>
                      </TableCell>
                    )}
                    <TableCell>
                      <div>{_lot?.supplier?.name} </div>
                      {_lot?.source ? (
                        <div>แหล่งวัตถุดิบ : {_lot?.source?.overall_code}</div>
                      ) : (
                        <div></div>
                      )}
                    </TableCell>
                    <TableCell>
                      <div>{_lot?.recipient_name}</div>
                      <div>{_lot?.order_department?.name}</div>
                    </TableCell>
                    {showPrice && (
                      <TableCell>
                        {_lot?.price
                          ? currencyFormatter.format(_lot?.price)
                          : '-'}{' '}
                        บาท
                      </TableCell>
                    )}
                    {showPrice && (
                      <TableCell>
                        {_lot?.price
                          ? currencyFormatter.format(
                              _lot?.price / _lot?.quantity,
                            )
                          : '-'}{' '}
                        บาท
                      </TableCell>
                    )}
                    <TableCell>
                      <MultiUnitProdOrMatBox
                        foundMaterial={
                          multiMaterial ? _lot?.material : selectedMaterial
                        }
                        quantity={_lot?.quantity}
                      />
                    </TableCell>
                    <TableCell>
                      <MultiUnitProdOrMatBox
                        foundMaterial={
                          multiMaterial ? _lot?.material : selectedMaterial
                        }
                        quantity={_lot?.amount}
                      />
                    </TableCell>
                    <TableCell>{_lot?.remark || ' - '}</TableCell>
                    {showActionButton && (
                      <TableCell>
                        <div className="flex flex-col gap-1">
                          <Link
                            to={`/ims/material-stock-lot/${_lot?.id}`}
                            className="w-full"
                          >
                            <Button
                              size="small"
                              variant="contained"
                              color="info"
                              fullWidth
                            >
                              รายละเอียด
                            </Button>
                          </Link>

                          <MaterialPlaceActionCard lot={_lot} place={place} />
                          <Link
                            to={
                              me?.userData?._id === _lot?.employee ||
                              me?.userData?.role?.level >= 1
                                ? `/ims/material-stock/edit/${_lot?.id}`
                                : '#'
                            }
                          >
                            <Button
                              size="small"
                              variant="contained"
                              color="warning"
                              fullWidth
                              disabled={
                                !(
                                  me?.userData?._id === _lot?.employee ||
                                  me?.userData?.role?.level >= 1
                                )
                              }
                            >
                              แก้ไข
                            </Button>
                          </Link>
                          <Button
                            size="small"
                            variant="contained"
                            color="error"
                            fullWidth
                            disabled={
                              !(
                                me?.userData?._id === _lot?.employee ||
                                me?.userData?.role?.level >= 1
                              )
                            }
                            onClick={() => handleDelete(_lot?._id)}
                          >
                            ลบ
                          </Button>
                        </div>
                      </TableCell>
                    )}
                  </TableRow>
                ))
              ) : (
                <TableRow key={''}>
                  <TableCell colSpan={12}>
                    <div className="text-center">ไม่มีข้อมูล</div>
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>{' '}
          <TablePagination
            component="div"
            page={page - 1}
            count={total || 1}
            rowsPerPage={size}
            onRowsPerPageChange={(e) => {
              setSize(e.target.value);
              setPage(1);
            }}
            onPageChange={(e, newValue) => {
              setPage(newValue + 1);
            }}
          />
        </TableContainer>{' '}
      </Card>
    </div>
  );
}

export default MaterialLotTable;

MaterialLotTable.propTypes = {
  /**
   * วัตถุดิบที่เลือก
   */
  selectedMaterial: PropTypes.object,
  /**
   * ค่า Config ของ Inventory System จาก Information
   */
  imsSetting: PropTypes.object,
  /**
   * ข้อมูลของวัตถุดิบที่มีอยู่ใน Stock Lot
   */
  materialStockLot: PropTypes.shape({
    rows: PropTypes.arrayOf(PropTypes.object),
  }),
  /**
   * หน้าของตาราง
   */
  page: PropTypes.number,
  /**
   * จำนวนรายการทั้งหมด
   */
  total: PropTypes.number,
  /**
   * จำนวนรายการต่อหน้า
   */
  size: PropTypes.number,
  setPage: PropTypes.func,
  setSize: PropTypes.func,
  /** ใช้งานกับวัตถุดิบหลายตัวหรือไม่ */
  multiMaterial: PropTypes.bool,
  showPrice: PropTypes.bool,
  /** แสดงสถานะว่าหมดสล็อกหรือยัง */
  showStatus: PropTypes.bool,
  /** คลังวัตถุดิบปัจจุบัน */
  place: PropTypes.object,
  /** แสดงปุ่มดำเนินการ และปุ่มรายละเอียดหรือไม่ */
  showActionButton: PropTypes.bool,
  handleDelete: PropTypes.func,
};
