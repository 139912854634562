import React from 'react';
import PropTypes from 'prop-types';

const ProcessEmployeeCostDriverBox = ({ currentProcess }) => {
  const manufacturingTransaction = currentProcess?.manufacturing_transactions;
  return <div>ProcessEmployeeCostDriverBox</div>;
};

export default ProcessEmployeeCostDriverBox;

ProcessEmployeeCostDriverBox.propTypes = {
  currentProcess: PropTypes.object,
};
