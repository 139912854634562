/* eslint-disable no-param-reassign */
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { gql } from 'graphql-request';
import { Link } from 'react-router-dom';
import {
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  TablePagination,
  Paper,
  Button,
} from '@mui/material';
import _ from 'lodash';

import {
  ViewTitle,
  Loading,
  Error,
  BackButton,
  SearchBox,
} from '@iarcpsu/emanufac-components/src/components';
import * as actions from '../../../redux/actions';
import graphqlClient from '../../../configs/graphqlClient';

/**
 * @function MeasuredData
 * @description Display a collections or a list of MeasuredData from database
 */

export default function MeasuredData({ title, subtitle }) {
  const dispatch = useDispatch();

  const measurementType = useSelector((state) => state.measurementType);

  const [page, setPage] = useState(1);
  const [size, setSize] = useState(10);
  const [total, setTotal] = useState();
  const [searchTerm, setSearchTerm] = useState();
  const [name, setName] = useState(null);

  const query = gql`
    query FindMeasuredData($input: MeasurementTypeInput) {
      findMeasurementTypes(input: $input) {
        currPage
        lastPage
        total
        rows {
          _id
          name
          type_code
        }
      }
    }
  `;

  const queryDataFromServer = async () => {
    try {
      console.log('QUERY');
      // dispatch(actions.measurementTypeLoading());
      const queryResult = await graphqlClient.request(query, {
        input: { name: name || null, page, size },
      });
      const measurementTypeData = queryResult?.findMeasurementTypes;
      dispatch(actions.measurementTypeStateSet(measurementTypeData));
    } catch (err) {
      console.error('Error on Fetch Data', err);
    }
  };

  const handleChangePage = (event, pageNumber) => {
    setPage(pageNumber + 1);
  };

  const handleChangeRowPerPage = (event) => {
    setSize(event.target.value);
    setPage(1);
  };

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      setName(searchTerm);
      setPage(1);
    }, 500);
    return () => clearTimeout(delayDebounceFn);
  }, [searchTerm]);

  useEffect(() => {
    queryDataFromServer();
    return () => {};
  }, [page, size, name]);

  useEffect(() => {
    if (measurementType?.rows) {
      setTotal(measurementType?.total);
    }
    return () => {};
  }, [measurementType]);

  const renderTitle = () => <ViewTitle title={title} subtitle={subtitle} />;
  if (measurementType.isLoading) {
    return <Loading />;
  }

  if (!measurementType.isLoading && measurementType.isCompleted) {
    return (
      <div>
        {renderTitle()}
        <div className="flex">
          <BackButton />
        </div>
        <div className="my-2">
          <SearchBox label="ค้นหา" setValue={setSearchTerm} />
        </div>
        <div className="my-2">
          <Paper>
            <Table sx={{ minWidth: 650 }}>
              <TableHead>
                <TableRow>
                  <TableCell>
                    <div className="font-bold">ลำดับที่</div>
                  </TableCell>{' '}
                  <TableCell>
                    <div className="font-bold">ชื่อตัวแปร </div>
                  </TableCell>{' '}
                  <TableCell>
                    <div className="font-bold"> รหัสตัวแปร</div>
                  </TableCell>
                  <TableCell>
                    <div className="font-bold">ดำเนินการ</div>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {!_.isEmpty(measurementType?.rows) ? (
                  _.map(measurementType?.rows, (row, index) => (
                    <TableRow
                      key={index}
                      sx={{
                        '&:last-child td, &:last-child th': { border: 0 },
                      }}
                    >
                      <TableCell component="th" scope="row">
                        <div className="text-xs">{index + 1}</div>
                      </TableCell>
                      <TableCell>{row?.name}</TableCell>{' '}
                      <TableCell>{row?.type_code}</TableCell>
                      <TableCell>
                        <Link to={`measured-data/detail/${row?._id}`}>
                          <Button color="info" variant="contained" size="small">
                            ค่าที่วัดได้
                          </Button>
                        </Link>
                      </TableCell>
                    </TableRow>
                  ))
                ) : (
                  <TableRow>
                    <TableCell colSpan={6}>
                      <div className="text-center">ไม่มีข้อมูล</div>
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>{' '}
              <TablePagination
                page={page - 1}
                count={total || 1}
                rowsPerPage={size}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowPerPage}
              />{' '}
            </Table>
          </Paper>
        </div>
      </div>
    );
  }
  return <Error />;
}

MeasuredData.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
};

MeasuredData.defaultProps = {
  title: '',
  subtitle: '',
};
