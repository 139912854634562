import React, { useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Card,
  CardContent,
  Checkbox,
  Chip,
  Paper,
  Switch,
  TablePagination,
} from '@mui/material';
import dayjs from 'dayjs';
import PropTypes from 'prop-types';

import {
  BackButton,
  DaySpotAndRangePicker,
  Error,
  Loading,
  LoadingLinear,
  MaterialPivotTable,
  ViewTitle,
} from '@iarcpsu/emanufac-components/src/components';
import { config, useQuery } from '@/configs';
import { WordLocalizeContext } from '@/contexts/WordLocalizeContext';
import * as actions from '@/redux/actions';
import { MATERIAL_STOCK_STATUS } from '@/utils/constants';
import generateMaterialByMaterialSource from '@/utils/functions/IMS/generateMaterialByMaterialSource';

/**
 * @function MaterialStockByGroup
 * @description Display a collections or a list of EditProcessTemplateTableView from database
 */

export default function MaterialStockByGroup({ title, subtitle }) {
  const dispatch = useDispatch();
  const query = useQuery();
  const { findWordInPlace, findWord } = useContext(WordLocalizeContext);

  // Global State
  const materialStockLot = useSelector((state) => state.materialStockLot);
  const place = useSelector((state) => state.place);
  const material = useSelector((state) => state.material);
  const pdfReport = useSelector((state) => state.pdfReport);

  // Local state
  const [page, setPage] = useState(1);
  const [size, setSize] = useState(100);
  const [total, setTotal] = useState();
  const [displayPivotTable, setIsDisplayPivotTable] = useState(true);
  const [usePurchaseDateSearch, setUsePurchaseDateSearch] = useState(false);
  const [startDate, setStartDate] = useState(dayjs().toDate());
  const [endDate, setEndDate] = useState(dayjs().toDate());
  const [startPurchaseDate, setStartPurchaseDate] = useState(dayjs().toDate());
  const [endPurchaseDate, setEndPurchaseDate] = useState(dayjs().toDate());
  const [isTotalBySource, setIsTotalBySource] = useState(true);
  const [showRemainAmount, setIsShowRemainAmount] = useState(false);
  const [filterOutZero, setIsFilterOutZero] = useState(false);
  const [showRunOutOff, setIsShowRunOutOff] = useState(false);
  const [filterByProcess, setFilterByProcess] = useState(false);

  const sourceOfData = {
    'material-source': {
      type_code: 'material-source',
      description: 'ที่มาวัตถุดิบ',
      title: query.get('mistakeplace')
        ? findWordInPlace('stranger_material') || 'วัตถุดิบไม่ตรงคลัง'
        : findWordInPlace('group_by_source') || 'วัตถุดิบตามกลุ่มที่มา',
    },
  };

  const queryDataFromServer = async () => {
    try {
      if (usePurchaseDateSearch) {
        dispatch(
          actions.materialStockLotGroupAll({
            purchaseStartDate: dayjs(startPurchaseDate).format('YYYY-MM-DD'),
            purchaseEndDate: dayjs(endPurchaseDate).format('YYYY-MM-DD'),
            groupBy: query.get('by'),
            place: query.get('place') || '',
            size,
            page,
            status: !showRunOutOff
              ? MATERIAL_STOCK_STATUS.IN_STOCK.status_code
              : '',
            placeRestricNotify: query.get('mistakeplace') ? true : '',
            strangerOnly: query.get('mistakeplace') ? true : '',
            fetchProcess: true,
          }),
        );
      } else {
        dispatch(
          actions.materialStockLotGroupAll({
            startDate: dayjs(startDate).format('YYYY-MM-DD'),
            endDate: dayjs(endDate).format('YYYY-MM-DD'),
            groupBy: query.get('by'),
            place: query.get('place') || '',
            size,
            page,
            status: !showRunOutOff
              ? MATERIAL_STOCK_STATUS.IN_STOCK.status_code
              : '',
            placeRestricNotify: query.get('mistakeplace') ? true : '',
            strangerOnly: query.get('mistakeplace') ? true : '',
            fetchProcess: true,
          }),
        );
      }
    } catch (error) {
      dispatch(actions.materialError());
    }
  };

  useEffect(() => {
    dispatch(
      actions.materialAll({
        placeRestriction: query.get('place') !== '' ? 'true' : '',
        place: query.get('place'),
        page: 1,
        size: config.maxFetchSize,
      }),
    );

    return () => {};
  }, [query.get('place')]);

  useEffect(() => {
    queryDataFromServer();

    return () => {};
  }, [
    page,
    size,
    startDate,
    endDate,
    showRunOutOff,
    usePurchaseDateSearch,
    startPurchaseDate,
    endPurchaseDate,
  ]);

  useEffect(() => {
    setTotal(materialStockLot?.total);

    return () => {};
  }, [materialStockLot]);

  useEffect(() => {
    if (query.get('place') && (query.get('place') !== '') !== '') {
      dispatch(actions.placeGet(query.get('place')));
    }

    return () => {};
  }, [query.get('place')]);

  useEffect(() => {
    dispatch(actions.pdfReportAll({ page: 1, size: config.maxFetchSize }));

    return () => {};
  }, []);

  const generatedData = generateMaterialByMaterialSource({
    materialStockLotBySource: materialStockLot?.rows,
    material: material?.rows,
    selectedDate: startDate,
    isTotalBySource,
    place,
    filterOutZero,
    nonRestrictPlace: query.get('mistakeplace')
      ? Boolean(query.get('mistakeplace'))
      : false,
    divideByProcess: filterByProcess,
  });

  const checkTurnOnProcessFilter = () => {
    if (query.get('mistakeplace') === 'true') {
      return place?.place_action?.for_stranger?.enable_group_by_process;
    }

    return place?.place_action?.enable_group_by_process;
  };

  useEffect(() => {
    if (place?.place_action?.enable) {
      setFilterByProcess(checkTurnOnProcessFilter());
    }

    return () => {};
  }, [place]);

  const renderTitle = () => (
    <ViewTitle
      title={`${sourceOfData[query.get('by')]?.title}`}
      subtitle={subtitle}
    />
  );

  if (materialStockLot.isLoading) {
    return <Loading />;
  }

  if (!materialStockLot.isLoading && materialStockLot.isCompleted) {
    return (
      <div>
        {renderTitle()}
        <BackButton />

        <div className="my-2">
          <Paper>
            <div className="flex flex-wrap py-4 px-2">
              <div className="w-full lg:w-1/3">
                <div className="flex gap-2 p-2 border rounded-md items-center">
                  <div>กรองตามวันที่เข้าคลังปัจจุบัน </div>
                  <Switch
                    checked={usePurchaseDateSearch}
                    onChange={(e) => {
                      setUsePurchaseDateSearch(e.target.checked);
                    }}
                  />
                  <div>กรองตาม{findWord('purchase_date') || 'วันที่ซื้อ'}</div>
                </div>
              </div>
              <div className="w-full lg:w-2/3 px-2">
                {usePurchaseDateSearch ? (
                  <DaySpotAndRangePicker
                    startDate={startPurchaseDate}
                    endDate={endPurchaseDate}
                    setEndDate={setEndPurchaseDate}
                    setStartDate={setStartPurchaseDate}
                    flex
                    state="date"
                  />
                ) : (
                  <DaySpotAndRangePicker
                    startDate={startDate}
                    endDate={endDate}
                    setEndDate={setEndDate}
                    setStartDate={setStartDate}
                    state="date"
                    flex
                  />
                )}
              </div>
            </div>
          </Paper>
        </div>
        <div className="my-2">
          {query.get('place') && (
            <Card>
              <CardContent>
                <div className="flex justify-between flex-wrap">
                  <div className="w-full lg:w-1/2">
                    <div>คลังวัตถุดิบ</div>
                    <div className="text-lg font-semibold">{place?.name}</div>
                    {query.get('mistakeplace') === 'true' && (
                      <div>
                        <Chip color="warning" label="ไม่ตรงคลัง" />
                      </div>
                    )}
                  </div>

                  <div className="w-full lg:w-1/2 flex flex-wrap p-2 border">
                    <div className="lg:w-2/3  rounded-md p-2 flex items-center">
                      <div>แยกเป็นล็อต</div>
                      <div>
                        <Switch
                          checked={isTotalBySource}
                          onChange={(e) => {
                            setIsTotalBySource(e.target.checked);
                            // setIsDisplayPivotTable(false);
                          }}
                        />
                      </div>
                      <div>รวมแหล่งเดียวกันไว้ด้วยกัน</div>
                    </div>
                    <div className="lg:w-1/3  rounded-md p-2 flex items-center">
                      <div></div>
                      <div>
                        <Checkbox
                          checked={showRemainAmount}
                          onChange={(e) =>
                            setIsShowRemainAmount(e.target.checked)
                          }
                        />
                      </div>
                      <div>แสดงคงเหลือ</div>
                    </div>
                    <div className="w-full p-2 flex gap-2">
                      <div className="flex items-center">
                        <div>
                          <Checkbox
                            checked={filterOutZero}
                            onChange={(e) =>
                              setIsFilterOutZero(e.target.checked)
                            }
                          />
                        </div>
                        <div>กรองวัตถุดิบที่ไม่มีออก</div>
                      </div>{' '}
                      <div className="flex items-center">
                        <div>
                          <Checkbox
                            checked={showRunOutOff}
                            onChange={(e) =>
                              setIsShowRunOutOff(e.target.checked)
                            }
                          />
                        </div>
                        <div>แสดงล็อตที่หมดแล้ว</div>
                      </div>
                      <div className="flex items-center">
                        <div>
                          <Checkbox
                            checked={filterByProcess}
                            onChange={(e) =>
                              setFilterByProcess(e.target.checked)
                            }
                          />
                        </div>
                        <div>จัดกลุ่มตามการผลิต</div>
                      </div>
                    </div>
                  </div>
                </div>
              </CardContent>
            </Card>
          )}
        </div>

        <div className="my-2">
          {displayPivotTable ? (
            <MaterialPivotTable
              generatedData={generatedData}
              isTotalBySource={isTotalBySource}
              showRemainAmount={showRemainAmount}
              page={page}
              size={size}
              total={total}
              showTotalPrevious={
                query.get('mistakeplace') === 'true'
                  ? place?.place_action?.for_stranger?.show_total_previous
                  : place?.place_action?.show_total_previous
              }
              showProcess={filterByProcess}
              showRunOutOff={showRunOutOff}
              showYield={place?.place_action?.show_yield_by_group}
              showRemainder={place?.place_action?.show_remainder_from_previous}
            />
          ) : (
            <LoadingLinear />
          )}
          <TablePagination
            component="div"
            count={total}
            page={page - 1}
            onPageChange={(event, newValue) => {
              setPage(newValue);
            }}
            rowsPerPage={size}
            onRowsPerPageChange={(event) => {
              setSize(event.target.value);
              setPage(1);
            }}
          />
        </div>
      </div>
    );
  }
  return <Error />;
}

MaterialStockByGroup.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
};

MaterialStockByGroup.defaultProps = {
  title: '',
  subtitle: '',
};
