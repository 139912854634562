import React, { useEffect, useState } from 'react';
import { RefreshCw } from 'react-feather';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Button, Card, InputAdornment, TextField } from '@mui/material';
import PropTypes from 'prop-types';

import { Error } from '@iarcpsu/emanufac-components/src/components/Error';
import { LoadingLinear } from '@iarcpsu/emanufac-components/src/components/Loading';
import { StepTable } from '@iarcpsu/emanufac-components/src/components/Tables';
import { ViewTitle } from '@iarcpsu/emanufac-components/src/components/ViewTitle';
import * as actions from '@/redux/actions';

const MyWorks = ({ title, subtitle }) => {
  // Setting
  const dispatch = useDispatch();
  const history = useHistory();
  const step = useSelector((state) => state.step);
  const me = useSelector((state) => state.me);
  const [searchTerm, setSearchTerm] = useState('');
  const [name, setName] = useState('');
  const [page, setPage] = useState(1);
  const [size, setSize] = useState(10);
  const [total, setTotal] = useState(undefined);
  const [useRedis, setIsUseRedis] = useState(true);

  useEffect(() => {
    dispatch(actions.stepReset());
    return () => {};
  }, []);

  // Initialization
  useEffect(() => {
    dispatch(
      actions.stepAll({
        name,
        page,
        size,
        employee: me?.userData?._id,
        forceWithoutCache: true,
        completed: false,
        deleted: false,
        // forceWithoutCache: useRedis ? '' : false,
      }),
    )
      .then(() => {})
      .catch((err) => {
        console.error('Fetch Process Error', err);
      });
    return () => {};
  }, [page, size, useRedis, name]);

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      setName(searchTerm);
      setPage(1);
    }, 500);
    return () => clearTimeout(delayDebounceFn);
  }, [searchTerm]);

  useEffect(() => {
    setTotal(step?.total);
    return () => {};
  }, [step]);

  // Functions

  const handleToInfoPage = (id) => {
    history.push(`works/view/${id}`);
  };

  const handleChangeRowsPerPage = (event) => {
    setSize(event.target.value);
    setPage(1);
  };
  const handleChangePage = (event, newPage) => {
    setPage(newPage + 1);
  };

  const tableProps = {
    step,
    page,
    size,
    total,
    name,
    handleToInfoPage,
    handleChangeRowsPerPage,
    handleChangePage,
  };

  // Component Rendering
  const renderTitle = () => (
    <div className="flex justify-between">
      <ViewTitle title={title} subtitle={subtitle} />
      <div>
        <Button
          variant="contained"
          color="teal"
          startIcon={<RefreshCw size={16} />}
          onClick={() => {
            setIsUseRedis(false);
          }}
        >
          รีเฟรช
        </Button>
      </div>
    </div>
  );

  const renderSearch = () => (
    <Card>
      <div className="p-4 flex flex-row flex-wrap">
        <div className="w-full md:w-1/2 px-2 py-1">
          <TextField
            label="ค้นหา"
            fullWidth
            size={'small'}
            id="outlined-start-adornment"
            onChange={(e) => {
              setSearchTerm(e.target.value);
            }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <i className="fas fa-search"></i>
                </InputAdornment>
              ),
            }}
          />
        </div>
      </div>
    </Card>
  );

  const renderTable = () => <StepTable {...tableProps} />;

  if (!step.isLoading && !step.isCompleted) {
    return <Error />;
  }

  return (
    <div>
      {renderTitle()}
      {renderSearch()}
      {step.isLoading && <LoadingLinear />}
      {!step.isLoading && step.isCompleted && renderTable()}
    </div>
  );
};

MyWorks.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
};

MyWorks.defaultProps = {
  title: '',
  subtitle: '',
};
export default MyWorks;
